


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SiteFilter from '@/views/Audit/components/SiteFilter.vue';
import { Action, State } from 'vuex-class';
import {
  AuditDetail,
  AuditSite,
} from '@/store/modules/audits/types/audits.types';
import DataCollectionSummaryTab from '@/views/Audit/components/DataCollectionSummaryTab.vue';
import DataHistoryTab from '@/views/Audit/components/DataHistoryTab.vue';
import { useUserEmail } from '@/utils/user.util';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  ParticipantRoles,
  ProjectParticipantDTO,
} from '@/store/modules/projects/types/projects.types';

@Component({
  components: {
    DataHistoryTab,
    SiteFilter,
    DataCollectionSummaryTab,
  },
})
export default class DataCollectionTab extends Vue {
  @Prop()
  public auditId!: number;
  @Prop({ default: true })
  public editable!: boolean;
  public selectedSites: number[] = [];
  public dataCollectionTabs: number = 0;
  public showDataActivityLogTab: boolean = false;
  public showDataCollectionSummaryTab: boolean = false;
  public dataCollectionTabKey: number = Math.floor(Math.random() * 999);
  public dataHistoryTabKey: number = Math.floor(Math.random() * 999);
  public siteFilterApplied: boolean = false;
  public isDataCollectionActive: boolean = false;
  public isLoading: boolean = true;

  @Action('audits/getAuditDetail')
  public getAuditDetail!: (auditId: number) => void;

  @State((state) => state.audits.auditDetail)
  public auditDetail!: AuditDetail;

  @State((state) => state.audits.apiState.getAuditDetail.success)
  public getAuditDetailApiStateSuccess!: boolean;

  @Action('audits/getAuditSitesForDataCollection')
  public getAuditSitesForDataCollection!: (auditId: number) => void;

  @State((state) => state.audits.auditSites)
  public auditSites!: AuditSite[];

  get siteList() {
    const siteArray: Array<{
      siteId: number;
      name: string;
      auditSiteId: number;
    }> = [];
    return this.auditSites
      ? this.auditSites.map((auditSite) => {
          return {
            siteId: auditSite.site.id,
            name: auditSite.site.name,
            auditSiteId: auditSite.id,
          };
        })
      : [];
  }

  public mounted() {
    this.getAuditSitesForDataCollection(this.auditId);
    if (this.currentRouteHash === '#dataHistory') {
      this.dataCollectionTabs = 1;
    } else {
      this.dataCollectionTabs = 0;
    }
    this.tabChange();
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public checkUserAccess(value: AuditDetail): boolean {
    if (!this.userEmail) {
      return false;
    }
    const userEmail: string = this.userEmail;
    if (value.project.user.oicPayload.email === userEmail) {
      return true;
    } else {
      const projectParticipant:
        | ProjectParticipantDTO
        | undefined = value.project.participants.find(
        (participant) =>
          participant.userEmail === userEmail && !participant.isDeleted,
      );
      if (!projectParticipant) {
        return false;
      }

      const isProjectCoordinator = projectParticipant.participantRoles.some(
        (participantRole) => {
          return (
            participantRole.role === ParticipantRoles.projectCoOrdinator &&
            participantRole.participantSites.some((participantSite) => {
              return (
                !participantSite.isDeleted && participantSite.invite.acceptedAt
              );
            })
          );
        },
      );
      return !!isProjectCoordinator;
    }
  }

  @Watch('auditSites')
  public watchAuditSites(newValue: any) {
    this.selectedSites = this.siteList.map((auditSite) => {
      return auditSite.auditSiteId;
    });
    if (this.currentRouteHash === '#dataHistory') {
      this.showDataActivityLogTab = true;
      this.dataHistoryTabKey += 1;
    } else {
      this.showDataCollectionSummaryTab = true;
      this.dataCollectionTabKey += 1;
    }
  }

  public showDataCollectionTemplate() {
    this.$router.push({
      name: 'audit',
      params: {
        auditId: this.auditId.toString(),
      },
      hash: '#submitRecord',
    });
    this.$emit('showDataCollectionTemplate');
  }

  public handleDataCollectionForSingleCriteria(auditCriteriaId: number) {
    this.$emit('showDataCollectionTemplate', auditCriteriaId);
  }

  public editDataCollectionRecord(rowData: any) {
    this.$router.push({
      name: 'audit',
      params: {
        auditId: this.auditId.toString(),
      },
      hash: '#submitRecord',
    });
    this.$emit('editDataCollectionRecord', rowData);
  }

  public applySiteFilter(newValue: number[]) {
    this.selectedSites = newValue;
    this.siteFilterApplied = true;
  }

  public tabChange(): void {
    this.isLoading = true;
    this.getAuditDetail(this.auditId);
    switch (this.dataCollectionTabs) {
      case 0:
        if (this.currentRouteHash !== '#dataCollection') {
          this.dataCollectionTabKey += 1;
          this.showDataCollectionSummaryTab = true;
          this.showDataActivityLogTab = false;
          this.$router.replace({ hash: '#dataCollection' });
        }
        break;
      case 1:
        if (this.currentRouteHash !== '#dataHistory') {
          if (!this.siteFilterApplied) {
            this.selectedSites = this.siteList.map((auditSite) => {
              return auditSite.auditSiteId;
            });
          }
          this.showDataCollectionSummaryTab = false;
          this.showDataActivityLogTab = true;
          this.dataHistoryTabKey += 1;
          this.$router.replace({ hash: '#dataHistory' });
        }
        break;
    }
  }

  @Watch('getAuditDetailApiStateSuccess')
  @isTruthy
  public watchGetAuditDetailSuccess(): void {
    if (
      this.auditDetail.status === 'Deleted' ||
      !this.checkUserAccess(this.auditDetail)
    ) {
      this.$router.push({
        name: 'project-details',
        params: {
          projectId: this.auditDetail.project.id.toString(),
        },
      });
    }
    this.isDataCollectionActive = this.auditDetail.endedAt ? false : true;
    this.isLoading = false;
  }

  get currentRouteHash() {
    return this.$route.hash;
  }

  get message() {
    return 'Records cannot be submitted for upcoming data collection periods.';
  }

  get disabledSubmitRecord(): boolean {
    return new Date(this.auditDetail.startDate) > new Date() ? true : false;
  }

  public convertDate(value: any): string {
    const myDate: Date = new Date(Date.parse(value));
    const realDate: string =
      myDate.getFullYear() +
      '-' +
      ('0' + (myDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + myDate.getDate()).slice(-2);
    return realDate;
  }
}
