import {
  ProjectCriteria,
  ProjectParticipantDTO,
} from '../modules/projects/types/projects.types';
import { SortOrder } from './general.types';

export enum GripDomainCategoryStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum GripBarrierStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum GripEnablerStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum GripStrategyStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum GripAnalysisStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export interface PaginationOptions {
  limit: number;
  page: number;
}

export interface GripDomainCategory {
  id: number;
  name: string;
  status: GripDomainCategoryStatus;
  createdDate: Date;
  updatedDate: Date;
}

export interface GripDomainCategoryDetails extends GripDomainCategory {
  gripBarrierCount: number;
  gripEnablerCount: number;
  gripStrategyCount: number;
  gripAnalysisBarrierCount: number;
  gripAnalysisEnablerCount: number;
  gripAnalysisStrategyCount: number;
}

export interface CreateGripDomainCategoryPayload {
  name: string;
  projectId: number;
}

export interface UpdateGripDomainCategoryPayload
  extends CreateGripDomainCategoryPayload {
  id: number;
}

export interface DeleteGripDomainCategoryPayload {
  projectId: number;
  currentGripDomainCategoryId: number;
  newGripDomainCategoryId: number | null;
  isCurrentGripDomainCategoryAssigned: boolean;
}

export interface GripBarrierBase {
  title: string;
  description: string;
  action: boolean;
  reason: string | null;
  links: string[];
  attachments: string[];
}
export interface GripBarrier extends GripBarrierBase {
  id: number;
  status: GripBarrierStatus;
  project: any;
  createdDate: Date;
  updatedDate: Date;
  gripDomainCategory: GripDomainCategory | null;
}

export interface GripBarrierDTO extends GripBarrierBase {
  id: number;
  gripDomainCategory: GripDomainCategory | null;
}

export interface GetBarriersPayload extends PaginationOptions {
  title?: string;
}

export interface CreateGripBarrierBase {
  title: string;
  description: string;
  action: boolean;
  reason: string | null;
  links: string[];
  attachments: string[];
  gripDomainCategoryId: number | null;
}

export interface UpdateGripBarrierBase extends CreateGripBarrierBase {
  id: number;
}
export interface CreateGripBarrierPayload extends CreateGripBarrierBase {
  projectId: number;
}
export interface UpdateGripBarrierPayload extends UpdateGripBarrierBase {
  projectId: number;
}

export interface GripEnablerBase {
  title: string;
  description: string;
  links: string[];
  attachments: string[];
}
export interface GripEnabler extends GripEnablerBase {
  id: number;
  status: GripEnablerStatus;
  project: any;
  createdDate: Date;
  updatedDate: Date;
  gripDomainCategory: GripDomainCategory | null;
}

export interface GripEnablerDTO extends GripEnablerBase {
  id: number;
  gripDomainCategory: GripDomainCategory | null;
}

export interface GetEnablersPayload extends PaginationOptions {
  title?: string;
}

export interface CreateGripEnablerBase {
  title: string;
  description: string;
  links: string[];
  attachments: string[];
  gripDomainCategoryId: number | null;
}

export interface UpdateGripEnablerBase extends CreateGripEnablerBase {
  id: number;
}
export interface CreateGripEnablerPayload extends CreateGripEnablerBase {
  projectId: number;
}
export interface UpdateGripEnablerPayload extends UpdateGripEnablerBase {
  projectId: number;
}

export interface GripStrategyBase {
  title: string;
  description: string;
  resource: string;
  method: string;
  links: string[];
  attachments: string[];
}
export interface GripStrategy extends GripStrategyBase {
  id: number;
  status: GripStrategyStatus;
  project: any;
  createdDate: Date;
  updatedDate: Date;
  gripDomainCategory: GripDomainCategory | null;
}

export interface GripStrategyDTO extends GripStrategyBase {
  id: number;
  gripDomainCategory: GripDomainCategory | null;
}

export interface GetStrategiesPayload extends PaginationOptions {
  title?: string;
}

export interface CreateGripStrategyBase {
  title: string;
  description: string;
  resource: string;
  method: string;
  links: string[];
  attachments: string[];
  gripDomainCategoryId: number | null;
}

export interface UpdateGripStrategyBase extends CreateGripStrategyBase {
  id: number;
}
export interface CreateGripStrategyPayload extends CreateGripStrategyBase {
  projectId: number;
}
export interface UpdateGripStrategyPayload extends UpdateGripStrategyBase {
  projectId: number;
}
export interface LinkDTO {
  url: string;
  count: number;
  isValid: boolean;
  errorMessage: string;
}

export interface FileAttachmentDTO {
  type: string;
  name: string;
}

export interface GripDTO {
  projectLead: string;
  stakeholder: string;
  actionDate: Date;
}

export interface Grip {
  id: number;
  projectLead: string;
  stakeholder: string;
  actionDate: Date;
  projectId: number;
}

export interface CreateGripPayload {
  projectLead: string;
  stakeholder: string;
  actionDate: Date;
  projectId: number;
}

export interface UpdateGripPayload extends CreateGripPayload {
  id: number;
}

export interface GripAnalysisCriteria {
  id: number;
  criteria: ProjectCriteria;
}

export interface GripAnalysisParams extends PaginationOptions {
  title: string;
  sort: SortOrder;
  sortColumn: string;
  isExportGrip: boolean;
}

export interface GripAnalysis {
  id: number;
  title: string;
  status: GripAnalysisStatus;
  createdDate: Date;
  updatedDate: Date;
  gripAnalysisCriteriaMaps: GripAnalysisCriteria[];
  gripAnalysisBarriers: GripAnalysisBarrier[];
}

export interface CreateGripAnalysisPayload {
  title: string;
  projectId: number;
}

export interface RenameGripAnalysisPayload {
  gripAnalysisId: number;
  title: string;
}

export interface UpdateGripAnalysisPayload {
  gripAnalysisId: number;
  criteriaIds: number[];
}

export interface GripAnalysisBarrier extends GripBarrierBase {
  id: number;
  status: GripBarrierStatus;
  createdDate: Date;
  updatedDate: Date;
  gripDomainCategory: GripDomainCategory | null;
  gripAnalysisEnablers: GripAnalysisEnabler[];
}

export interface CreateGripAnalysisBarrierPayload
  extends CreateGripBarrierBase {
  gripAnalysisId: number;
}

export interface UpdateGripAnalysisBarrierPayload
  extends CreateGripAnalysisBarrierPayload {
  id: number;
}

export interface GripAnalysisEnabler extends GripEnablerBase {
  id: number;
  status: GripEnablerStatus;
  createdDate: Date;
  updatedDate: Date;
  gripDomainCategory: GripDomainCategory | null;
  gripAnalysisStrategies: GripAnalysisStrategy[];
}

export interface CreateGripAnalysisEnablerPayload
  extends CreateGripEnablerBase {
  gripAnalysisId: number;
  gripAnalysisBarrierId: number;
}

export interface UpdateGripAnalysisEnablerPayload
  extends CreateGripAnalysisEnablerPayload {
  id: number;
}

export interface GripAnalysisStrategy extends GripStrategyBase {
  id: number;
  status: GripStrategyStatus;
  gripDomainCategory: GripDomainCategory | null;
  createdDate: Date;
  updatedDate: Date;
}

export interface CreateGripAnalysisStrategyPayload
  extends CreateGripStrategyBase {
  gripAnalysisId: number;
  gripAnalysisEnablerId: number;
}

export interface UpdateGripAnalysisStrategyPayload
  extends CreateGripAnalysisStrategyPayload {
  id: number;
}

export interface GripAnalysisDTO {
  id: number;
  title: string;
  status: GripAnalysisStatus;
  createdDate: Date;
  updatedDate: Date;
  gripAnalysisBarriers: GripAnalysisBarrier[];
}

export interface GripNotificationResponse {
  id: number;
  message: string;
}

export interface UpdateGripNotificationsPayload {
  gripNotificationIds: number[];
  acknowledgedBy: ProjectParticipantDTO;
  acknowledgedDate: Date;
}

export interface GripExportReportPayload {
  projectId: number;
  isAllGripAnalysisSelected: boolean;
  isDomainCategoryRequired: boolean;
  gripAnalysisIds: number[];
}

export interface GripExportReportResponse {
  gripTeamDetails: Grip;
  gripAnalysis: GripAnalysis[];
}

export interface GripItemReloadDTO {
  item: string;
  id: number;
  prevItemId: string | null;
}

export enum GripItemType {
  AnalysisBarrier = 'analysis_barrier',
  AnalysisEnabler = 'analysis_enabler',
  AnalysisStrategy = 'analysis_strategy',
}

export type GripAnalysiItem =
  | GripAnalysisBarrier
  | GripAnalysisEnabler
  | GripAnalysisStrategy;
