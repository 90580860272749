































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  cloneDeep as _cloneDeep,
  map as _map,
  orderBy as _orderBy,
  sumBy as _sumBy,
  filter as _filter,
  remove as _remove,
  find as _find,
} from 'lodash';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  AuditCriteria,
  AuditDetail,
  AuditSamplingDataConfig,
  AuditSamplingResponse,
  DataCollectionSubmitRequestPayload,
  DataCollectionSummaryCriteria,
  DataCollectionSummaryRequestPayload,
  DataCollectionSummaryResponsePayload,
  DataCollectionSubmitResponse,
} from '@/store/modules/audits/types/audits.types';
import AuditMinAndMaxErrorModal from '@/views/Audit/components/AuditMinAndMaxErrorModal.vue';
import { Action, State } from 'vuex-class';
import { AuditStatus } from '@/store/modules/audits/types/audits.types';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import DataCollectionContinuousDiscreteCriteria from './DataCollectionContinuousDiscreteCriteria.vue';
import DataCollectionRangeCriteriaIndividual from './DataCollectionRangeCriteriaIndividual.vue';
import ConfirmationModal from './ConfirmationModal.vue';
import SubmitNewRecordModal from './SubmitNewRecordModal.vue';
import { RootState } from '../../../store/store';
import {
  ParticipantRoles,
  ProjectDetailPayload,
  ProjectSite,
} from '../../../store/modules/projects/types/projects.types';
import { PacesRoles } from '../../../store/types/general.types';
import { useUserEmail } from '../../../utils/user.util';
import { ValidationProvider } from 'vee-validate';
import CriteriaViewUpdateComponent from '../../Project/components/CriteriaViewUpdateComponent.vue';

const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    DataCollectionContinuousDiscreteCriteria,
    DataCollectionRangeCriteriaIndividual,
    ValidationProvider,
    CriteriaViewUpdateComponent,
  },
  data: () => {
    return {
      CRITERION_TYPE,
    };
  },
})
export default class DataCollectionIndividual extends Vue {
  @Prop()
  public auditDetail!: AuditDetail;
  @Prop()
  public singleAuditCriteriaId!: number;
  @Prop()
  public editDataCollectionRecord!: any;
  @Prop()
  public auditDetailAndSettings!: any;
  @Prop()
  public defaultSiteId!: number;
  @Prop()
  public siteList!: any[];
  @Prop()
  public auditCriteriaList!: AuditCriteria[];
  @Prop()
  public auditConfig!: AuditSamplingResponse;
  @Prop()
  public isEdit!: boolean;

  public dirtyDateTimeOption: string = 'Single Date with Time';
  public dirtyRecordedStartDate: Date = new Date();
  public dirtyRecordedEndDate: Date = new Date();
  public dirtyRecordedStartTime: Date = new Date();
  public dirtyRecordedEndTime: Date = new Date();
  public isFormValueChanged: boolean = false;
  public inputValueReferences: Array<{ value: string | number }> = [];
  public commentValueReferences: Array<Partial<{ comments: string }>> = [];
  public resultData: DataCollectionSubmitRequestPayload = {
    auditId: this.auditId,
    auditSiteId: this.defaultSiteId,
    recordedStartDateTime: null,
    recordedEndDateTime: null,
    dataCollectionType: 'individual',
    aid: null,
    criteriaData: [],
    isSingleCriteria: !!this.singleAuditCriteriaId,
    editDataCollectionRecord: this.editDataCollectionRecord,
    comments: '',
  };
  public tempCriteriaOptionValuesData: any[] = [];
  public submitDisabled: boolean = true;
  public totalToDateMessage: boolean = false;
  public startDate: Date = new Date(this.auditDetail.startDate);
  public endDate: Date = new Date(this.auditDetail.endDate);
  public invalidStartDate: boolean = false;
  public invalidEndDate: boolean = false;
  public formKey: number = Math.floor(Math.random() * 999);
  public isMultipleSubmission: boolean = false;
  public isParticipant: boolean = false;
  public identifierErrorMsg: string = '';
  public isCriteriaDisabled: boolean = false;
  public rangeCriteriaErrorStatus: Array<{
    index: number;
    isError: boolean;
  }> = [];
  public continuousDiscreteCriteriaErrorStatus: Array<{
    index: number;
    isError: boolean;
  }> = [];

  public tryToSubmitKey: number = Math.floor(Math.random() * 999);

  @Action('audits/getDataCollectionSummary')
  public getDataCollectionSummary!: (
    payload: DataCollectionSummaryRequestPayload,
  ) => void;

  @Action('projects/getProjectSites')
  public getProjectSites!: (projectId: number) => void;

  @State((state) => state.projects.projectSites)
  public projectSites!: ProjectSite[];

  @State((state) => state.audits.dataCollectionSummary)
  public dataCollectionSummary!: DataCollectionSummaryResponsePayload;

  @State((state) => state.audits.dataCollectionResponse)
  public dataCollectionResponse!: DataCollectionSubmitResponse;

  @State((state: RootState) => state.projects.projectDetail)
  public projectDetail!: ProjectDetailPayload;

  public get isRangeCriteriaError(): boolean {
    return !this.rangeCriteriaErrorStatus.every((status) => !status.isError);
  }

  public get isContinuousDiscreteCriteriaError(): boolean {
    return !this.continuousDiscreteCriteriaErrorStatus.every(
      (status) => !status.isError,
    );
  }

  get projectSiteList(): ProjectSite[] {
    return this.projectSites ? this.projectSites : [];
  }

  public mounted() {
    this.populateData('mounted');
    this.dirtyDateTimeOption = this.auditDetail.auditDataTimestamp;
    this.getCurrentUserRole();
    this.getProjectSites(this.projectDetail.id);
  }

  public editRecordSubmitBtnStatus() {
    this.dirtyRecordedStartTime = this.selectedRecordDateTime(
      this.dirtyRecordedStartTime,
      true,
    );
    this.submitDisabled =
      this.disableButtonBySiteIdentifier() ||
      this.isRangeCriteriaError ||
      this.isContinuousDiscreteCriteriaError;
    this.identifierErrorMsg = this.getIdentifierErrorMsg();
  }

  public populateData(path?: string) {
    this.resultData.criteriaData = [];
    this.tempCriteriaOptionValuesData = [];
    _map(this.auditCriteriaList, (auditCriteria) => {
      if (
        this.singleAuditCriteriaId &&
        this.singleAuditCriteriaId !== auditCriteria.id
      ) {
        return false;
      } else if (
        this.editDataCollectionRecord &&
        this.editDataCollectionRecord.isSingleCriteria &&
        this.editDataCollectionRecord.dataCollectionRecords[0].auditCriteria
          .id !== auditCriteria.id
      ) {
        return false;
      }
      const optionObject: any[] = [];
      let editOptionObject: any[] = [];
      let total = 0;
      let optionTotal = 0;
      let isCommentSectionExpanded: boolean = false;
      let comments: string = '';
      const options = JSON.parse(auditCriteria.criteria.criteriaOptions);
      if (
        auditCriteria.criteria.criteriaType === CRITERION_TYPE.BOOLEAN ||
        auditCriteria.criteria.criteriaType === CRITERION_TYPE.CHECKBOXES ||
        auditCriteria.criteria.criteriaType ===
          CRITERION_TYPE.CHECKBOXES_SINGLE ||
        (auditCriteria.criteria.criteriaType ===
          CRITERION_TYPE.CONTINUOUS_DISCRETE &&
          !this.editDataCollectionRecord) ||
        (auditCriteria.criteria.criteriaType === CRITERION_TYPE.RANGE &&
          !this.editDataCollectionRecord)
      ) {
        _map(options, (option, optionIndex) => {
          const totalToDate = 0;
          optionObject.push({
            option,
            value: 0,
            totalToDate,
          });
          optionTotal = optionTotal + totalToDate;
        });
      } else {
        const totalToDate = 0;
        optionObject.push({
          option: options[2],
          value: 0,
          totalToDate,
        });
        optionTotal = optionTotal + totalToDate;
      }
      if (
        this.editDataCollectionRecord &&
        this.editDataCollectionRecord.dataCollectionRecords
      ) {
        this.editDataCollectionRecord.dataCollectionRecords.forEach(
          (dataCollectionRecord: any) => {
            if (dataCollectionRecord.auditCriteria.id === auditCriteria.id) {
              editOptionObject = JSON.parse(dataCollectionRecord.optionValues);
              total = Number(dataCollectionRecord.total);
              comments = dataCollectionRecord.comments;
              isCommentSectionExpanded = comments.length > 0 ? true : false;
            }
          },
        );
      }
      if (path === 'mounted') {
        this.resultData.auditSiteId = this.defaultSiteId;
      }
      if (this.singleAuditCriteriaId || auditCriteria.isActive) {
        const criteriaDataPayload = {
          auditCriteriId: auditCriteria.id,
          criteria: auditCriteria.criteria,
          optionValues:
            this.editDataCollectionRecord && editOptionObject.length
              ? editOptionObject
              : optionObject,
          order: auditCriteria.order,
          total,
          optionTotal,
          isCommentSectionExpanded,
          comments,
        };
        this.resultData.criteriaData.push(criteriaDataPayload);
      }
    });
    this.resultData.criteriaData = _orderBy(
      this.resultData.criteriaData,
      ['order'],
      ['asc'],
    );
    if (this.editDataCollectionRecord) {
      this.totalToDateMessage = true;
      this.resultData.aid = this.editDataCollectionRecord.aid;
      if (path === 'mounted') {
        this.resultData.auditSiteId = this.editDataCollectionRecord.auditSite.id;
      }
      this.resultData.comments = this.editDataCollectionRecord.comments;
      this.resultData.recordedStartDateTime = this.editDataCollectionRecord
        .recordedStartDateTime
        ? new Date(this.editDataCollectionRecord.recordedStartDateTime)
        : null;
      this.resultData.recordedEndDateTime = this.editDataCollectionRecord
        .recordedEndDateTime
        ? new Date(this.editDataCollectionRecord.recordedEndDateTime)
        : null;

      switch (this.auditDetail.auditDataTimestamp) {
        case 'Single Date':
          this.dirtyRecordedStartDate = new Date(
            this.editDataCollectionRecord.recordedStartDateTime,
          );
          break;
        case 'Single Date with Time':
          this.dirtyRecordedStartDate = new Date(
            this.editDataCollectionRecord.recordedStartDateTime,
          );
          this.dirtyRecordedStartTime = new Date(
            this.editDataCollectionRecord.recordedStartDateTime,
          );
          break;
        case 'Date Range':
          this.dirtyRecordedStartDate = new Date(
            this.editDataCollectionRecord.recordedStartDateTime,
          );
          this.dirtyRecordedEndDate = new Date(
            this.editDataCollectionRecord.recordedEndDateTime,
          );
        case 'Date Range with Time':
          this.dirtyRecordedStartDate = new Date(
            this.editDataCollectionRecord.recordedStartDateTime,
          );
          this.dirtyRecordedStartTime = new Date(
            this.editDataCollectionRecord.recordedStartDateTime,
          );
          this.dirtyRecordedEndDate = new Date(
            this.editDataCollectionRecord.recordedEndDateTime,
          );
          this.dirtyRecordedEndTime = new Date(
            this.editDataCollectionRecord.recordedEndDateTime,
          );
        case 'None':
          this.resultData.recordedStartDateTime = new Date();
      }
    } else {
      /**
       * 1. For case - 'Date Range' || 'Date Range with Time'
       * default RecordedEndDate is same as RecordedStartDate
       *
       * There can be scenarios where user collect data within a time period(multiple days)
       * The default scenario would be user can collect data all at once
       * (Business decision since most of the time people collect data at once)
       * and hence end date is same as start date.
       *
       *
       * 2. A project owner or co-ordinator should able to submit the data collection record
       * into a non-current cycle and the default record date should set to the end date of the cycle
       * (user is allow to select a record date within the cycle),
       */
      const recordedDate: Date =
        this.auditDetail.endDate &&
        new Date() > new Date(this.auditDetail.endDate)
          ? new Date(this.auditDetail.endDate)
          : new Date();
      switch (this.auditDetail.auditDataTimestamp) {
        case 'Single Date':
        case 'Single Date with Time':
          this.resultData.recordedStartDateTime = recordedDate;
          this.dirtyRecordedStartDate = recordedDate;
          this.dirtyRecordedStartTime = recordedDate;
          break;
        case 'Date Range':
          this.dirtyRecordedStartDate = recordedDate;
          this.dirtyRecordedEndDate = recordedDate;
        case 'Date Range with Time':
          this.resultData.recordedStartDateTime = recordedDate;
          this.resultData.recordedEndDateTime = recordedDate;
          this.dirtyRecordedStartDate = recordedDate;
          this.dirtyRecordedEndDate = recordedDate;
          this.dirtyRecordedStartTime = recordedDate;
          this.dirtyRecordedEndTime = recordedDate;
        case 'None':
          this.resultData.recordedStartDateTime = new Date();
      }
    }

    if (this.resultData.auditSiteId) {
      this.getDataCollectionSummary({
        auditId: this.auditId,
        siteIds: String(this.resultData.auditSiteId),
      });
    }

    const criteriaData = _cloneDeep(this.resultData.criteriaData);
    criteriaData.map((criteria: any) => {
      this.tempCriteriaOptionValuesData.push({
        auditCriteriId: criteria.auditCriteriId,
        oldOptionData: criteria.optionValues,
      });
    });

    this.updateResultDataForCriteria();
    this.copyValueReference();
  }

  public getCriteriaSamplingData(criteriaId: number) {
    const optionValues: any = [];
    this.dataCollectionSummary.configurationData.map(
      (dataCollectionSummaryCriteria: DataCollectionSummaryCriteria) => {
        if (criteriaId === dataCollectionSummaryCriteria.criteria.id) {
          _map(
            dataCollectionSummaryCriteria.criteriaSamplingData
              .criteriaOptionsDataDistribution,
            (optionDataKey, optionDataValue) => {
              optionValues.push({
                option: optionDataValue,
                totalToDate: optionDataKey,
                value: 0,
              });
            },
          );
        }
      },
    );
    return optionValues;
  }

  @Watch('dataCollectionSummary')
  @isTruthy
  public watchDataCollectionSummary(
    newValue: DataCollectionSummaryResponsePayload,
  ) {
    if (this.resultData.criteriaData.length > 0) {
      this.isCriteriaDisabled = false;
      _map(
        newValue.configurationData,
        (dataCollectionSummaryCriteria: DataCollectionSummaryCriteria) => {
          _map(this.resultData.criteriaData, (criteriaData) => {
            if (
              criteriaData.criteria.id ===
              dataCollectionSummaryCriteria.criteria.id
            ) {
              if (
                (criteriaData.criteria.criteriaType === CRITERION_TYPE.RANGE ||
                  criteriaData.criteria.criteriaType ===
                    CRITERION_TYPE.CONTINUOUS_DISCRETE) &&
                !this.editDataCollectionRecord
              ) {
                criteriaData.optionValues = [];
              }
              criteriaData.total =
                dataCollectionSummaryCriteria.criteriaSamplingData.total;
              _map(
                dataCollectionSummaryCriteria.criteriaSamplingData
                  .criteriaOptionsDataDistribution,
                (optionDataKey, optionDataValue) => {
                  if (
                    (criteriaData.criteria.criteriaType ===
                      CRITERION_TYPE.RANGE ||
                      criteriaData.criteria.criteriaType ===
                        CRITERION_TYPE.CONTINUOUS_DISCRETE) &&
                    !this.editDataCollectionRecord
                  ) {
                    criteriaData.optionValues.push({
                      option: optionDataValue,
                      totalToDate: optionDataKey,
                      value: 0,
                    });
                  }
                  _map(criteriaData.optionValues, (optionValue) => {
                    if (
                      optionValue.option &&
                      optionDataValue === optionValue.option.toString()
                    ) {
                      optionValue.totalToDate = optionDataKey;
                    }
                  });
                },
              );
            }
          });
        },
      );
      this.formKey += 1;
    } else {
      this.isCriteriaDisabled = true;
    }
  }

  public copyValueReference() {
    this.inputValueReferences = [];
    this.commentValueReferences = [this.resultData];

    const { criteriaData } = this.resultData;

    for (const data of criteriaData) {
      this.commentValueReferences = [...this.commentValueReferences, data];
      for (const optionValue of data.optionValues) {
        this.inputValueReferences = [...this.inputValueReferences, optionValue];
      }
    }
  }

  public checkIsFormDirty() {
    this.copyValueReference();
    if (!this.isFormValueChanged) {
      return true;
    }

    let isDirty = false;

    for (const value of this.inputValueReferences) {
      if (value.value !== 0 && value.value !== '0') {
        isDirty = true;
        break;
      }
    }
    for (const value of this.commentValueReferences) {
      if (value.comments !== '') {
        isDirty = true;
        break;
      }
    }

    return isDirty;
  }

  public updateResultDataForCriteria() {
    if (this.auditConfig.auditSamplingConfigs.auditSamplingType === 'adHoc') {
      const criteriaRemoveIdsList: any[] = [];
      if (this.siteList.length > 1) {
        this.auditCriteriaList.forEach((auditCriteria: AuditCriteria) => {
          _map(this.auditConfig.dataConfigs, (dataConfig) => {
            const findDataConfig = _find(
              dataConfig,
              (dataConfigData: AuditSamplingDataConfig) => {
                return (
                  dataConfigData.auditCriteriaMap &&
                  dataConfigData.auditSiteMap &&
                  dataConfigData.auditCriteriaMap.id === auditCriteria.id &&
                  dataConfigData.auditSiteMap.id ===
                    this.resultData.auditSiteId &&
                  !dataConfigData.isSamplingEnabled
                );
              },
            );
            if (findDataConfig && findDataConfig.isSamplingEnabled === false) {
              criteriaRemoveIdsList.push(auditCriteria.id);
            }
          });
        });
      } else {
        this.auditCriteriaList.forEach((auditCriteria: AuditCriteria) => {
          _map(this.auditConfig.dataConfigs, (dataConfig) => {
            const findDataConfig = _find(
              dataConfig,
              (dataConfigData: AuditSamplingDataConfig) => {
                return (
                  dataConfigData.auditCriteriaMap &&
                  dataConfigData.auditSiteMap === null &&
                  dataConfigData.auditCriteriaMap.id === auditCriteria.id &&
                  !dataConfigData.isSamplingEnabled
                );
              },
            );
            if (findDataConfig && findDataConfig.isSamplingEnabled === false) {
              criteriaRemoveIdsList.push(auditCriteria.id);
            }
          });
        });
      }
      if (criteriaRemoveIdsList.length > 0) {
        this.resultData.criteriaData = _remove(
          this.resultData.criteriaData,
          (criteriaData: any) => {
            return (
              criteriaRemoveIdsList.indexOf(criteriaData.auditCriteriId) === -1
            );
          },
        );
      }
    }
  }

  @Watch('resultData.auditSiteId')
  public changeSiteData(newValue: number) {
    this.populateData('siteChange');
    this.$emit('updateSiteDropdownItem', newValue);
    this.submitDisabled =
      this.disableButtonBySiteIdentifier() ||
      this.isRangeCriteriaError ||
      this.isContinuousDiscreteCriteriaError;
    this.identifierErrorMsg = this.getIdentifierErrorMsg();
  }

  get auditId(): number {
    return this.auditDetail.id;
  }

  get criteriaDataGetter() {
    return this.resultData.criteriaData;
  }

  // Fetch the data collection period start date
  get minDate(): Date {
    return new Date(
      this.startDate.getFullYear(),
      this.startDate.getMonth(),
      this.startDate.getDate(),
    );
  }

  // Fetch dates within start date and end date range
  get maxDate(): Date {
    if (this.auditDetail.auditCategory === 'Cycle') {
      // If end date is a future date, set to today's date.
      if (new Date(this.endDate) > new Date()) {
        return new Date();
      }
      return new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth(),
        this.endDate.getDate(),
      );
    }
    return new Date();
  }

  get minDateTime(): Date {
    const minDateTime: Date = new Date();
    const selectedRecordDate: string = this.getDate(
      this.dirtyRecordedStartDate,
    );
    const auditStartDate: string = this.getDate(this.startDate);
    if (selectedRecordDate === auditStartDate) {
      return this.startDate;
    }

    minDateTime.setHours(0);
    minDateTime.setMinutes(0);
    return minDateTime;
  }

  get maxDateTime(): Date {
    const maxDateTime: Date = new Date();
    const selectedRecordDate: string = this.getDate(
      this.dirtyRecordedStartDate,
    );
    const auditEndDate: string = this.getDate(this.endDate);
    const today: string = this.getDate(new Date());
    // convert dates into millisecond for comparision
    const selectedRecordTime: number = new Date(selectedRecordDate).getTime();
    const todaysTime: number = new Date(today).getTime();

    // If selectedRecordDate is equal to auditEndDate, set max time to auditEndDateTime
    if (selectedRecordDate === auditEndDate) {
      return this.endDate;
    }
    // If selectedRecordDate is equal to today, set max time to now
    if (selectedRecordTime === todaysTime) {
      maxDateTime.setHours(maxDateTime.getHours());
      maxDateTime.setMinutes(maxDateTime.getMinutes());
      return maxDateTime;
    }

    maxDateTime.setHours(23);
    maxDateTime.setMinutes(59);
    return maxDateTime;
  }

  public selectedRecordDateTime(newValue: Date, isStartTime: boolean): Date {
    const todaysDate: string = this.getDate(new Date());
    const dirtyDate: string = this.getDate(newValue);
    const dirtyTime: string = isStartTime
      ? this.getTime(this.dirtyRecordedStartTime)
      : this.getTime(this.dirtyRecordedEndTime);
    const auditStartDate: string = this.getDate(this.startDate);
    const auditEndDate: string = this.getDate(this.endDate);
    const todaysTime: number = new Date().getTime();
    const auditStartTime: number = this.startDate.getTime();
    const auditEndTime: number = this.endDate.getTime();
    const recordedStartDateTime: number = new Date(
      dirtyDate + ' ' + dirtyTime,
    ).getTime();

    /**
     * set recordedStartTime based on selectedRecordDate
     * selectedRecordDate = dirtyDate
     *
     * 1.if selectedRecordDate is equal to auditStartDate and selectedTime is less than auditStartTime,
     * set datetime to startDateTime
     * 2. if selectedRecordDate is equal to auditEndDate and selectedTime is greater than auditEndTime,
     * set datetime to endDateTime
     * 3. if selectedRecordDate is equal to todaysDate and selectedTime is greater than today,
     * set datetime to now
     * 4. default return selected date time.
     */
    if (
      dirtyDate === auditStartDate &&
      recordedStartDateTime < auditStartTime
    ) {
      return this.startDate;
    }

    if (dirtyDate === auditEndDate && recordedStartDateTime > auditEndTime) {
      return this.endDate;
    }

    if (dirtyDate === todaysDate && recordedStartDateTime > todaysTime) {
      return new Date();
    }

    return new Date(dirtyDate + ' ' + dirtyTime);
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  @Watch('dirtyRecordedStartDate')
  @isTruthy
  public watchRecordedStartDate(newValue: Date) {
    const dirtyDate: string = this.getDate(newValue);
    // set recordedStartTime based on selectedRecordDateTime
    this.dirtyRecordedStartTime = this.selectedRecordDateTime(newValue, true);

    if (
      this.auditDetail.auditDataTimestamp === 'Date Range' ||
      this.auditDetail.auditDataTimestamp === 'Date Range with Time'
    ) {
      this.invalidStartDate =
        this.dirtyRecordedStartTime > this.dirtyRecordedEndTime ? true : false;
      if (!this.invalidStartDate) {
        this.invalidEndDate = false;
        this.submitDisabled =
          this.disableButtonBySiteIdentifier() ||
          this.isRangeCriteriaError ||
          this.isContinuousDiscreteCriteriaError;
      } else {
        this.submitDisabled = true;
      }
    }
    if (this.dirtyRecordedStartTime) {
      const dirtyTime = this.getTime(this.dirtyRecordedStartTime);
      this.resultData.recordedStartDateTime = new Date(
        dirtyDate + ' ' + dirtyTime,
      );
    } else {
      this.resultData.recordedStartDateTime = new Date(dirtyDate);
    }
  }

  @Watch('dirtyRecordedEndDate')
  @isTruthy
  public watchRecordedEndDate(newValue: Date) {
    const dirtyDate = this.getDate(newValue);
    // set recordedStartTime based on selectedRecordDate
    this.dirtyRecordedEndTime = this.selectedRecordDateTime(newValue, false);

    if (
      this.auditDetail.auditDataTimestamp === 'Date Range' ||
      this.auditDetail.auditDataTimestamp === 'Date Range with Time'
    ) {
      this.invalidEndDate =
        this.dirtyRecordedStartTime > this.dirtyRecordedEndTime ? true : false;
      if (!this.invalidEndDate) {
        this.invalidStartDate = false;
        this.submitDisabled =
          this.disableButtonBySiteIdentifier() ||
          this.isRangeCriteriaError ||
          this.isContinuousDiscreteCriteriaError;
      } else {
        this.submitDisabled = true;
      }
    }
    if (this.dirtyRecordedEndTime) {
      const dirtyTime = this.getTime(this.dirtyRecordedEndTime);
      this.resultData.recordedEndDateTime = new Date(
        dirtyDate + ' ' + dirtyTime,
      );
    } else {
      this.resultData.recordedEndDateTime = new Date(dirtyDate);
    }
  }

  @Watch('dirtyRecordedStartTime')
  @isTruthy
  public watchRecordedStartTime(newValue: Date) {
    const dirtyTime = this.getTime(newValue);
    const dirtyDate = this.getDate(this.dirtyRecordedStartDate);
    this.resultData.recordedStartDateTime = new Date(
      dirtyDate + ' ' + dirtyTime,
    );

    if (
      this.auditDetail.auditDataTimestamp === 'Date Range' ||
      this.auditDetail.auditDataTimestamp === 'Date Range with Time'
    ) {
      this.invalidStartDate =
        this.dirtyRecordedStartTime > this.dirtyRecordedEndTime ? true : false;
      if (!this.invalidStartDate) {
        this.invalidEndDate = false;
        this.submitDisabled = this.isRangeCriteriaError;
      } else {
        this.submitDisabled = true;
      }
    }
  }

  @Watch('dirtyRecordedEndTime')
  @isTruthy
  public watchRecordedEndTime(newValue: Date) {
    const dirtyTime = this.getTime(newValue);
    const dirtyDate = this.getDate(this.dirtyRecordedEndDate);
    this.resultData.recordedEndDateTime = new Date(dirtyDate + ' ' + dirtyTime);

    if (
      this.auditDetail.auditDataTimestamp === 'Date Range' ||
      this.auditDetail.auditDataTimestamp === 'Date Range with Time'
    ) {
      this.invalidEndDate =
        this.dirtyRecordedStartTime > this.dirtyRecordedEndTime ? true : false;
      if (!this.invalidEndDate) {
        this.invalidStartDate = false;
        this.submitDisabled = this.isRangeCriteriaError;
      } else {
        this.submitDisabled = true;
      }
    }
  }

  @Watch('dataCollectionResponse')
  @isTruthy
  public watchDataCollectionResponse(): void {
    if (this.isMultipleSubmission && !this.isEdit) {
      this.changeSiteData(this.defaultSiteId);
    }
  }

  @Watch('resultData.criteriaData', { deep: true })
  public changeCriteriaData() {
    this.isFormValueChanged = true;
  }

  public getDate(newValue: Date) {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public getTime(newValue: Date) {
    return newValue.getHours() + ':' + newValue.getMinutes() + ':00';
  }

  public formatDate(date: Date) {
    return this.getDate(date);
  }

  public getSiteName(auditSiteId: number) {
    const dirtySite = _find(this.siteList, (siteData) => {
      return siteData.auditSiteId === auditSiteId;
    });
    return dirtySite ? dirtySite.name : '';
  }

  public getSiteIdentifier(auditSiteId: number): boolean {
    const dirtySite = this.siteList.find((siteData) => {
      return siteData.auditSiteId === auditSiteId;
    });
    if (!dirtySite) {
      return false;
    }
    const projectSite: ProjectSite | undefined = this.projectSiteList.find(
      (site: ProjectSite) => site.site.id === dirtySite.siteId,
    );
    return projectSite && projectSite.isIdentifierRequired
      ? projectSite.isIdentifierRequired
      : false;
  }

  public disableButtonBySiteIdentifier(): boolean {
    const siteIdentifier = this.resultData.auditSiteId
      ? this.getSiteIdentifier(this.resultData.auditSiteId)
      : false;
    if (siteIdentifier && !this.resultData.aid) {
      return true;
    } else {
      return false;
    }
  }

  public getIdentifierErrorMsg() {
    const siteIdentifier = this.resultData.auditSiteId
      ? this.getSiteIdentifier(this.resultData.auditSiteId)
      : false;
    if (siteIdentifier && !this.resultData.aid) {
      return `This field cannot be empty. Enter NA if don't have sample code.`;
    } else {
      return '';
    }
  }

  public getCurrentUserRole(): void {
    this.isParticipant = !!this.auditDetail.auditParticipants.find(
      (auditParticipant) => {
        return (
          auditParticipant.userEmail === this.userEmail &&
          auditParticipant.auditParticipantRoles.some(
            (auditParticipantRole) => {
              return (
                !auditParticipantRole.isDeleted &&
                auditParticipantRole.role === ParticipantRoles.dataCollector
              );
            },
          )
        );
      },
    );
  }

  public handleInputChange(rowData: any, index: number) {
    const total = _sumBy(rowData.optionValues, (optionValue: any) => {
      return Number(optionValue.value);
    });
    this.resultData.criteriaData[index].total = total;
  }

  public handleIndividualCircular(
    rowData: any,
    index: number,
    criteriaOptions: any,
    optionIndex: number,
  ) {
    if (rowData.total === 0) {
      this.resultData.criteriaData[index].total = 1;
      this.resultData.criteriaData[index].optionValues[optionIndex].value = 1;
    } else {
      if (rowData.optionValues[optionIndex].value === 1) {
        this.resultData.criteriaData[index].total = 0;
        this.resultData.criteriaData[index].optionValues[optionIndex].value = 0;
      } else {
        this.resultData.criteriaData[index].optionValues.map((temp: any) => {
          if (temp.option === criteriaOptions.option) {
            temp.value = 1;
          } else {
            temp.value = 0;
          }
        });
      }
    }
    let checkTotal = 0;
    for (const temp of this.resultData.criteriaData) {
      checkTotal = checkTotal + temp.total;
    }
    if (checkTotal === 0) {
      this.submitDisabled = true;
    } else {
      this.submitDisabled =
        this.disableButtonBySiteIdentifier() ||
        this.isRangeCriteriaError ||
        this.isContinuousDiscreteCriteriaError;
    }
  }

  public handleIndividualCheckboxSingle(
    rowData: any,
    index: number,
    criteriaOptions: any,
    optionIndex: number,
  ) {
    if (rowData.total === 0) {
      this.resultData.criteriaData[index].total = 1;
      this.resultData.criteriaData[index].optionValues[optionIndex].value = 1;
    } else {
      if (rowData.optionValues[optionIndex].value === 1) {
        this.resultData.criteriaData[index].total = 0;
        this.resultData.criteriaData[index].optionValues[optionIndex].value = 0;
      } else {
        this.resultData.criteriaData[index].optionValues.map((temp: any) => {
          if (temp.option === criteriaOptions.option) {
            temp.value = 1;
          } else {
            temp.value = 0;
          }
        });
      }
    }
    let checkTotal = 0;
    for (const temp of this.resultData.criteriaData) {
      checkTotal = checkTotal + temp.total;
    }
    if (checkTotal === 0) {
      this.submitDisabled = true;
    } else {
      this.submitDisabled =
        this.disableButtonBySiteIdentifier() || this.isRangeCriteriaError;
    }
  }

  public handleIndividualCheckbox(
    rowData: any,
    index: number,
    criteriaOptions: any,
    optionIndex: number,
  ) {
    if (rowData.total === 0) {
      this.resultData.criteriaData[index].total = 1;
      this.resultData.criteriaData[index].optionValues[optionIndex].value = 1;
    } else {
      if (rowData.optionValues[optionIndex].value === 1) {
        this.resultData.criteriaData[index].total =
          this.resultData.criteriaData[index].total - 1;
        this.resultData.criteriaData[index].optionValues[optionIndex].value = 0;
      } else {
        this.resultData.criteriaData[index].total =
          this.resultData.criteriaData[index].total + 1;
        this.resultData.criteriaData[index].optionValues[optionIndex].value = 1;
      }
    }
    let checkTotal = 0;
    for (const temp of this.resultData.criteriaData) {
      checkTotal = checkTotal + temp.total;
    }
    if (checkTotal === 0 || this.invalidStartDate || this.invalidEndDate) {
      this.submitDisabled = true;
    } else {
      this.submitDisabled =
        this.disableButtonBySiteIdentifier() ||
        this.isRangeCriteriaError ||
        this.isContinuousDiscreteCriteriaError;
    }
  }

  public handleEnteredDataRecords() {
    this.tryToSubmitKey += 1;
    queueMicrotask(() => {
      if (this.isRangeCriteriaError) {
        return;
      }
      const auditConfigs: any[] = [];
      _map(this.auditConfig.dataConfigs, (configs: any) => {
        _map(configs, (config: any) => {
          auditConfigs.push(config);
        });
      });

      if (
        this.auditConfig.auditSamplingConfigs.auditSamplingType ===
          'consecutive' &&
        this.auditConfig.auditSamplingConfigs.samplingMode === 'minAndMax' &&
        this.auditConfig.auditSamplingConfigs.validateDataEntry === true
      ) {
        const exceededMaxList: any[] = [];
        this.resultData.criteriaData.forEach((rowData: any) => {
          let currentTotalData: number = 0;
          let sumTotalToDate: number = 0;
          let isValueAdded: boolean = false;
          _map(rowData.optionValues, (item: any) => {
            if (this.isEdit) {
              const oldCriteriaData = _find(
                this.tempCriteriaOptionValuesData,
                (data: any) => {
                  return data.auditCriteriId === rowData.auditCriteriId;
                },
              );
              const oldOption = _find(
                oldCriteriaData.oldOptionData,
                (option: any) => {
                  return option.option === item.option;
                },
              );
              sumTotalToDate += item.totalToDate - oldOption.value;
              currentTotalData += item.value;
            } else {
              sumTotalToDate += item.totalToDate;
              currentTotalData += item.value;
            }
            if (item.value) {
              isValueAdded = true;
            }
          });
          currentTotalData += sumTotalToDate;

          const settingCriteriaData = _find(auditConfigs, (data: any) => {
            if (this.auditDetail.auditSiteType === 'multiSite') {
              if (
                data.auditCriteriaMap.id === rowData.auditCriteriId &&
                data.auditSiteMap &&
                data.auditSiteMap.id === this.resultData.auditSiteId
              ) {
                return data;
              }
            } else {
              return data.auditCriteriaMap.id === rowData.auditCriteriId;
            }
          });
          if (
            settingCriteriaData &&
            Number(settingCriteriaData.maxSampleSize) > 0 &&
            Number(settingCriteriaData.maxSampleSize) < currentTotalData
          ) {
            exceededMaxList.push({
              criteriaData: rowData,
              maxSampleSize: Number(settingCriteriaData.maxSampleSize),
              currentTotalData,
            });
          }
        });
        if (exceededMaxList.length > 0) {
          this.$buefy.modal.open({
            parent: this,
            component: AuditMinAndMaxErrorModal,
            hasModalCard: true,
            trapFocus: true,
            props: {
              modalTitle: 'Warning: Data Collected Exceeded Max. Value',
              exceededMaxList,
            },
            events: {
              emitResultData: () => {
                this.$emit('submitRecords', this.resultData);
              },
            },
          });
        } else {
          this.$emit('submitRecords', this.resultData);
        }
      } else {
        if (this.isEdit) {
          // getting existing data records of inactive criteria.
          const resultCriteriaData: any[] = [];
          this.editDataCollectionRecord.dataCollectionRecords.forEach(
            (dataCollectionRecord: any) => {
              const criteriaData = this.resultData.criteriaData.find(
                (criteria) =>
                  criteria.auditCriteriId ===
                  dataCollectionRecord.auditCriteria.id,
              );

              if (criteriaData) {
                resultCriteriaData.push(criteriaData);
              } else {
                let optionTotal = 0;
                JSON.parse(dataCollectionRecord.optionValues).forEach(
                  (option: any) => {
                    optionTotal = optionTotal + Number(option.optionTotal);
                  },
                );

                const criteriaDataPayload = {
                  auditCriteriId: dataCollectionRecord.auditCriteria.id,
                  criteria: dataCollectionRecord.auditCriteria.criteria,
                  optionValues: JSON.parse(dataCollectionRecord.optionValues),
                  order: dataCollectionRecord.auditCriteria.order,
                  total: dataCollectionRecord.total,
                  optionTotal,
                  isCommentSectionExpanded:
                    dataCollectionRecord.comments.length > 0 ? true : false,
                  comments: dataCollectionRecord.comments,
                };
                resultCriteriaData.push(criteriaDataPayload);
              }
            },
          );

          this.$emit('submitRecords', {
            ...this.resultData,
            criteriaData: resultCriteriaData,
          });
        } else {
          this.$emit('submitRecords', this.resultData);
        }
      }
      if (this.isMultipleSubmission) {
        this.resetFormFields();
      }
    });
  }

  public handleMultipleSubmission(): void {
    this.tryToSubmitKey += 1;
    queueMicrotask(() => {
      if (this.isRangeCriteriaError || this.isContinuousDiscreteCriteriaError) {
        return;
      }
      if (this.isMultipleSubmission) {
        return this.handleEnteredDataRecords();
      }
      this.$buefy.modal.open({
        parent: this,
        component: SubmitNewRecordModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          isParticipant: this.isParticipant,
        },
        events: {
          confirm: (isSubmitAnotherRecord: boolean) => {
            this.isMultipleSubmission = isSubmitAnotherRecord;
            this.$emit('isMultipleSubmission', isSubmitAnotherRecord);
            this.handleEnteredDataRecords();
          },
        },
      });
    });
  }

  public resetFormFields(): void {
    this.resultData.comments = '';
    this.resultData.aid = '';
    this.identifierErrorMsg = '';
  }

  public showCommentBox(rowData: any) {
    rowData.isCommentSectionExpanded = !rowData.isCommentSectionExpanded;
  }

  public getTotalByOption(option: string, dataCollectionRecords: any[]) {
    let total = 0;
    _map(dataCollectionRecords, (dataCollectionRecord) => {
      if (
        dataCollectionRecord.dataCollection.auditSite.id ===
        this.resultData.auditSiteId
      ) {
        const optionValues = JSON.parse(dataCollectionRecord.optionValues);
        _map(optionValues, (optionWithValue) => {
          if (optionWithValue.option === option) {
            total = total + Number(optionWithValue.value);
          }
        });
      }
    });
    return total;
  }

  public navigateToOverview(): void {
    if (!this.checkIsFormDirty()) {
      return this.$router.go(-1);
    }
    const backPageLabel: string = !this.isParticipant
      ? 'Overview'
      : 'Project Details';
    const title: string = `Back to ${backPageLabel}?`;
    const content: string = `Are you sure you want to go back to ${backPageLabel}?
       There are new records not submitted and will be discarded.`;
    const confirmButtonLabel: string = `Back to ${backPageLabel}`;
    const cancelButtonLabel: string = 'Cancel';
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmationModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title,
        content,
        confirmButtonLabel,
        cancelButtonLabel,
      },
      events: {
        confirm: () => {
          this.$router.go(-1);
        },
      },
    });
  }

  public getTotalByRangeOption(option: string, dataCollectionRecords: any[]) {
    let total = 0;
    _map(dataCollectionRecords, (dataCollectionRecord) => {
      if (
        dataCollectionRecord.dataCollection.auditSite.id ===
        this.resultData.auditSiteId
      ) {
        const optionValues = JSON.parse(dataCollectionRecord.optionValues);
        _map(optionValues, (optionWithValue) => {
          if (optionWithValue.option === parseInt(option, 10)) {
            total = total + Number(optionWithValue.value);
          }
        });
      }
    });
    return total;
  }

  public getTotalByRangeOptionNA(option: string, dataCollectionRecords: any[]) {
    let total = 0;
    _map(dataCollectionRecords, (dataCollectionRecord) => {
      if (
        dataCollectionRecord.dataCollection.auditSite.id ===
        this.resultData.auditSiteId
      ) {
        const optionValues = JSON.parse(dataCollectionRecord.optionValues);
        _map(optionValues, (optionWithValue) => {
          if (optionWithValue.option === option) {
            total = total + Number(optionWithValue.value);
          }
        });
      }
    });
    return total;
  }

  public handleSubmitButton(value: boolean): void {
    this.submitDisabled =
      this.disableButtonBySiteIdentifier() ||
      this.isRangeCriteriaError ||
      this.isContinuousDiscreteCriteriaError ||
      value;
  }

  public handleRangeCriteriaSubmitButton(value: {
    index: number;
    isError: boolean;
  }): void {
    const criteriaIndex = this.rangeCriteriaErrorStatus.findIndex(
      (status) => status.index === value.index,
    );
    criteriaIndex !== -1
      ? (this.rangeCriteriaErrorStatus[criteriaIndex].isError = value.isError)
      : this.rangeCriteriaErrorStatus.push(value);
    this.handleSubmitButton(
      this.isRangeCriteriaError || this.isContinuousDiscreteCriteriaError,
    );
  }

  public handleContinuousDiscreteCriteriaSubmitButton(value: {
    index: number;
    isError: boolean;
  }) {
    const criteriaIndex = this.continuousDiscreteCriteriaErrorStatus.findIndex(
      (status) => status.index === value.index,
    );
    criteriaIndex !== -1
      ? (this.continuousDiscreteCriteriaErrorStatus[criteriaIndex].isError =
          value.isError)
      : this.continuousDiscreteCriteriaErrorStatus.push(value);

    this.handleSubmitButton(
      this.isRangeCriteriaError || this.isContinuousDiscreteCriteriaError,
    );
  }
}
