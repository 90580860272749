




















































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import Card from '@/components/Card.vue';
import MultiSiteTable from '@/components/reports/MultiSiteTable.vue';
import VerticalBarChart from '@/components/reports/VerticalBarChart';
import HorizontalBarChart from '@/components/reports/HorizontalBarChart';
import {
  AuditSite,
  AuditCriteria,
  AuditReportFiltersConfig,
  AuditReportSinglePeriodResponse,
  ChartRecords,
  ChartType,
} from '@/store/modules/audits/types/audits.types';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import {
  chartColorSet,
  primaryChartColor,
  labelSplitIndex,
} from '@/store/types/general.types';
import CriteriaViewUpdateComponent from '../../Project/components/CriteriaViewUpdateComponent.vue';

@Component({
  components: {
    Card,
    MultiSiteTable,
    VerticalBarChart,
    HorizontalBarChart,
    CriteriaViewUpdateComponent,
  },
})
export default class MultiSiteReport extends Vue {
  @Prop()
  public analysisFilter!: AuditReportFiltersConfig;
  @Prop()
  public auditSitelist!: AuditSite[];
  @Prop()
  public auditCriteriaList!: AuditCriteria[];
  @Prop()
  public reportResponse!: AuditReportSinglePeriodResponse;

  public dataSet: any[] = [];
  public dataSetOptions: any[] = [];
  public loadingSet: boolean[] = [];
  public criteriaList: Array<AuditCriteria['criteria']> = [];
  public verticalBarChartDataSetOption: any = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Compliance %',
            display: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Criteria',
            display: false,
          },
          ticks: {
            minRotation: 0,
            callback: (label: string, index: number) => {
              return this.generateLabel(label, index);
            },
          },
        },
      ],
    },
    legend: {
      display: false,
      align: 'start',
      onHover: (e: any) => {
        e.target.style.cursor = 'pointer';
      },
      onLeave: (e: any) => {
        e.target.style.cursor = 'default';
      },
      labels: {
        boxWidth: 15,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          if (tooltipItem.yLabel < 0) {
            label += 'N/A';
          } else {
            label += Math.round(tooltipItem.yLabel * 100) / 100;
          }
          return label;
        },
      },
    },
  };
  public horizontalBarChartDataSetOption: any = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Compliance %',
            display: true,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Criteria',
            display: false,
          },
          ticks: {
            minRotation: 0,
            callback: (label: string, index: number) => {
              return this.generateLabel(label, index);
            },
          },
        },
      ],
    },
    legend: {
      display: false,
      align: 'start',
      onHover: (e: any) => {
        e.target.style.cursor = 'pointer';
      },
      onLeave: (e: any) => {
        e.target.style.cursor = 'default';
      },
      labels: {
        boxWidth: 15,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          if (tooltipItem.xLabel < 0) {
            label += 'N/A';
          } else {
            label += Math.round(tooltipItem.xLabel * 100) / 100;
          }
          return label;
        },
      },
    },
  };
  public individualBooleanCriteriaConfiguration: any[] = [];
  public individualMixedCriteriaConfiguration: any[] = [];
  public aggregateCriteriaConfiguration: any[] = [];
  public renderOverallStatisticTable: boolean = true;

  public mounted() {
    this.populateData();
  }

  public populateData() {
    if (!this.reportResponse.success) {
      this.dataSet = [];
      this.dataSetOptions = [];
      this.loadingSet = [];
      this.individualBooleanCriteriaConfiguration = [];
      this.individualMixedCriteriaConfiguration = [];
    } else {
      this.dataSet = [];
      this.dataSetOptions = [];
      this.loadingSet = [];
      this.individualBooleanCriteriaConfiguration = [];
      this.individualMixedCriteriaConfiguration = [];
      const chartData: ChartRecords[] = this.reportResponse.chartData;
      this.generateConfigurationData(
        this.reportResponse.statisticData.configurationData,
        this.reportResponse.statisticData.samplingConfiguration,
      );
      if (chartData.length > 0) {
        this.multiSiteRender(chartData);
      }
    }
  }

  public multiSiteRender(chartData: ChartRecords[]) {
    if (this.analysisFilter.checkIndividualSite) {
      if (this.analysisFilter.checkIndividualCriteria) {
        this.renderIndividualSiteIndividualCriteria(chartData);
      } else {
        this.renderIndividualSiteAggregateCriteria(chartData);
      }
    } else {
      if (this.analysisFilter.checkIndividualCriteria) {
        this.renderAggregateSiteIndividualCriteria(chartData);
      } else {
        this.renderAgregateSiteAggregateCriteria(chartData);
      }
    }
  }

  public renderIndividualSiteIndividualCriteria(chartData: ChartRecords[]) {
    const backgroundColors: string[] = [];
    _map(this.analysisFilter.filteredSites, () => {
      backgroundColors.push(primaryChartColor);
    });
    Object.keys(chartData).forEach((key: string) => {
      const data = {
        labels: [] as string[],
        datasets: [] as any[],
      };
      let dataOptions = {};
      const dirtyChartData = chartData[Number(key)];
      const label: string[] = [];
      const chartValue: number[] = [];
      _map(dirtyChartData.data, (dirtyData) => {
        label.push(dirtyData.site.name);
        chartValue.push(dirtyData.compliance);
      });
      const dataSetObject = {
        label: dirtyChartData.criteria.title,
        backgroundColor: backgroundColors,
        pointBackgroundColor: 'white',
        borderWidth: 1,
        pointBorderColor: '#249EBF',
        data: chartValue,
        categoryPercentage: this
          .getIndividualCriteriaIndividualSiteChartCategoryPercentage,
      };
      let dataOptionsObject;
      if (
        this.analysisFilter.selectedChartType === ChartType.verticalBarChart
      ) {
        dataOptionsObject = _cloneDeep(this.verticalBarChartDataSetOption);
      } else {
        dataOptionsObject = _cloneDeep(this.horizontalBarChartDataSetOption);
      }
      data.labels = _cloneDeep(label);
      data.datasets.push(dataSetObject);
      dataOptions = _cloneDeep(dataOptionsObject);
      this.dataSet.push(data);
      this.dataSetOptions.push(dataOptions);
      this.loadingSet.push(true);
      this.criteriaList.push(dirtyChartData.criteria);
    });
  }

  public renderAggregateSiteIndividualCriteria(chartData: ChartRecords[]) {
    Object.keys(chartData).forEach((key: string) => {
      const data = {
        labels: [] as string[],
        datasets: [] as any[],
      };
      let dataOptions = {};
      const dirtyChartData = chartData[Number(key)];
      let label: string;
      switch (this.analysisFilter.filteredSites.length) {
        case 1:
          label = dirtyChartData.data[0].site.name;
          break;
        case this.auditSitelist.length:
          label = 'All Sites';
          break;
        default:
          label =
            'Selected sites (' + this.analysisFilter.filteredSites.length + ')';
      }
      const chartValue: number = dirtyChartData.data[0].compliance;
      const dataSetObject = {
        label: dirtyChartData.criteria.title,
        backgroundColor: [primaryChartColor],
        pointBackgroundColor: 'white',
        borderWidth: 1,
        pointBorderColor: '#249EBF',
        data: [chartValue],
        categoryPercentage: this
          .getIndividualCriteriaAggregateSiteChartCategoryPercentage,
      };
      let dataOptionsObject;
      if (
        this.analysisFilter.selectedChartType === ChartType.verticalBarChart
      ) {
        dataOptionsObject = _cloneDeep(this.verticalBarChartDataSetOption);
      } else {
        dataOptionsObject = _cloneDeep(this.horizontalBarChartDataSetOption);
      }
      data.labels.push(label);
      data.datasets.push(dataSetObject);
      dataOptions = _cloneDeep(dataOptionsObject);
      this.dataSet.push(data);
      this.dataSetOptions.push(dataOptions);
      this.loadingSet.push(true);
      this.criteriaList.push(dirtyChartData.criteria);
    });
  }

  public renderIndividualSiteAggregateCriteria(chartData: ChartRecords[]) {
    const data = {
      labels: [] as string[],
      datasets: [] as any[],
    };
    let dataOptions = {};
    _map(
      this.analysisFilter.filteredSites,
      (auditSiteMapId: number, auditSiteMapIdIndex: string) => {
        const backgroundColors: string[] = this.getIndividualSiteColors(
          Number(auditSiteMapIdIndex),
        );
        const chartValues: number[] = this.getChartValues(
          chartData,
          auditSiteMapId,
        );
        const dataSetObject = {
          label: this.getSiteNameByAuditSiteMapId(auditSiteMapId),
          backgroundColor: backgroundColors,
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: chartValues,
          categoryPercentage: this.getAggregateCriteriaChartCategoryPercentage,
        };
        data.datasets.push(dataSetObject);
      },
    );
    let dataOptionsObject;
    if (this.analysisFilter.selectedChartType === ChartType.verticalBarChart) {
      dataOptionsObject = _cloneDeep(this.verticalBarChartDataSetOption);
    } else {
      dataOptionsObject = _cloneDeep(this.horizontalBarChartDataSetOption);
    }
    dataOptionsObject.legend.display = true;
    data.labels = this.getCriteriaNames(chartData);
    dataOptions = _cloneDeep(dataOptionsObject);
    this.dataSet.push(data);
    this.dataSetOptions.push(dataOptions);
    this.loadingSet.push(true);
  }

  public renderAgregateSiteAggregateCriteria(chartData: ChartRecords[]) {
    let siteName: string;
    switch (this.analysisFilter.filteredSites.length) {
      case 1:
        siteName = chartData[0].data[0].site.name;
        break;
      case this.auditSitelist.length:
        siteName = 'All Sites';
        break;
      default:
        siteName =
          'Selected sites (' + this.analysisFilter.filteredSites.length + ')';
    }
    const data = {
      labels: [] as string[],
      datasets: [] as any[],
    };
    let dataOptions = {};
    const labels: string[] = [];
    const chartValues: number[] = [];
    const backgroundColors: string[] = [];
    Object.keys(chartData).map((key: string) => {
      labels.push(chartData[Number(key)].criteria.title);
      chartValues.push(chartData[Number(key)].data[0].compliance);
      backgroundColors.push(primaryChartColor);
    });
    const dataSetObject = {
      label: siteName,
      backgroundColor: backgroundColors,
      pointBackgroundColor: 'white',
      borderWidth: 1,
      pointBorderColor: '#249EBF',
      data: chartValues,
      categoryPercentage: this.getAggregateCriteriaChartCategoryPercentage,
    };
    let dataOptionsObject;
    if (this.analysisFilter.selectedChartType === ChartType.verticalBarChart) {
      dataOptionsObject = _cloneDeep(this.verticalBarChartDataSetOption);
    } else {
      dataOptionsObject = _cloneDeep(this.horizontalBarChartDataSetOption);
    }
    data.labels = _cloneDeep(labels);
    data.datasets = [];
    data.datasets.push(dataSetObject);
    dataOptions = _cloneDeep(dataOptionsObject);
    this.dataSet.push(data);
    this.dataSetOptions.push(dataOptions);
    this.loadingSet.push(true);
  }

  public generateConfigurationData(
    configurationData: any[],
    samplingConfiguration: any,
  ) {
    if (this.analysisFilter.checkIndividualSite) {
      if (Array.isArray(configurationData) && configurationData.length > 0) {
        const aggregateConfig = configurationData.map((data: any) => {
          const criteriaSamplingData = data.criteriaSamplingData.filter(
            (item: any) => {
              const criteriaOptionsDataDistribution =
                item.criteriaOptionsDataDistribution;
              const dirtyCriteriaSamplingData = Object.keys(
                criteriaOptionsDataDistribution,
              ).map((key: string) => ({
                [key]:
                  data.criteriaSamplingData[0].criteriaOptionsDataDistribution[
                    key
                  ],
              }));
              return dirtyCriteriaSamplingData;
            },
          );
          return {
            id: data.criteria.id,
            title: _get(data, 'criteria.title', ''),
            criteriaType: _get(data, 'criteria.criteriaType', ''),
            sampleSize: Number(
              _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
            ),
            criteriaOptions: JSON.parse(data.criteria.criteriaOptions),
            siteSamplingConfiguration: data.siteSamplingDataConfigurations,
            criteriaSamplingDataConfiguration:
              data.criteriaSamplingDataConfigurations,
            samplingConfiguration,
            criteriaSamplingData,
          };
        });
        this.aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
      } else {
        this.aggregateCriteriaConfiguration = [];
      }
    } else {
      if (Array.isArray(configurationData) && configurationData.length > 0) {
        const aggregateConfig = configurationData.map((data: any) => {
          const criteriaOptionsDataDistribution =
            data.criteriaSamplingData.criteriaOptionsDataDistribution;
          const criteriaSamplingData = Object.keys(
            criteriaOptionsDataDistribution,
          ).map((key: string) => ({
            [key]:
              data.criteriaSamplingData.criteriaOptionsDataDistribution[key],
          }));
          return {
            id: data.criteria.id,
            title: _get(data, 'criteria.title', ''),
            criteriaType: _get(data, 'criteria.criteriaType', ''),
            sampleSize: Number(
              _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
            ),
            criteriaOptions: JSON.parse(data.criteria.criteriaOptions),
            siteSamplingConfiguration: data.siteSamplingDataConfigurations,
            criteriaSamplingDataConfiguration:
              data.criteriaSamplingDataConfigurations,
            samplingConfiguration,
            criteriaSamplingData,
          };
        });
        this.aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
      } else {
        this.aggregateCriteriaConfiguration = [];
      }
    }
    this.generateIndividualCriteriaSet(this.aggregateCriteriaConfiguration);
  }

  public generateIndividualCriteriaSet(aggregateCriteriaConfiguration: any[]) {
    this.individualBooleanCriteriaConfiguration = aggregateCriteriaConfiguration.filter(
      (item) => {
        return item.criteriaType === CRITERION_TYPE.BOOLEAN;
      },
    );
    this.individualMixedCriteriaConfiguration = aggregateCriteriaConfiguration.filter(
      (item) => {
        return item.criteriaType !== CRITERION_TYPE.BOOLEAN;
      },
    );
    if (
      this.individualBooleanCriteriaConfiguration.length ===
        this.aggregateCriteriaConfiguration.length &&
      this.analysisFilter.checkIndividualCriteria
    ) {
      this.renderOverallStatisticTable = false;
    }
  }

  public getMixedBooleanTitleIndex(index: number): number {
    return this.individualBooleanCriteriaConfiguration.length + index + 1;
  }

  public getChartValues(
    chartData: ChartRecords[],
    auditSiteMapId: number,
  ): number[] {
    const chartValues: number[] = [];
    Object.keys(chartData).map((key: string) => {
      const dirtyChartData = chartData[Number(key)];
      _map(dirtyChartData.data, (dirtyData, dirtyDataIndex) => {
        if (dirtyData.auditSiteId === auditSiteMapId) {
          chartValues.push(dirtyData.compliance);
        }
      });
    });
    return chartValues;
  }

  public getSiteNameByAuditSiteMapId(auditSiteMapId: number): string {
    const siteName = this.auditSitelist.filter(
      (auditSite) => auditSite.id === auditSiteMapId,
    );
    return siteName[0].site.name;
  }

  public getCriteriaNames(chartData: ChartRecords[]): string[] {
    const criteriaNames: string[] = [];
    Object.keys(chartData).map((key: string) => {
      criteriaNames.push(chartData[Number(key)].criteria.title);
    });
    return criteriaNames;
  }

  public getBackgroundColors(): string[] {
    const backgroundColors: string[] = [];
    _map(
      this.analysisFilter.filteredCriterias,
      (auditCriteriaMapId: number, auditCriteriaMapIndex: string) => {
        backgroundColors.push(primaryChartColor);
      },
    );
    return backgroundColors;
  }

  public getIndividualSiteColors(index: number): string[] {
    const backgroundColors: string[] = [];
    _map(
      this.analysisFilter.filteredCriterias,
      (auditCriteriaMapId: number, auditCriteriaMapIndex: string) => {
        backgroundColors.push(chartColorSet[index]);
      },
    );
    return backgroundColors;
  }

  public generateLabel = (label: string, index: number): string | string[] => {
    if (this.analysisFilter.checkIndividualCriteria) {
      return this.transformLabel(label);
    } else {
      if (this.analysisFilter.filteredCriterias.length > 8) {
        return index + 1 + '.';
      }
      return this.transformLabel(label);
    }
  };

  public transformLabel(label: string): string | string[] {
    const words = label.split(' ');
    let endIndex: number = labelSplitIndex;
    let eachLine: string = '';
    const eachLabel: string[] = [];
    _map(words, (word: string, wordIndex: string) => {
      switch (Number(wordIndex)) {
        case 0:
          eachLine = word;
          break;
        case endIndex:
          eachLabel.push(eachLine);
          eachLine = word;
          endIndex += labelSplitIndex;
          break;
        case words.length - 1:
          eachLine += ' ' + word;
          eachLabel.push(eachLine);
          break;
        default:
          eachLine += ' ' + word;
      }
    });
    if (eachLabel.length >= 3) {
      eachLabel[2] = eachLabel[2] + '...';
      return eachLabel.slice(0, 3);
    }
    return label;
  }

  get getIndividualCriteriaAggregateSiteChartCategoryPercentage(): number {
    const noOfBooleanCriterias: number = this
      .individualBooleanCriteriaConfiguration.length;
    if (this.analysisFilter.selectedChartType === ChartType.verticalBarChart) {
      return 0.1;
    } else {
      return 0.2;
    }
  }

  get getAggregateCriteriaChartCategoryPercentage(): number {
    const noOfBooleanCriterias: number = this
      .individualBooleanCriteriaConfiguration.length;
    return noOfBooleanCriterias <= 10 ? noOfBooleanCriterias * 0.1 : 0.8;
  }

  get getIndividualCriteriaIndividualSiteChartCategoryPercentage(): number {
    const noOfSites: number = this.analysisFilter.filteredSites.length;
    if (this.analysisFilter.selectedChartType === ChartType.verticalBarChart) {
      return noOfSites <= 10 ? noOfSites * 0.1 : 0.8;
    } else {
      return noOfSites <= 10 ? noOfSites * 0.2 : 0.8;
    }
  }

  get getSelectedCriteriaList(): AuditCriteria[] {
    const selectedCriteriaList: any[] = [];
    this.analysisFilter.filteredCriterias.forEach(
      (auditCriteriaMapId: number) => {
        const auditCriteria = this.auditCriteriaList.find((criteria) => {
          return (
            criteria.id === auditCriteriaMapId &&
            criteria.criteria.criteriaType === CRITERION_TYPE.BOOLEAN
          );
        });
        if (auditCriteria) {
          selectedCriteriaList.push(auditCriteria);
        }
      },
    );
    return selectedCriteriaList;
  }
}
