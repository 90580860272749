






























































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import BaseDropDown from '@/components/base/BaseDropDown.vue';
import {
  CreateProjectParticipantPayload,
  ParticipantRoles,
  ProjectParticipantDataTrfObj,
  Site,
  UpdateProjectParticipantPayload,
} from '@/store/modules/projects/types/projects.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { RootState } from '@/store/store';
import { ToastProgrammatic as Toast } from 'buefy';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ApiState } from '@/store/types/general.types';
import { cloneData } from '@/utils/general.util';

@Component({
  components: {
    BaseDropDown,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ParticipantForm extends Vue {
  @Prop() public participant!: ProjectParticipantDataTrfObj;
  @Prop() public isEdit!: boolean;
  @Prop() public sites!: Site[];
  @Prop() public projectOwnerEmail!: string;
  @Prop() public currentUserEmail!: string;
  @Prop() public participantsList!: ProjectParticipantDataTrfObj[];

  @Action('projects/getProjectParticipants')
  public getProjectParticipants!: (projectId: number) => void;

  @Action('projects/createProjectParticipant')
  public createProjectParticipant!: (
    payload: CreateProjectParticipantPayload,
  ) => void;

  @Action('projects/updateProjectParticipant')
  public updateProjectParticipant!: (
    payload: UpdateProjectParticipantPayload,
  ) => void;

  @State((state: RootState) => state.projects.apiState.createProjectParticipant)
  public createProjectParticipantState!: ApiState;

  @State((state: RootState) => state.projects.apiState.updateProjectParticipant)
  public updateProjectParticipantState!: ApiState;

  public form = {
    userEmail: '',
    participantRole: null as any,
    participantSites: [] as Site[],
    participantRoleId: 0,
  };
  public checkedAll = false;
  public isRoleProjectCoordinator: boolean = false;
  public selectedSiteNames: string = '';
  public isSameProjectOwnerEmail: boolean = false;
  public isSameParticipantEmail: boolean = false;
  public existingParticipant: ProjectParticipantDataTrfObj | undefined;
  public showErrorMessage: boolean = false;

  get projectId() {
    return +this.$route.params.projectId;
  }

  get roleOptions() {
    return [
      {
        label: ParticipantRoles.dataCollector,
        value: ParticipantRoles.dataCollector,
      },
      {
        label: ParticipantRoles.projectCoOrdinator,
        value: ParticipantRoles.projectCoOrdinator,
      },
    ];
  }

  get siteOptions() {
    if (Array.isArray(this.sites)) {
      return this.sites;
    }
    return [];
  }

  get displaySelectedSiteName() {
    // participantSites can be undefined
    // We only proceed when all data is NOT undefined.
    if (
      this.form.participantSites.length === 0 ||
      this.form.participantSites.some(
        (participantSites) => participantSites === undefined,
      )
    ) {
      return 'Select Sites';
    }

    const siteNames: Site[] = [];
    this.form.participantSites.forEach((site) => {
      const selectedAuditSite: Site | undefined = this.siteOptions
        .filter((option) => option !== undefined) // can be undefined
        .find((option: Site) => site.id === option.id);

      if (selectedAuditSite) {
        siteNames.push(selectedAuditSite);
      }
    });

    if (siteNames.length) {
      this.selectedSiteNames = this.convertArrayItemIntoString(siteNames);
    }

    const selectedSiteName =
      this.selectedSiteNames.length > 65
        ? this.selectedSiteNames.substring(0, 65) + '...'
        : this.selectedSiteNames;

    return this.checkedAll ? 'All Sites' : selectedSiteName;
  }

  // Except project owners other participants can only add participants with 'Data Collector' role
  get restrictedRoleOptions() {
    if (this.projectOwnerEmail !== this.currentUserEmail) {
      return [{ label: 'Data Collector', value: 'Data Collector' }];
    }
    return this.roleOptions;
  }

  public mounted() {
    if (this.participant) {
      const participant: ProjectParticipantDataTrfObj = cloneData(
        this.participant,
      );
      const {
        userEmail,
        participantRole,
        participantRoleId,
        participantSites,
      } = participant;
      this.form.userEmail = userEmail;
      this.form.participantRole =
        this.roleOptions.find(({ value }) => value === participantRole) || null;

      const data: Site[] = [];
      participantSites.forEach((participantSite) => {
        if (!participantSite.isDeleted) {
          const site = this.siteOptions.find(
            (siteOption) => siteOption.id === participantSite.site.id,
          );
          if (site) {
            data.push(site);
          }
        }
      });
      this.form.participantSites = data;
      this.form.participantRoleId = participantRoleId || 0;
    } else {
      // this.form.participantSites = cloneData(this.siteOptions);
    }
  }

  public handleCheckAll(value: boolean) {
    this.checkedAll = value;
    this.form.participantSites = [];
    if (value) {
      this.form.participantSites = cloneData(this.siteOptions);
    }
  }

  public handleSubmit() {
    this.showErrorMessage = false;
    if (
      this.isSameProjectOwnerEmail ||
      this.checkParticipantExistsByRoleAndSite()
    ) {
      this.showErrorMessage = true;
    } else if (this.isEdit) {
      this.$emit('close');
      this.updateProjectParticipant({
        projectId: this.projectId,
        id: this.participant.id,
        userEmail: this.form.userEmail,
        participantRole: this.form.participantRole.value,
        participantRoleId: this.form.participantRoleId,
        participantSites: this.form.participantSites.map(
          (participantSite: Site) => participantSite.id,
        ),
      });
    } else {
      this.$emit('close');
      this.createProjectParticipant({
        projectId: this.projectId,
        userEmail: this.form.userEmail,
        participantRole: this.form.participantRole.value,
        participantSites: this.form.participantSites.map(
          (participantSite: Site) => participantSite.id,
        ),
      });
    }
  }

  public convertArrayItemIntoString(data: any[]) {
    const values = data.map((item: any) => `${item.name}`).join(', ');
    return `${values}`;
  }

  @Watch('form.participantSites', { deep: true })
  public onCheck(value: any[]) {
    this.checkedAll = false;
    if (
      value
        .filter((v) => v !== undefined)
        .map(({ id }) => id)
        .sort()
        .toString() ===
      this.siteOptions
        .map(({ id }) => id)
        .sort()
        .toString()
    ) {
      this.checkedAll = true;
    }
  }

  @Watch('form.userEmail', { deep: true })
  public onCheckUserEmail(participantEmail: string) {
    if (this.participantsList) {
      this.existingParticipant = Object(this.participantsList).find(
        (data: ProjectParticipantDataTrfObj) => {
          let matchingCondition: boolean =
            data.userEmail.toLowerCase() === participantEmail.toLowerCase();
          // While editing avoid checking the conditions with the same participant
          if (this.isEdit) {
            matchingCondition =
              matchingCondition &&
              this.participant.participantRoleId !== data.participantRoleId;
          }
          return matchingCondition;
        },
      );
    }
    switch (participantEmail) {
      case this.projectOwnerEmail.toLowerCase():
        this.isSameProjectOwnerEmail = true;
        break;
      case this.existingParticipant &&
        this.existingParticipant.userEmail.toLowerCase():
        this.isSameParticipantEmail = true;
        break;
      default:
        this.isSameParticipantEmail = false;
        this.isSameProjectOwnerEmail = false;
    }
  }

  @Watch('createProjectParticipantState.success')
  @isTruthy
  public onCreateSuccess(): void {
    this.getProjectParticipants(this.projectId);
    this.$emit('close');
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Participant added successfully`,
    });
  }

  @Watch('updateProjectParticipantState.success')
  @isTruthy
  public updateSuccess(): void {
    this.getProjectParticipants(this.projectId);
    this.$emit('close');
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Changes saved`,
    });
  }

  /**
   * Following scenarios are handled while checking if the participants exists or not
   *
   * 1. If the participant roles are the same then the participant exists.
   * 2. The participant exists if at least on of the sites match with the existing participant
   */
  private checkParticipantExistsByRoleAndSite(): boolean {
    const existingParticipant: ProjectParticipantDataTrfObj | undefined = this
      .existingParticipant;

    // Condition: If the participant roles are the same then the participant exists
    if (
      existingParticipant &&
      this.form.participantRole.value === existingParticipant.participantRole
    ) {
      return true;
    }

    // Condition: The participant exists if at least on of the sites match with the existing participant
    const isSiteSame: boolean = false;
    if (existingParticipant) {
      existingParticipant.participantSites.some((existingParticipantSite) => {
        return Boolean(
          this.form.participantSites.find(
            (site: Site) => site.id === existingParticipantSite.site.id,
          ),
        );
      });
    }
    return Boolean(isSiteSame);
  }
}
