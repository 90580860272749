



























































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '../../layouts/DefaultLayout.vue';
import HeroBar from '@/components/base/HeroBar.vue';
import { Action, State } from 'vuex-class';
import {
  ProjectListPagePayload,
  GetProjectListPayload,
} from '@/store/modules/projects/types/projects.types';
import { ToastProgrammatic as Toast } from 'buefy';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { AxiosError } from 'axios';
import BasePagination from '@/components/base/BasePagination.vue';
import { PacesRoles } from '@/store/types/general.types';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/utils/date.util';
import BaseDialog from '../../components/base/BaseDialog.vue';
import { cloneDeep as _cloneDeep, get as _get, last as _last } from 'lodash';
import { ApiState } from '../../store/types/general.types';
import { capitalizeFirstLetter } from '@/jbi-shared/util/user.utils';

@Component({
  components: {
    DefaultLayout,
    HeroBar,
    BasePagination,
  },
})
export default class ProjectDraftListPage extends Vue {
  @Prop() public participantProjectOnly!: boolean;
  @Prop() public userEmail!: string;
  @Prop() public isLoading!: boolean;
  public draftSearchQuery: string = '';
  public totalCount: number = 0;
  public perPage = 5;
  public page = 1;
  public draftProjectDetails: any = [];
  public isEmptyDraft: boolean = false;
  public dirtyLoading: boolean = false;

  @Action('projects/getDraftProjects')
  public getDraftProjects!: (params: GetProjectListPayload) => void;

  @State((state) => state.projects.draftListProject)
  public draftListProject!: ProjectListPagePayload[];

  @Action('projects/deleteProjectById')
  public deleteProjectById!: (projectId: number) => void;

  @State((state) => state.projects.apiState.getProjectDetail)
  public getProjectDetailState!: ApiState;

  get totalNumberOfPage(): number {
    return Math.ceil(this.totalCount / this.perPage);
  }

  get startItemIndex(): number {
    return this.page * this.perPage - this.perPage + 1;
  }

  get endItemIndex(): number {
    return Math.min(this.totalCount, this.page * this.perPage);
  }

  get isFirstPage(): boolean {
    return this.page === 1;
  }

  get isLastPage(): boolean {
    return this.page === this.totalNumberOfPage;
  }

  get getCurrentPage(): number {
    this.page = +this.$route.query.page || this.page;
    return this.page;
  }

  set getCurrentPage(newValue: number) {
    this.page = newValue;
    this.$router
      .push({
        path: this.$route.path,
        query: {
          tab: 'Draft',
          search: (this.$route.query.search as string) || this.draftSearchQuery,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: this.page.toString(),
        },
      })
      .catch(() => {
        return;
      });
    this.getDraftProjects({
      search: this.draftSearchQuery,
      limit: this.perPage,
      page: this.page,
      participantProjectOnly: this.participantProjectOnly,
    });
  }

  get getCurrentPerPage(): number {
    this.perPage = +this.$route.query.limit || this.perPage;
    return this.perPage;
  }

  set getCurrentPerPage(newValue: number) {
    this.perPage = newValue;
    this.$router
      .push({
        path: this.$route.path,
        query: {
          search: (this.$route.query.search as string) || this.draftSearchQuery,
          limit: this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
        },
      })
      .catch(() => {
        return;
      });
    this.getDraftProjects({
      search: this.draftSearchQuery,
      limit: this.perPage,
      page: this.page,
      participantProjectOnly: this.participantProjectOnly,
    });
  }

  public mounted() {
    this.dirtyLoading = this.isLoading;
    this.draftSearchQuery = (this.$route.query.search as string) || '';
    if (this.draftSearchQuery) {
      this.$router
        .push({
          path: this.$route.path,
          query: {
            tab: 'Created',
            search:
              (this.$route.query.search as string) || this.draftSearchQuery,
            limit:
              (this.$route.query.limit as string) || this.perPage.toString(),
            page: (this.$route.query.page as string) || this.page.toString(),
          },
        })
        .catch(() => {
          return;
        });
    }
    this.getDraftProjects({
      search: (this.$route.query.search as string) || this.draftSearchQuery,
      limit: Number(this.$route.query.limit) || this.perPage,
      page: Number(this.$route.query.page) || this.page,
      participantProjectOnly: this.participantProjectOnly,
    });
  }

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  public getAdministratorName(item: any) {
    const givenName = capitalizeFirstLetter(item.user.oicPayload.given_name);
    const familyName = capitalizeFirstLetter(item.user.oicPayload.family_name);
    item.administratorName = `${givenName} ${familyName} (${item.user.email})`;
    item.given_name = givenName;
    item.family_name = familyName;
    return item;
  }

  public checkProjectAdmin(item: any) {
    const userEmail = this.userEmail;
    if (item.user.oicPayload.email === userEmail) {
      return true;
    } else {
      return false;
    }
  }

  public getProjectUrl(item: any) {
    const projectUrl = '/projects/' + item.id + '#dashboard';
    if (item.user.email === this.userEmail) {
      return projectUrl;
    }
  }

  @isTruthy
  @Watch('draftListProject')
  public getWatcherActiveListProject(newValue: any) {
    this.dirtyLoading = false;
    this.$emit('updateLoadingBool', this.dirtyLoading);
    this.draftProjectDetails = [];
    if (newValue.items.length > 0) {
      newValue.items.map((dirtyProjectItem: ProjectListPagePayload) => {
        const newProjectItem = this.getAdministratorName(dirtyProjectItem);
        const isUserAdmin = this.checkProjectAdmin(dirtyProjectItem);
        if (isUserAdmin) {
          this.draftProjectDetails.push(newProjectItem);
        }
      });
      this.isEmptyDraft = false;
    } else {
      this.isEmptyDraft = true;
    }
    this.totalCount = newValue.totalItems;
  }

  @Watch('draftSearchQuery')
  public watchCreatedSearchQuery(newValue: string) {
    this.page = 1;
    this.$router
      .push({
        path: this.$route.path,
        query: {
          tab: 'Draft',
          search: newValue,
          limit: this.perPage.toString(),
          page: this.page.toString(),
        },
      })
      .catch(() => {
        return;
      });
    this.getDraftProjects({
      search: newValue,
      limit: this.perPage,
      page: this.page,
      participantProjectOnly: this.participantProjectOnly,
    });
  }

  @Watch('$route.query.tab')
  public routeParam() {
    if (this.$route.query.tab === 'Draft') {
      this.$router
        .push({
          path: this.$route.path,
          query: {
            tab: 'Created',
            search: this.draftSearchQuery,
            limit: this.perPage.toString(),
            page: this.page.toString(),
          },
        })
        .catch(() => {
          return;
        });
      const params = {
        search: this.draftSearchQuery,
        limit: this.perPage,
        page: this.page,
        participantProjectOnly: this.participantProjectOnly,
      };

      return this.getDraftProjects(params);
    }
  }

  public handleDelete(project: ProjectListPagePayload) {
    this.$buefy.modal.open({
      parent: this,
      component: BaseDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title: `Delete Project Setup`,
        content: `Are you sure you want to delete the project setup "${project.title}"?`,
        confirmButtonText: `Delete`,
      },
      events: {
        confirm: async () => {
          this.deleteProjectById(project.id);
        },
      },
    });
  }

  @Watch('getProjectDetailState.success')
  @isTruthy
  public watchGetProjectDetailSuccess(): void {
    this.draftProjectDetails = [];
    this.getDraftProjects({
      search: this.draftSearchQuery,
      limit: this.perPage,
      page: this.page,
      participantProjectOnly: this.participantProjectOnly,
    });
  }

  @Watch('getProjectDetailState.error')
  @isTruthy
  public watchGetProjectDetailDelete(error: AxiosError): void {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error Deleting Project. Error: ${_get(
        error.response,
        'data.message',
      )}`,
    });
  }
}
