


















































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { RootState } from '@/store/store';
import {
  ChangeProjectOwnerPayload,
  GetExistingProjectOwnerRequestPayload,
  ProjectParticipantDTO,
  ParticipantWithSubscriptionDTO,
  OwnerActionEnum,
  ParticipantRoles,
  ProjectSite,
  Site,
  ChangeProjectOwnerDTO,
} from '@/store/modules/projects/types/projects.types';
import BaseDropDown from '@/components/base/BaseDropDown.vue';
import dayjs from 'dayjs';
import { useAuthState } from '@/utils/store.util';
import { GeneralRoles } from '@/jbi-shared/types/casbin-permission.types';
import ProjectOwnerCancelRequestModal from './ProjectOwnerCancelRequest.vue';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  CheckSubscriptionStatusByEmailIdsPayload,
  OicPayload,
  UserSubscriptionStatusByEmailPayload,
} from '@/store/modules/auth/types/auth.types';
import { ApiState } from '@/store/types/general.types';
import { UserSubscriptionStatus } from '@/jbi-shared/types/jaas-oauth2.types';
import { useOicPayload, useUserEmail } from '@/utils/user.util';
import { getJBIUsername } from '@/jbi-shared/util/user.utils';

@Component({
  components: {
    BaseDropDown,
  },
})
export default class ProjectOwnerRequestForm extends Vue {
  @Prop() public projectId!: number;
  @Prop() public projectOwnerEmail!: string;
  @Prop() public projectOwnerName!: string;
  @Prop() public isSystemAdmin!: boolean;
  @Prop({ default: false, type: Boolean })
  public isReclaimProjectOwnership!: boolean;

  public disableSendRequest: boolean = this.isReclaimProjectOwnership
    ? false
    : true;
  public showModalProjectOwner: boolean = false;
  public disableProjectOwnerOption: boolean = false;
  public selectedRoleForCurrentProjectOwner: ParticipantRoles | null = null;
  public selectedSitesForCurrentProjectOwner: Site[] = [];
  public checkedAllSites: boolean = false;
  public participantsWithSubscriptionInfo: ParticipantWithSubscriptionDTO[] = [];
  public newRequestedProjectOwner: {
    userEmail: string;
    participantName: string;
  } | null = null;
  public searchTerm: string = '';
  public statusFilter: string = '';
  public remainingDays: number = 0;
  public projectOwnerRequestId: number = 0;
  public emailToken: string = '';
  public participantSubscriptionStatus = {
    all: 'all',
    valid: 'valid',
    invalid: 'invalid',
  };
  public confirmSendRequest: ChangeProjectOwnerDTO | null = null;
  public currentOwnerAction: OwnerActionEnum = OwnerActionEnum.none;

  @Action('projects/changeProjectOwnerRequest')
  public changeProjectOwnerRequest!: (
    payload: ChangeProjectOwnerPayload,
  ) => void;

  @State(
    (state: RootState) => state.projects.apiState.changeProjectOwnerRequest,
  )
  public changeProjectOwnerRequestState!: ApiState;

  @Action('projects/getExistingProjectOwnerRequest')
  public getExistingProjectOwnerRequest!: (
    payload: GetExistingProjectOwnerRequestPayload,
  ) => void;

  @State((state: RootState) => state.projects.existingProjectOwnerRequest)
  public existingProjectOwnerRequestState!: ApiState;

  @Action('auth/checkSubscriptionStatusByEmailIds')
  public checkSubscriptionStatusByEmailIds!: (
    payload: CheckSubscriptionStatusByEmailIdsPayload,
  ) => void;

  @State((state: RootState) => state.auth.userSubscriptionStatusByEmail)
  public userSubscriptionStatusByEmail!: UserSubscriptionStatusByEmailPayload[];

  @Action('projects/getProjectParticipantsForAdmin')
  public getProjectParticipantsForAdmin!: (projectId: number) => void;

  // prettier-ignore
  @State((state: RootState) => state.projects.apiState.getProjectParticipantsForAdmin)
  public getProjectParticipantsState!: ApiState;

  @State((state: RootState) => state.projects.projectParticipantsForAdmin)
  public projectParticipants!: ProjectParticipantDTO[];

  @Action('projects/getProjectSitesForAdmin')
  public getProjectSitesForAdmin!: (projectId: number) => void;

  // prettier-ignore
  @State((state: RootState) => state.projects.apiState.getProjectSitesForAdmin)
  public getProjectSitesState!: ApiState;

  @State((state: RootState) => state.projects.projectSitesForAdmin)
  public projectSites!: ProjectSite[];

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  get OwnerActionEnum() {
    return OwnerActionEnum;
  }

  get participants(): ParticipantWithSubscriptionDTO[] {
    let filteredParticipant = this.participantsWithSubscriptionInfo;
    if (this.searchTerm) {
      filteredParticipant = filteredParticipant.filter(
        (participant: ParticipantWithSubscriptionDTO) => {
          const participantName = participant.participantName.toLowerCase();
          return participantName.includes(this.searchTerm.toLowerCase());
        },
      );
    }

    if (this.statusFilter !== 'all') {
      filteredParticipant = filteredParticipant.filter(
        (participant: ParticipantWithSubscriptionDTO) =>
          participant.subscriptionStatus ===
          (this.statusFilter as UserSubscriptionStatus),
      );
    }

    return filteredParticipant;
  }

  get projectOwnerRoleOptions() {
    return [
      {
        label: ParticipantRoles.projectCoOrdinator,
        value: ParticipantRoles.projectCoOrdinator,
      },
      {
        label: ParticipantRoles.dataCollector,
        value: ParticipantRoles.dataCollector,
      },
    ];
  }

  get sites(): Site[] {
    return this.projectSites
      ? this.projectSites.map((projectSite) => projectSite.site)
      : [];
  }

  get isAdmin(): boolean {
    const permissions = useAuthState.call(this).roles;
    return permissions.some((e) => e === GeneralRoles.Admin);
  }

  get selectedSiteNames(): string {
    this.checkedAllSites =
      this.selectedSitesForCurrentProjectOwner.length === this.sites.length;

    return this.checkedAllSites
      ? 'All Sites'
      : this.selectedSitesForCurrentProjectOwner
          .map((site) => site.name)
          .join(', ');
  }

  get disabledButton(): boolean {
    return (
      !this.validatedFromData() ||
      this.disableProjectOwnerOption ||
      this.disableSendRequest
    );
  }

  get buttonText(): string {
    if (this.isAdminNewProjectOwner && !this.isReclaimProjectOwnership) {
      return 'Save';
    }
    return 'Send Request';
  }

  get isAdminNewProjectOwner(): boolean {
    return this.isSystemAdmin &&
      this.newRequestedProjectOwner &&
      this.newRequestedProjectOwner.userEmail === this.userEmail
      ? true
      : false;
  }

  public validatedFromData(): boolean {
    if (!this.newRequestedProjectOwner) {
      return false;
    } else if (
      this.newRequestedProjectOwner.userEmail === this.projectOwnerEmail
    ) {
      return false;
    }
    if (
      this.currentOwnerAction === OwnerActionEnum.removeFromProject &&
      this.newRequestedProjectOwner
    ) {
      return true;
    } else if (
      this.currentOwnerAction === OwnerActionEnum.appointNewRole &&
      this.newRequestedProjectOwner &&
      this.selectedRoleForCurrentProjectOwner &&
      this.selectedSitesForCurrentProjectOwner.length > 0
    ) {
      return true;
    }

    return false;
  }

  public getParticipantRoles(
    participant: ParticipantWithSubscriptionDTO,
  ): string {
    return participant.participantRoles
      .map((participantRole) => participantRole.role)
      .join(', ');
  }

  public handleCheckAllSites(value: boolean) {
    this.checkedAllSites = value;
    this.selectedSitesForCurrentProjectOwner = [];
    if (value) {
      this.selectedSitesForCurrentProjectOwner = [...this.sites];
    }
  }

  public onCurrentOwnerAction(value: OwnerActionEnum) {
    this.currentOwnerAction = value;
  }

  @Watch('userSubscriptionStatusByEmail')
  @isTruthy
  public async userSubscriptionStatus(
    subscriptionDetails: UserSubscriptionStatusByEmailPayload[],
  ) {
    if (this.projectParticipants && !this.isReclaimProjectOwnership) {
      const participantsSubscriptionDto: ParticipantWithSubscriptionDTO[] = [];
      this.projectParticipants.forEach((participant: ProjectParticipantDTO) => {
        if (this.projectOwnerEmail === participant.userEmail) {
          return;
        }
        const userSubscriptionStatus = subscriptionDetails.find(
          (subscriptionDetail: UserSubscriptionStatusByEmailPayload) =>
            subscriptionDetail.user === participant.userEmail,
        );

        const participantNameEmail = participant.user
          ? getJBIUsername(participant.userEmail, participant.user.oicPayload)
          : participant.userEmail;

        const isValidProjectParticipant: boolean = participant.participantRoles.every(
          (role) =>
            role.participantSites.every((site) => site.invite.acceptedAt),
        );

        const participantSubscriptionDto: ParticipantWithSubscriptionDTO = {
          ...participant,
          participantName: participantNameEmail,
          subscriptionStatus: userSubscriptionStatus
            ? (userSubscriptionStatus.subscriptionStatus as UserSubscriptionStatus)
            : UserSubscriptionStatus.INVALID,
          isValidProjectParticipant,
        };

        participantsSubscriptionDto.push(participantSubscriptionDto);
      });

      /** if one of the participant have valid subscription and have accepted all site invites for all roles */
      this.disableSendRequest = !participantsSubscriptionDto.some(
        (participantInfo) =>
          participantInfo.subscriptionStatus === UserSubscriptionStatus.VALID &&
          participantInfo.participantRoles.every((role) =>
            role.participantSites.every((site) => site.invite.acceptedAt),
          ),
      );
      this.participantsWithSubscriptionInfo = participantsSubscriptionDto;
    }
  }

  public sendRequest() {
    if (this.newRequestedProjectOwner && this.validatedFromData()) {
      const systemAdminEmail =
        this.isSystemAdmin && this.userEmail ? this.userEmail : null;

      const projectOwnerRequest: ChangeProjectOwnerPayload = {
        newRequestedOwnerEmail: this.newRequestedProjectOwner.userEmail,
        projectId: this.projectId,
        currentOwnerEmail: this.projectOwnerEmail,
        currentOwnerAction: this.currentOwnerAction,
        assignedRoleForCurrentOwner: this.selectedRoleForCurrentProjectOwner,
        assignedSitesForCurrentOwner: this.selectedSitesForCurrentProjectOwner.map(
          (site) => site.id,
        ),
        systemAdminEmail,
        isSystemAdmin: this.isSystemAdmin,
        isReclaimProject: this.isReclaimProjectOwnership,
      };

      this.confirmSendRequest = {
        isAdminNewProjectOwner: this.isAdminNewProjectOwner,
        currentProjectOwnerEmail: this.projectOwnerEmail,
        newRequestedProjectOwnerEmail: this.newRequestedProjectOwner.userEmail,
      };

      this.changeProjectOwnerRequest(projectOwnerRequest);
      this.$emit('close');
    }
  }

  public cancelRequest() {
    const modalContent =
      'After your last request is cancelled, the recipient of this request will no longer be able to respond to it.';
    this.$buefy.modal.open({
      parent: this,
      component: ProjectOwnerCancelRequestModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalContent,
        projectOwnerRequestId: this.projectOwnerRequestId,
        projectId: this.projectId,
        token: this.emailToken,
        isReclaimProject: false,
        isSystemAdmin: this.isSystemAdmin,
      },
      events: {
        close: () => {
          this.$emit('close');
        },
        confirm: () => {
          Toast.open({
            message: `Project administration change request cancelled`,
            position: 'is-top',
            type: 'is-dark',
            duration: 3500,
          });
          this.getExistingProjectOwnerRequest({
            projectId: this.projectId,
            isSystemAdmin: this.isSystemAdmin,
          });
        },
      },
    });
  }

  @Watch('getProjectParticipantsState')
  @isTruthy
  public onGetProjectParticipantsState() {
    if (this.projectParticipants) {
      this.checkSubscriptionStatusByEmailIds({
        email_list: this.projectParticipants.map(
          (participant) => participant.userEmail,
        ),
      });
    }
  }

  @Watch('existingProjectOwnerRequestState')
  @isTruthy
  public watchExistingProjectOwnerRequestState(newValue: any) {
    this.disableProjectOwnerOption = newValue.isNewProjectOwnerRequest
      ? false
      : true;

    if (newValue.expireDate) {
      this.remainingDays = dayjs(newValue.expireDate).diff(new Date(), 'day');
      this.projectOwnerRequestId = newValue.projectOwnerRequestId;
      this.emailToken = newValue.token;
    }
  }

  @Watch('changeProjectOwnerRequestState')
  @isTruthy
  public watchChangeProjectOwnerRequestState() {
    this.$emit('confirm', this.confirmSendRequest);
    this.$emit('close');
  }

  public mounted(): void {
    this.showModalProjectOwner = true;
    this.getProjectSitesForAdmin(this.projectId);

    if (this.isReclaimProjectOwnership && this.userEmail) {
      this.newRequestedProjectOwner = {
        userEmail: this.userEmail,
        participantName: 'You',
      };
    } else {
      this.getProjectParticipantsForAdmin(this.projectId);
      this.getExistingProjectOwnerRequest({
        projectId: this.projectId,
        isSystemAdmin: this.isSystemAdmin,
      });
    }
    this.statusFilter = 'all';
  }
}
