








































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import Card from '@/components/Card.vue';
import SingleSiteTable from '@/components/reports/SingleSiteTable.vue';
import VerticalBarChart from '@/components/reports/VerticalBarChart';
import HorizontalBarChart from '@/components/reports/HorizontalBarChart';
import {
  AuditSite,
  AuditCriteria,
  AuditReportFiltersConfig,
  AuditReportSinglePeriodResponse,
  ChartType,
  ChartRecords,
} from '@/store/modules/audits/types/audits.types';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import {
  chartColorSet,
  primaryChartColor,
  labelSplitIndex,
} from '@/store/types/general.types';
import CriteriaViewUpdateComponent from '../../Project/components/CriteriaViewUpdateComponent.vue';

@Component({
  components: {
    Card,
    SingleSiteTable,
    VerticalBarChart,
    HorizontalBarChart,
    CriteriaViewUpdateComponent,
  },
})
export default class SingleSiteReport extends Vue {
  @Prop()
  public analysisFilter!: AuditReportFiltersConfig;
  @Prop()
  public auditSitelist!: AuditSite[];
  @Prop()
  public auditCriteriaList!: AuditCriteria[];
  @Prop()
  public reportResponse!: AuditReportSinglePeriodResponse;

  public dataSet: any[] = [];
  public dataSetOptions: any[] = [];
  public loadingSet: boolean[] = [];
  public criteriaList: Array<AuditCriteria['criteria']> = [];
  public verticalBarChartDataSetOption: any = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Compliance %',
            display: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Criteria',
            display: false,
          },
          ticks: {
            minRotation: 0,
            callback: (label: string, index: number) => {
              return this.generateLabel(label, index);
            },
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          if (tooltipItem.yLabel < 0) {
            return data.datasets[tooltipItem.datasetIndex].label + ': NA';
          }
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ': ' +
            tooltipItem.yLabel
          );
        },
        labelColor: (tooltipItem: any, chart: any) => {
          return {
            backgroundColor: primaryChartColor,
          };
        },
      },
    },
  };
  public horizontalBarChartDataSetOption: any = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Compliance %',
            display: true,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Criteria',
            display: false,
          },
          ticks: {
            minRotation: 0,
            callback: (label: string, index: number) => {
              return this.generateLabel(label, index);
            },
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          if (tooltipItem.xLabel < 0) {
            return data.datasets[tooltipItem.datasetIndex].label + ': NA';
          }
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ': ' +
            tooltipItem.xLabel
          );
        },
        labelColor: (tooltipItem: any, chart: any) => {
          return {
            backgroundColor: primaryChartColor,
          };
        },
      },
    },
  };
  public individualBooleanCriteriaConfiguration: any[] = [];
  public individualMixedCriteriaConfiguration: any[] = [];
  public aggregateCriteriaConfiguration: any[] = [];
  public renderOverallStatisticTable: boolean = true;

  public mounted() {
    this.populateData();
  }

  public populateData() {
    if (!this.reportResponse.success) {
      this.dataSet = [];
      this.dataSetOptions = [];
      this.loadingSet = [];
      this.individualBooleanCriteriaConfiguration = [];
      this.individualMixedCriteriaConfiguration = [];
    } else {
      this.dataSet = [];
      this.dataSetOptions = [];
      this.loadingSet = [];
      this.individualBooleanCriteriaConfiguration = [];
      this.individualMixedCriteriaConfiguration = [];
      const chartData: ChartRecords[] = this.reportResponse.chartData;
      this.generateConfigurationData(
        this.reportResponse.statisticData.configurationData,
        this.reportResponse.statisticData.samplingConfiguration,
      );
      if (chartData.length > 0) {
        this.singleSiteRender(chartData);
      }
    }
  }

  public singleSiteRender(chartData: ChartRecords[]) {
    const siteName = chartData[0].data[0].site.name;
    if (this.analysisFilter.checkIndividualCriteria) {
      this.renderIndividualCriteria(chartData, siteName);
    } else {
      this.renderAggregateCriteria(chartData, siteName);
    }
  }

  public renderIndividualCriteria(chartData: ChartRecords[], siteName: string) {
    Object.keys(chartData).forEach((key) => {
      const data = {
        labels: [] as string[],
        datasets: [] as any[],
      };
      let dataOptions = {};
      const dirtyChartData = chartData[Number(key)];
      const label = dirtyChartData.data[0].site.name;
      const chartValue = dirtyChartData.data[0].compliance;
      const dataSetObject = {
        label: dirtyChartData.criteria.title,
        backgroundColor: [primaryChartColor],
        hoverBackgroundColor: primaryChartColor,
        pointBackgroundColor: 'white',
        borderWidth: 1,
        pointBorderColor: '#249EBF',
        data: [chartValue],
        categoryPercentage: this.getIndividualCriteriaChartCategoryPercentage,
      };
      if (
        this.analysisFilter.selectedChartType === ChartType.verticalBarChart
      ) {
        dataOptions = _cloneDeep(this.verticalBarChartDataSetOption);
      } else {
        dataOptions = _cloneDeep(this.horizontalBarChartDataSetOption);
      }
      data.labels.push(label);
      data.datasets.push(dataSetObject);
      this.dataSet.push(data);
      this.dataSetOptions.push(dataOptions);
      this.loadingSet.push(true);
      this.criteriaList.push(dirtyChartData.criteria);
    });
  }

  public renderAggregateCriteria(chartData: ChartRecords[], siteName: string) {
    const data = {
      labels: [] as string[],
      datasets: [] as any[],
    };
    let dataOptions = {};
    const labels: string[] = [];
    const chartValues: number[] = [];
    const backgroundColors: string[] = [];
    Object.keys(chartData).map((key: string) => {
      labels.push(chartData[Number(key)].criteria.title);
      chartValues.push(chartData[Number(key)].data[0].compliance);
      backgroundColors.push(primaryChartColor);
    });
    const dataSetObject = {
      label: siteName,
      backgroundColor: backgroundColors,
      hoverBackgroundColor: primaryChartColor,
      pointBackgroundColor: 'white',
      borderWidth: 1,
      pointBorderColor: '#249EBF',
      data: chartValues,
      categoryPercentage: this.getAggregateCriteriaChartCategoryPercentage,
    };
    let dataOptionsObject;
    if (this.analysisFilter.selectedChartType === ChartType.verticalBarChart) {
      dataOptionsObject = _cloneDeep(this.verticalBarChartDataSetOption);
    } else {
      dataOptionsObject = _cloneDeep(this.horizontalBarChartDataSetOption);
    }
    data.labels = _cloneDeep(labels);
    data.datasets = [];
    data.datasets.push(dataSetObject);
    dataOptions = _cloneDeep(dataOptionsObject);
    this.dataSet.push(data);
    this.dataSetOptions.push(dataOptions);
    this.loadingSet.push(true);
  }

  public generateConfigurationData(
    configurationData: any[],
    samplingConfiguration: any,
  ) {
    if (Array.isArray(configurationData) && configurationData.length > 0) {
      const aggregateConfig: any = configurationData.map((data: any) => {
        const criteriaOptionsDataDistribution =
          data.criteriaSamplingData.criteriaOptionsDataDistribution;
        const criteriaSamplingData = Object.keys(
          criteriaOptionsDataDistribution,
        ).map((key: string) => ({
          [key]: data.criteriaSamplingData.criteriaOptionsDataDistribution[key],
        }));
        return {
          id: data.criteria.id,
          title: _get(data, 'criteria.title', ''),
          criteriaType: _get(data, 'criteria.criteriaType', ''),
          sampleSize: Number(
            _get(data, 'criteriaSamplingDataConfigurations.sampleSize', 0),
          ),
          criteriaOptions: JSON.parse(data.criteria.criteriaOptions),
          siteSamplingConfiguration: data.siteSamplingDataConfigurations,
          criteriaSamplingDataConfiguration:
            data.criteriaSamplingDataConfigurations,
          samplingConfiguration,
          criteriaSamplingData,
        };
      });
      this.aggregateCriteriaConfiguration = _cloneDeep(aggregateConfig);
    } else {
      this.aggregateCriteriaConfiguration = [];
    }
    this.generateIndividualCriteriaSet(this.aggregateCriteriaConfiguration);
  }

  public generateIndividualCriteriaSet(aggregateCriteriaConfiguration: any[]) {
    this.individualBooleanCriteriaConfiguration = aggregateCriteriaConfiguration.filter(
      (item) => {
        return item.criteriaType === CRITERION_TYPE.BOOLEAN;
      },
    );
    this.individualMixedCriteriaConfiguration = aggregateCriteriaConfiguration.filter(
      (item) => {
        return item.criteriaType !== CRITERION_TYPE.BOOLEAN;
      },
    );
    if (
      this.individualBooleanCriteriaConfiguration.length ===
        this.aggregateCriteriaConfiguration.length &&
      this.analysisFilter.checkIndividualCriteria
    ) {
      this.renderOverallStatisticTable = false;
    }
  }

  public getMixedBooleanTitleIndex(index: number) {
    return this.individualBooleanCriteriaConfiguration.length + index + 1;
  }

  public generateLabel = (label: string, index: number): string | string[] => {
    if (this.analysisFilter.checkIndividualCriteria) {
      return this.transformLabel(label);
    } else {
      if (this.analysisFilter.filteredCriterias.length > 8) {
        return index + 1 + '.';
      }
      return this.transformLabel(label);
    }
  };

  public transformLabel(label: string): string | string[] {
    const words = label.split(' ');
    let endIndex: number = labelSplitIndex;
    let eachLine: string = '';
    const eachLabel: string[] = [];
    _map(words, (word: string, wordIndex: string) => {
      switch (Number(wordIndex)) {
        case 0:
          eachLine = word;
          break;
        case endIndex:
          eachLabel.push(eachLine);
          eachLine = word;
          endIndex += labelSplitIndex;
          break;
        case words.length - 1:
          eachLine += ' ' + word;
          eachLabel.push(eachLine);
          break;
        default:
          eachLine += ' ' + word;
      }
    });
    if (eachLabel.length >= 3) {
      eachLabel[2] = eachLabel[2] + '...';
      return eachLabel.slice(0, 3);
    }
    return label;
  }

  get getAggregateCriteriaChartCategoryPercentage() {
    const noOfBooleanCriterias: number = this
      .individualBooleanCriteriaConfiguration.length;
    return noOfBooleanCriterias <= 10 ? noOfBooleanCriterias * 0.1 : 0.8;
  }

  get getIndividualCriteriaChartCategoryPercentage() {
    const noOfBooleanCriterias: number = this
      .individualBooleanCriteriaConfiguration.length;
    if (this.analysisFilter.selectedChartType === ChartType.verticalBarChart) {
      return 0.1;
    } else {
      return 0.2;
    }
  }

  get getSelectedCriteriaList(): AuditCriteria[] {
    const selectedCriteriaList: any[] = [];
    this.analysisFilter.filteredCriterias.forEach(
      (auditCriteriaMapId: number) => {
        const auditCriteria = this.auditCriteriaList.find((criteria) => {
          return (
            criteria.id === auditCriteriaMapId &&
            criteria.criteria.criteriaType === CRITERION_TYPE.BOOLEAN
          );
        });
        if (auditCriteria) {
          selectedCriteriaList.push(auditCriteria);
        }
      },
    );
    return selectedCriteriaList;
  }
}
