



















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  AuditCriteria,
  AuditDetail,
  AuditSamplingRequestPayload,
  AuditSamplingResponse,
  AuditSite,
  DataCollectionSubmitRequestPayload,
} from '@/store/modules/audits/types/audits.types';
import { Action, State } from 'vuex-class';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import DataCollectionAggregate from '@/views/Audit/components/DataCollectionAggregate.vue';
import DataCollectionIndividual from '@/views/Audit/components/DataCollectionIndividual.vue';
import { orderByUtil } from '@/jbi-shared/util/sort.util';
import { SortOrder } from '@/jbi-shared/types/search.types';

const dirtyMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    DataCollectionAggregate,
    DataCollectionIndividual,
  },
})
export default class DataCollectionModal extends Vue {
  @Prop()
  public auditDetail!: AuditDetail;
  @Prop()
  public auditId!: number;
  @Prop()
  public singleAuditCriteriaId!: number;
  @Prop()
  public editDataCollectionRecord!: any;
  @Prop()
  public dataCollectionSummary!: any;

  public dataCollectionType: string = 'aggregate';
  public newEntryTitle: boolean = true;
  public renderComponent: boolean = true;
  public breadcrumbTitle: string = 'Submit New Record';
  public defaultSiteId: number | null = null;
  public isMultipleSubmission: boolean = false;

  @Action('audits/getAuditCriteria')
  public getAuditCriteria!: (auditId: number) => void;

  @State((state) => state.audits.auditCriteria)
  public auditCriteria!: AuditCriteria[];

  @Action('audits/getAuditSites')
  public getAuditSites!: (payload: number) => void;

  @State((state) => state.audits.auditSites)
  public auditSites!: AuditSite[];

  @Action('audits/postDataCollection')
  public fetchDataCollection!: (
    payload: DataCollectionSubmitRequestPayload,
  ) => void;

  @State((state) => state.audits.dataCollectionResponse)
  public dataCollectionResponse!: any;

  @Action('audits/getSamplingConfig')
  public getSamplingConfig!: (payload: AuditSamplingRequestPayload) => void;

  @State((state) => state.audits.auditConfig)
  public auditConfig!: AuditSamplingResponse;

  @Watch('auditSites')
  @isTruthy
  public watchAuditSites(newValue: AuditSite[]) {
    this.defaultSiteId = newValue[0].id;
  }

  @Watch('dataCollectionResponse')
  @isTruthy
  public watchDataCollectionResponse(newValue: any) {
    Toast.open({
      message: `Data Successfully Submitted`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
    if (!this.isMultipleSubmission) {
      this.$emit('closeDataCollectionTemplate');
      this.$router.push({
        name: 'audit',
        params: {
          auditId: this.auditId.toString(),
        },
        hash: '#dataCollection',
      });
    }
  }

  public mounted() {
    this.getAuditCriteria(this.auditId);
    this.getAuditSites(this.auditId);
    this.getSamplingConfig({
      auditId: this.auditId,
    });
    this.populateData();
  }

  public getFormattedTitle(title: string) {
    if (title.length > 45) {
      return title.substring(0, 45) + '...';
    } else {
      return title;
    }
  }

  public populateData() {
    if (
      this.editDataCollectionRecord &&
      this.editDataCollectionRecord.dataCollectionRecords
    ) {
      this.newEntryTitle = false;
      this.dataCollectionType = this.editDataCollectionRecord.dataCollectionType;
      this.breadcrumbTitle = 'Edit Record';
    }
  }

  public handleEnteredDataRecords(
    newValue: DataCollectionSubmitRequestPayload,
  ) {
    if (newValue.aid === '') {
      newValue.aid = null;
    }
    this.fetchDataCollection(newValue);
  }

  public switchMode(newValue: string) {
    this.renderComponent = false;
    this.dataCollectionType = newValue;
    this.$nextTick(() => {
      this.renderComponent = true;
    });
  }

  get siteList(): any[] {
    const siteArray: any[] = [];
    this.auditSites.forEach((auditSite) => {
      siteArray.push({
        siteId: auditSite.site.id,
        name: auditSite.site.name,
        auditSiteId: auditSite.id,
      });
    });

    return siteArray;
  }

  get auditCriteriaList(): AuditCriteria[] {
    return this.auditCriteria.sort(orderByUtil(['order'], [SortOrder.ASC]));
  }

  public handleSiteDropdownItem(newValue: number) {
    this.defaultSiteId = newValue;
  }
}
