













































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import AuditList from '@/views/Project/components/AuditList.vue';
import ParticipantList from '@/views/Project/components/ParticipantList.vue';
import InvitationList from '@/views/Project/components/InvitationList.vue';
import ActivityLogList from '@/views/Project/components/ActivityLogList.vue';
import NewAuditModal from '@/views/Project/components/NewAuditModal.vue';
import {
  ProjectDetailPayload,
  ProjectDataCollectionRecordExportPayload,
} from '@/store/modules/projects/types/projects.types';
import { sortBy as _sortBy, filter as _filter } from 'lodash';
import moment from 'moment';
import { RootState } from '@/store/store';
import ProjectDashboardChart from '@/views/Project/components/ProjectDashboardChart.vue';
import { AuditStatus } from '@/store/modules/audits/types/audits.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import ProjectReportExportDropdown from '@/views/Project/components/ProjectReportExportDropdown.vue';
import { reportExportType } from '@/store/types/general.types';
import { ProjectExportDataCsv } from '@/components/projects/export/csv/ProjectExportDataCsv';
import { ProjectExportDataExcel } from '@/components/projects/export/excel/ProjectExportDataExcel';
import { ApiState } from '../../../store/types/general.types';
import {
  ProjectParticipantDTO,
  UpdatedJBICriteriaAcknowledgement,
} from '../../../store/modules/projects/types/projects.types';
import { GripNotificationResponse } from '../../../store/types/grip.types';
import GripNotificationModal from '../../Grip/components/GripNotificationModal.vue';

@Component({
  components: {
    ProjectDashboardChart,
    AuditList,
    ParticipantList,
    InvitationList,
    ActivityLogList,
    ProjectReportExportDropdown,
  },
})
export default class ProjectDashboardTab extends Vue {
  @Prop({ default: true })
  public editable!: boolean;

  @Prop({ default: false })
  public isProjectOwner!: boolean;

  @Prop({ default: false })
  public isUserProjectOwnerOrCoordinator!: boolean;

  @Prop()
  public currentUser!: ProjectParticipantDTO;

  @Action('projects/getProjectDetail')
  public getProjectDetail!: (projectId: number) => void;

  @Action('projects/getProjectDataCollectionRecordExport')
  public getProjectDataCollectionRecordExport!: (projectId: number) => void;

  @Action('projects/addNewAudit')
  public addNewAudit!: (params: any) => void;

  @Action('projects/getUpdatedJBICriteriaWithAcknowledgement')
  public getUpdatedJBICriteriaWithAcknowledgement!: (projectId: number) => void;

  @State((state: RootState) => state.projects.projectDetail)
  public projectDetail!: ProjectDetailPayload;

  @State((state: RootState) => state.projects.apiState.addNewAudit)
  public addNewAuditState!: ApiState;

  @State((state: RootState) => state.projects.projectDataCollectionRecordExport)
  public projectDataCollectionRecordExport!: ProjectDataCollectionRecordExportPayload;

  @State(
    (state: RootState) =>
      state.projects.apiState.getProjectDataCollectionRecordExport,
  )
  public getProjectDataCollectionRecordExportState!: ApiState;

  @State((state) => state.projects.updatedJBICriteriaListWithAcknowledgment)
  public updatedJBICriteriaListWithAcknowledgment!: UpdatedJBICriteriaAcknowledgement;

  @Action('projects/getGripNotifications')
  public getGripNotifications!: (projectId: number) => void;

  @State((state) => state.projects.gripNotifications)
  public gripNotifications!: GripNotificationResponse[];

  @State((state) => state.projects.apiState.getGripNotifications)
  public getGripNotificationsState!: ApiState;

  @Action('projects/getProjectParticipants')
  public getProjectParticipants!: (projectId: number) => void;

  @State((state: RootState) => state.projects.participants)
  public projectParticipants!: ProjectParticipantDTO[];

  get isProjectCriteriaAcknowledged(): boolean {
    return this.updatedJBICriteriaListWithAcknowledgment &&
      this.updatedJBICriteriaListWithAcknowledgment
        .projectCriteriaAcknowledgement
      ? this.updatedJBICriteriaListWithAcknowledgment
          .projectCriteriaAcknowledgement.isAcknowledged
      : true;
  }

  public isAuditDeleted: boolean = false;
  public auditListKey: number = Math.floor(Math.random() * 999);
  public isNewAuditCreated: boolean = false;
  public isProjecttDashboard: boolean = true;
  public isGripNotification: boolean = false;

  // projectHeaderExport, projectSubHeaderExport and projectDataCollectionRecordInfo is an array
  // with dynamic key attribute with represents the header, subheader and data record in the exported file
  public projectHeaderExport: any = [];
  public projectSubHeaderExport: any = [];
  public projectDataCollectionRecordInfo: any = [];
  public exportReprotType: string = '';

  get isAllowedToCreateDataCollectionPeriod(): boolean {
    return this.editable && this.projectDetail.isUserAuthorizedToAccessAllSites;
  }

  public openAddNewAuditModal() {
    let latestDate: Date = new Date();
    if (this.projectDetail.audits) {
      const filteredAudits: any = this.projectDetail.audits.filter(
        (item: any) => item.status === 'Active',
      );
      if (filteredAudits.length > 0) {
        latestDate = new Date(
          Math.max.apply(
            null,
            filteredAudits.map((item: any) => {
              if (item.endDate) {
                return new Date(item.endDate);
              }
              return new Date(item.startDate);
            }),
          ),
        );
        latestDate.setDate(latestDate.getDate() + 1);
      }
    }

    this.$buefy.modal.open({
      parent: this,
      component: NewAuditModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Add New Data Collection Period',
        auditCount: this.auditList.length,
        projectDetail: this.projectDetail,
        latestDate,
      },
      events: {
        addNewAudit: (newValue: any) => {
          newValue.newAuditNumber = this.auditList.length;
          newValue.projectId = this.projectDetail.id;
          newValue.isAuditDeleted = this.isAuditDeleted;
          this.addNewAudit(newValue);
        },
      },
    });
  }

  get auditList() {
    if (this.projectDetail) {
      const filteredAudit = _filter(
        this.projectDetail.audits,
        (auditDetail) => {
          if (auditDetail.status === AuditStatus.DELETED) {
            this.isAuditDeleted = true;
          }
          return auditDetail.status !== AuditStatus.DELETED;
        },
      );
      return _sortBy(filteredAudit, (auditDetail) => {
        if (moment(auditDetail.startDate).isAfter(new Date())) {
          auditDetail.isFutureAudit = true;
        } else {
          auditDetail.isFutureAudit = false;
        }
        return auditDetail.startDate ? new Date(auditDetail.startDate) : null;
      });
    }
    return [];
  }

  get activeAuditList() {
    if (this.projectDetail) {
      const filteredAudit = _filter(
        this.projectDetail.audits,
        (auditDetail) => {
          if (auditDetail.status === AuditStatus.DELETED) {
            this.isAuditDeleted = true;
          }
          return (
            auditDetail.status !== AuditStatus.DELETED &&
            auditDetail.status === AuditStatus.ACTIVE
          );
        },
      );
      return _sortBy(filteredAudit, (auditDetail) => {
        if (moment(auditDetail.startDate).isAfter(new Date())) {
          auditDetail.isFutureAudit = true;
        } else {
          auditDetail.isFutureAudit = false;
        }
        return auditDetail.startDate ? new Date(auditDetail.startDate) : null;
      });
    }
    return [];
  }

  get archivedAuditList() {
    const filteredAudit = _filter(this.projectDetail.audits, (auditDetail) => {
      if (auditDetail.status === AuditStatus.DELETED) {
        this.isAuditDeleted = true;
      }
      return (
        auditDetail.status !== AuditStatus.DELETED &&
        auditDetail.status === AuditStatus.ARCHIVED
      );
    });
    return _sortBy(filteredAudit, (auditDetail) => {
      if (moment(auditDetail.startDate).isAfter(new Date())) {
        auditDetail.isFutureAudit = true;
      } else {
        auditDetail.isFutureAudit = false;
      }
      return auditDetail.startDate ? new Date(auditDetail.startDate) : null;
    });
  }

  get participants(): ProjectParticipantDTO[] {
    return this.projectParticipants || [];
  }

  public changeActiveTab(newValue: string) {
    this.$emit('changeActiveTab', newValue);
  }

  get projectId() {
    return +this.$route.params.projectId;
  }

  public openGripNotificationsModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: GripNotificationModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        gripNotifications: this.gripNotifications,
        projectId: this.projectId,
        currentUser: this.currentUser,
      },
      events: {
        success: () => {
          this.isGripNotification = false;
        },
        error: () => {
          this.isGripNotification = true;
        },
      },
    });
  }

  public mounted() {
    this.getProjectParticipants(this.projectId);
    this.getUpdatedJBICriteriaWithAcknowledgement(this.projectId);
    this.getGripNotifications(this.projectId);
  }

  @Watch('getGripNotificationsState.success')
  @isTruthy
  public watchGetGripNotificationsSuccess(): void {
    this.isGripNotification =
      this.gripNotifications && this.gripNotifications.length > 0;
  }

  @Watch('addNewAuditState.success')
  @isTruthy
  public watchAddNewAuditStateSuccess() {
    this.auditListKey += 1;
    this.getProjectDetail(this.projectId);
    this.getGripNotifications(this.projectId);
  }

  @Watch('getProjectDataCollectionRecordExportState.success')
  @isTruthy
  public watchGetProjectDataCollectionRecordExportState(): void {
    switch (this.exportReprotType) {
      case reportExportType[2]:
        this.handleExportToCsv(this.projectDataCollectionRecordExport);
        break;
      case reportExportType[3]:
        this.handleExportToExcel(this.projectDataCollectionRecordExport);
        break;
      default:
        break;
    }
  }

  public handleExport(exportType: string): void {
    this.exportReprotType = exportType;
    this.getProjectDataCollectionRecordExport(this.projectId);
  }

  public handleExportToCsv(
    projectDataCollectionRecords: ProjectDataCollectionRecordExportPayload,
  ) {
    const projectExportDataCsv = new ProjectExportDataCsv();
    projectExportDataCsv.create(
      this.projectDetail.title,
      projectDataCollectionRecords,
    );
  }

  public handleExportToExcel(
    projectDataCollectionRecords: ProjectDataCollectionRecordExportPayload,
  ): void {
    const projectExportDataExcel = new ProjectExportDataExcel();
    projectExportDataExcel.create(
      this.projectDetail.title,
      projectDataCollectionRecords,
    );
  }
}
