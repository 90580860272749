
















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SubmitNewRecordModal extends Vue {
  @Prop(Boolean) public isParticipant!: boolean;
  public isSubmitAnotherRecord: boolean | null = null;

  public confirmContinue(): void {
    this.$emit('confirm', this.isSubmitAnotherRecord);
    this.$emit('close');
  }
  public cancel(): void {
    this.$emit('close');
  }
}
