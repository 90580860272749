






































































import { Site } from '@/store/modules/site/types/site.types';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CreateParticipantForm from './CreateParticipantForm.vue';
import Card from '@/components/Card.vue';
import {
  ParticipantFormData,
  ParticipantSite,
  ProjectParticipantDataTrfObj,
} from '@/store/modules/projects/types/projects.types';
import DeleteModalBox from '../../../../components/DeleteModalBox.vue';
import { PacesRoles } from '@/store/types/general.types';
import { cloneData } from '@/utils/general.util';

@Component({
  components: { Card },
})
export default class InviteParticipants extends Vue {
  @Prop()
  public sites!: Site[];

  @Prop({ default: [] })
  public value!: ProjectParticipantDataTrfObj[];

  // local copy of 'value' for mutation
  public projectParticipants: ProjectParticipantDataTrfObj[] = [];

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  get activeParticipants(): ProjectParticipantDataTrfObj[] {
    return this.projectParticipants.filter(({ isDeleted }) => !isDeleted);
  }

  get projectCoordinatorRole(): PacesRoles {
    return PacesRoles.ProjectCoordinator;
  }

  public getSiteCount(sites: Array<Site | ParticipantSite>): number {
    return sites.filter((siteOrParticipantSite: any) => {
      if (siteOrParticipantSite.site) {
        return siteOrParticipantSite.isDeleted === false;
      }
      return true;
    }).length;
  }

  public transformSitesToParticipantSites(sites: Site[]): ParticipantSite[] {
    return sites.map((associatedSite: Site) => {
      return {
        isDeleted: false,
        site: associatedSite,
      };
    });
  }

  public handleCreateParticipant(): void {
    this.$buefy.modal.open({
      parent: this,
      component: CreateParticipantForm,
      hasModalCard: true,
      props: {
        isEdit: false,
        sites: this.sites,
        participantsList: this.activeParticipants,
      },
      events: {
        submit: (newParticipant: ParticipantFormData) => {
          const transformedSites: ParticipantSite[] = this.transformSitesToParticipantSites(
            newParticipant.participantSites as Site[],
          );

          /**
           * Note:
           *
           * Depending on use cases, "participantSite" of Participant type
           * can be of two different types:
           * 1. Site (when receiving from modal)
           * 2. ParticipantSite (when receiving from parent)
           *
           * In this case, parent is expecting ParticipantSite type.
           * Hence the explicit type casting below before assigning new values for emitting.
           */
          ((newParticipant.participantSites as unknown) as ParticipantSite[]) = transformedSites;

          this.projectParticipants.push(
            (newParticipant as unknown) as ProjectParticipantDataTrfObj,
          );

          /**
           * The local copy of participants is emitted to parent,
           * and value update will trigger a re-render in watcher.
           *
           * Note:
           * Because projectParticipants is a local copy
           * of 'value' prop, that is passed down via v-model,
           * this input event UPDATES parent value directly.
           * In turn, triggering the local watcher.
           */
          this.$emit('input', this.projectParticipants);
        },
      },
    });
  }

  public handleEdit(participantIndex: number): void {
    this.$buefy.modal.open({
      parent: this,
      component: CreateParticipantForm,
      hasModalCard: true,
      props: {
        isEdit: true,
        sites: this.sites,
        participant: this.projectParticipants[participantIndex],
        participantsList: this.projectParticipants,
      },
      events: {
        submit: (updatedParticipant: ParticipantFormData) => {
          // Similar concept in handleCreateParticipant.
          const transformedSites: ParticipantSite[] = this.transformSitesToParticipantSites(
            updatedParticipant.participantSites as Site[],
          );

          ((updatedParticipant.participantSites as unknown) as ParticipantSite[]) = transformedSites;

          this.projectParticipants[
            participantIndex
          ] = (updatedParticipant as unknown) as ProjectParticipantDataTrfObj;

          this.$emit('input', this.projectParticipants);
        },
      },
    });
  }

  public handleDelete(participantIndex: number) {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Delete',
        modalContent: `Are you sure you want to delete this participant?`,
      },
      events: {
        confirm: () => {
          /**
           * During deletion, only remove participant from array
           * when the db record for the participant is not created.
           * (Upsert strategy)
           */
          if (this.projectId && this.projectParticipants[participantIndex].id) {
            this.projectParticipants[participantIndex].isDeleted = true;
          } else {
            this.projectParticipants.splice(participantIndex, 1);
          }

          this.$emit('input', this.projectParticipants);
          this.$buefy.toast.open('Participant had been deleted!');
        },
      },
    });
  }

  @Watch('value')
  public replaceValueFromParent(participants: ProjectParticipantDataTrfObj[]) {
    /**
     * The 'value' here is received using v-model.
     * Hence the re-render is triggered when
     * assigning a cloned copy of the updated value.
     */
    this.projectParticipants = cloneData(participants);
  }
}
