













































































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import {
  ParticipantRoles,
  ProjectParticipantDTO,
  ProjectParticipantRole,
  ProjectParticipantSiteMap,
} from '@/store/modules/projects/types/projects.types';
import AuditParticipantForm from '../components/AuditParticipantForm.vue';
import { Site } from '@/store/modules/projects/types/projects.types';
import { RootState } from '@/store/store';
import { ToastProgrammatic as Toast } from 'buefy';
import { get as _get } from 'lodash';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  AuditSite,
  AuditDetail,
  DeleteAuditParticipantPayload,
  AuditParticipantSite,
  AuditParticipantDTO,
  AuditParticipantDataTrfObj,
  AuditParticipantRole,
} from '../../../store/modules/audits/types/audits.types';
import BaseDialog from '../../../components/base/BaseDialog.vue';
import Card from '@/components/Card.vue';
import DeleteModalBox from '../../../components/DeleteModalBox.vue';
import { ApiState } from '../../../store/types/general.types';
import { useUserEmail } from '@/utils/user.util';
import { getJBIUsername } from '@/jbi-shared/util/user.utils';

@Component({
  components: { Card },
})
export default class AuditParticipantTab extends Vue {
  @Prop({ default: true })
  public editable!: boolean;
  @Prop()
  public auditDetail!: AuditDetail;

  public projectOwnerEmail: string = '';

  @State((state) => state.audits.auditSites)
  public auditSites!: AuditSite[];

  @State((state: RootState) => state.audits.participants)
  public participants!: AuditParticipantDTO[];

  @State((state: RootState) => state.audits.apiState.deleteParticipant)
  public deleteParticipantState!: ApiState;

  @State((state: RootState) => state.audits.apiState.resendInvitation)
  public resendInvitationState!: ApiState;

  @State((state: RootState) => state.audits.apiState.createParticipant)
  public createParticipantState!: ApiState;

  @State((state: RootState) => state.audits.apiState.updateParticipant)
  public updateParticipantState!: ApiState;

  @State((state: RootState) => state.audits.apiState.getAuditSites)
  public getAuditSitesState!: ApiState;

  @Action('audits/getParticipants')
  public getParticipants!: (auditId: number) => void;

  @Action('audits/deleteParticipant')
  public deleteParticipant!: (payload: DeleteAuditParticipantPayload) => void;

  @Action('audits/resendInvitation')
  public resendInvitation!: (payload: {
    auditId: number;
    invitationId: number;
    auditParticipantId: number;
  }) => void;

  @Action('audits/getAuditSites')
  public getAuditSites!: (auditId: number) => void;

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  get auditId() {
    return +this.$route.params.auditId;
  }

  get sites() {
    return this.auditSites.map(({ site }) => ({
      id: site.id,
      name: site.name,
      address: site.address,
      description: site.description,
    }));
  }

  // TODO: PAC-924 check on resend invite
  public invitationStatus(participant: any) {
    const invitation = participant.invitation || '';

    if (invitation.acceptedAt) {
      return 'Accepted';
    }

    if (new Date(invitation.expiredAt) <= new Date()) {
      return 'Expired';
    } else {
      return 'Pending';
    }
  }

  public mounted() {
    this.getAuditSites(this.auditId);
    this.getParticipants(this.auditId);
    this.projectOwnerEmail = this.auditDetail.project.user.email;
  }

  public getParticipantName(participant: AuditParticipantDataTrfObj) {
    if (participant.user) {
      return getJBIUsername(participant.userEmail, participant.user.oicPayload);
    } else {
      return participant.userEmail;
    }
  }

  get items(): AuditParticipantDataTrfObj[] {
    const auditTabParticipants: AuditParticipantDataTrfObj[] = [];
    if (this.participants && this.auditSites) {
      const auditSiteIds: number[] = this.auditSites.map(
        (auditSite) => auditSite.site.id,
      );

      /** get audit data collectors */
      this.participants.forEach((auditParticipant) => {
        if (!auditParticipant.isDeleted) {
          const dataCollectorRole:
            | AuditParticipantRole
            | undefined = auditParticipant.auditParticipantRoles.find(
            (participantRole: AuditParticipantRole) =>
              participantRole.role === ParticipantRoles.dataCollector,
          );

          if (dataCollectorRole && !dataCollectorRole.isDeleted) {
            // ** Filter coordinator's sites based on audit sites */
            const participantSites: ProjectParticipantSiteMap[] = dataCollectorRole.auditParticipantSites.filter(
              (auditParticipantSite) =>
                auditSiteIds.includes(auditParticipantSite.site.id),
            );

            if (participantSites.length > 0) {
              auditTabParticipants.push({
                ...auditParticipant,
                participantRole: dataCollectorRole.role,
                participantSites,
                participantRoleId: dataCollectorRole.id,
                level: 'Audit',
              });
            }
          }
        }
      });

      /** get project coordinators */
      this.auditDetail.project.participants.forEach((projectParticipant) => {
        if (!projectParticipant.isDeleted) {
          /**
           * Participant's role should be matched from participant role.
           * (As a participant could be both project coordinator and data collector
           * within the same project under different sites)
           */
          const projectCoOrdinatorRole:
            | ProjectParticipantRole
            | undefined = projectParticipant.participantRoles.find(
            (participantRole) =>
              participantRole.role === ParticipantRoles.projectCoOrdinator,
          );

          if (projectCoOrdinatorRole && !projectCoOrdinatorRole.isDeleted) {
            // ** Filter coordinator's sites based on audit sites */
            const participantSites: ProjectParticipantSiteMap[] = projectCoOrdinatorRole.participantSites.filter(
              (participantSite) =>
                auditSiteIds.includes(participantSite.site.id),
            );
            if (participantSites.length > 0) {
              auditTabParticipants.push({
                ...projectParticipant,
                participantRole: projectCoOrdinatorRole.role,
                participantSites,
                participantRoleId: projectCoOrdinatorRole.id,
                level: 'Project',
              });
            }
          }
        }
      });
    }
    return auditTabParticipants;
  }

  public handleResend(participant: AuditParticipantDataTrfObj) {
    this.$buefy.modal.open({
      parent: this,
      component: BaseDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title: `Resend`,
        content: `Are you sure you want to resend invitation to this participant?`,
        confirmButtonText: 'Resend',
      },
      events: {
        confirm: () => {
          // TODO:PAC-924 - Resend invite: Commented out for temporary, need to be handle -->
          // if (
          //   this.auditSites.length &&
          //   participant
          // ) {
          //   // A audit participant can be associated with different invites under different sites,
          //   // If there is more than one project coordinator for the same audit
          //   // under different sites who have created them.
          //   // That's why we match the audit participant with the current logged in user's site.
          //   // So we get the invite specific to this audit.
          //   const userSite: Site = this.auditSites[0];
          //   const matchedAuditParticipantSite = participant.auditParticipantRoles[0].participantSites.find(
          //     (auditParticipantSite) =>
          //       auditParticipantSite.site.id === userSite.id,
          //   );
          //   this.resendInvitation({
          //     auditId: this.auditId,
          //     invitationId: matchedAuditParticipantSite.invite.id,
          //     auditParticipantId: participant.id,
          //   });
          // }
        },
      },
    });
  }

  public handleCreate() {
    const modal = this.$buefy.modal.open({
      parent: this,
      props: {
        isEdit: false,
        participant: null,
        sites: this.sites,
        projectOwnerEmail: this.projectOwnerEmail,
        participantsList: this.items,
      },
      component: AuditParticipantForm,
      hasModalCard: true,
      events: {
        close: () => {
          modal.close();
        },
      },
    });
  }

  public handleEdit(participant: AuditParticipantDataTrfObj) {
    const modal = this.$buefy.modal.open({
      parent: this,
      props: {
        isEdit: true,
        participant,
        sites: this.sites,
        projectOwnerEmail: this.projectOwnerEmail,
      },
      component: AuditParticipantForm,
      hasModalCard: true,
      events: {
        close: () => {
          modal.close();
        },
      },
    });
  }

  public async handleDelete(participant: AuditParticipantDataTrfObj) {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteModalBox,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: `Delete ${participant.userEmail}?`,
        modalContent: `${participant.userEmail} will no longer be able to access to this data collection period.`,
      },
      events: {
        confirm: () => {
          const siteIds: number[] = this.sites.map(
            (site: AuditParticipantSite['site']) => site.id,
          );
          this.deleteParticipant({
            auditId: this.auditId,
            auditParticipantId: participant.id,
            auditParticipantRoleId: participant.participantRoleId,
            siteIds,
          });
        },
      },
    });
  }

  @Watch('getAuditSitesState.success')
  @isTruthy
  public onGetAuditSitesState(data: ApiState) {
    const auditSiteIds: number[] =
      this.auditSites.length > 0
        ? this.auditSites.map((auditSite) => auditSite.site.id)
        : [];
  }

  @Watch('deleteParticipantState.success')
  @isTruthy
  public async onDeleteSuccess() {
    this.getParticipants(this.auditId);

    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Successful delete participant`,
    });
  }

  @Watch('resendInvitationState.success')
  @isTruthy
  public async onResendSuccess() {
    this.getParticipants(this.auditId);

    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Successful resend invitation to participant`,
    });
  }

  @Watch('createParticipantState.success')
  @isTruthy
  public onCreateSuccess(): void {
    this.getParticipants(this.auditId);
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Participant added successfully`,
    });
  }

  @Watch('updateParticipantState.success')
  @isTruthy
  public updateSuccess(): void {
    this.getParticipants(this.auditId);
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Participant updated successfully`,
    });
  }
}
