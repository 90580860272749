
















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { Action, State } from 'vuex-class';
import HeroBar from '@/components/base/HeroBar.vue';
import { AuditDetail } from '@/store/modules/audits/types/audits.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import AuditHeader from '@/views/Audit/components/AuditHeader.vue';
import DataCollectionSheet from '@/views/Audit/components/dataCollector/DataCollectionSheet.vue';
import DataHistory from '@/views/Audit/components/dataCollector/DataHistory.vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import { useUserEmail } from '@/utils/user.util';
import { PacesRoles } from '@/store/types/general.types';
import Card from '@/components/Card.vue';
import { AuditStatus } from '@/store/modules/audits/types/audits.types';
import GripTab from '../../Grip/GripTab.vue';
import { ParticipantRoles } from '@/store/modules/projects/types/projects.types';

/**
 * Declaration of available tabs in the page
 */
const tabNames = ['#dataCollectionSheet', '#dataHistory', '#grip'];

@Component({
  components: {
    DefaultLayout,
    HeroBar,
    AuditHeader,
    DataCollectionSheet,
    DataHistory,
    Card,
    GripTab,
  },
})
export default class AuditPageDataCollector extends Vue {
  public currentTab = 0;
  public showDataCollectionSheet: boolean = false;
  public showDataHistoryTab: boolean = false;
  public showGripTab: boolean = false;
  public dataCollectionSheetTabKey: number = Math.floor(Math.random() * 999);
  public dataHistoryTabKey: number = Math.floor(Math.random() * 999);
  public gripTabKey: number = Math.floor(Math.random() * 999);
  public isDataCollectionActive: boolean = false;
  public isDataCollectionArchived: boolean = false;
  public showAuditPage: boolean = false;
  public auditSettingInfo: any = [];
  public dateToday: Date = new Date();
  public isLoading: boolean = true;
  public isDisplayDataCollection: boolean = false;

  @Action('audits/getAuditDetail')
  public getAuditDetail!: (auditId: number) => void;

  @Action('audits/getUpdatedAuditJBICriteriaList')
  public getUpdatedAuditJBICriteriaList!: (auditId: number) => void;

  @State((state) => state.audits.auditDetail)
  public auditDetail!: AuditDetail;

  @State((state) => state.audits.apiState.getAuditDetail.success)
  public getAuditDetailApiStateSuccess!: boolean;

  public mounted(): void {
    this.getAuditDetail(this.auditId);
    this.changeActiveTabBasedOnUrl();
    this.getUpdatedAuditJBICriteriaList(this.auditId);
  }

  public changeActiveTabBasedOnUrl() {
    const tabIndex = Object.values(tabNames).findIndex(
      (value) => value === this.currentRouteHash,
    );
    this.currentTab = tabIndex !== -1 ? tabIndex : 0;
    this.renderTabComponent();
  }

  public tabChange() {
    this.isLoading = true;
    this.getAuditDetail(this.auditId);
    this.$router.replace({ hash: tabNames[this.currentTab] });
    this.renderTabComponent();
  }

  public renderTabComponent() {
    switch (tabNames[this.currentTab]) {
      case '#dataCollectionSheet':
        this.showGripTab = false;
        this.showDataHistoryTab = false;
        this.showDataCollectionSheet = true;
        this.dataCollectionSheetTabKey += 1;
        break;
      case '#dataHistory':
        this.showDataCollectionSheet = false;
        this.showGripTab = false;
        this.showDataHistoryTab = true;
        this.dataHistoryTabKey += 1;
        break;
      case '#grip':
        this.showDataCollectionSheet = false;
        this.showDataHistoryTab = false;
        this.showGripTab = true;
        this.gripTabKey += 1;
        break;
      default:
        break;
    }
  }

  get auditId(): number {
    return +this.$route.params.auditId;
  }

  get currentRouteHash() {
    return this.$route.hash;
  }

  get checkGripStatus() {
    return this.auditDetail.enableGrip;
  }

  public refreshDataCollectionSheet() {
    this.renderTabComponent();
  }

  @Watch('getAuditDetailApiStateSuccess')
  @isTruthy
  public watchGetAuditDetailSuccess() {
    if (
      this.auditDetail.status === 'Deleted' ||
      !this.checkUserAccess(this.auditDetail)
    ) {
      if (this.auditDetail.project) {
        /**
         * if no history routes redirect to project list
         * else redirect 1 step back
         */
        if (window.history.state === null) {
          this.$router.push({
            name: 'project-me',
          });
        } else {
          this.$router.go(-1);
        }
      }
    }
    this.auditSettingInfo = {
      name: this.auditDetail.name,
      description: this.auditDetail.description,
      enableGrip: this.auditDetail.enableGrip,
    };
    this.isDataCollectionActive = this.auditDetail.endedAt ? false : true;
    this.isDataCollectionArchived =
      this.auditDetail.project.status === AuditStatus.ARCHIVED ||
      this.auditDetail.status === AuditStatus.ARCHIVED
        ? true
        : false;
    this.isDisplayDataCollection =
      this.auditDetail.auditCategory === 'Cycle'
        ? this.dateToday < new Date(this.auditDetail.endDate)
        : true;
    this.isLoading = false;
    this.showAuditPage = true;
  }

  public checkUserAccess(value: AuditDetail): boolean {
    const userEmail = this.userEmail;
    const isParticipant = !value.auditParticipants
      ? false
      : value.auditParticipants.find((participant) => {
          return (
            participant.userEmail === userEmail &&
            !participant.isDeleted &&
            participant.auditParticipantRoles.some((role) => {
              return (
                role.role === ParticipantRoles.dataCollector &&
                !role.isDeleted &&
                role.auditParticipantSites.some((siteMap) => {
                  return !siteMap.isDeleted && siteMap.invite.acceptedAt;
                })
              );
            })
          );
        });

    return isParticipant ? true : false;
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  get message() {
    return 'This data collection period has been archived';
  }
}
