




























































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep, get as _get, map as _map } from 'lodash';
import Card from '@/components/Card.vue';
import MultiSiteComparisonTable from '@/components/reports/MultiSiteComparisonTable.vue';
import VerticalBarChart from '@/components/reports/VerticalBarChart';
import HorizontalBarChart from '@/components/reports/HorizontalBarChart';
import {
  AuditSite,
  AuditCriteria,
  AuditReportFiltersConfig,
  AuditReportMultiplePeriodResponse,
  ChartRecords,
  StatisticData,
  AuditReportSinglePeriodResponse,
  ChartType,
} from '@/store/modules/audits/types/audits.types';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import {
  chartColorSet,
  primaryChartColor,
  labelSplitIndex,
} from '@/store/types/general.types';
import CriteriaViewUpdateComponent from '../../Project/components/CriteriaViewUpdateComponent.vue';

const dirtyMonths: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    Card,
    VerticalBarChart,
    HorizontalBarChart,
    MultiSiteComparisonTable,
    CriteriaViewUpdateComponent,
  },
})
export default class MultiSiteComparisonReport extends Vue {
  @Prop()
  public analysisFilter!: AuditReportFiltersConfig;
  @Prop()
  public auditCriteriaList!: AuditCriteria[];
  @Prop()
  public auditSitelist!: AuditSite[];
  @Prop()
  public comparisonReportResponse!: AuditReportMultiplePeriodResponse;

  public dataSet: any[] = [];
  public dataSetOptions: any[] = [];
  public loadingSet: boolean[] = [];
  public verticalBarChartDataSetOption: any = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Compliance %',
            display: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Criteria',
            display: false,
          },
          ticks: {
            minRotation: 0,
            callback: (label: string, index: number) => {
              return this.generateLabel(label, index);
            },
          },
        },
      ],
    },
    legend: {
      display: true,
      onHover: (e: any) => {
        e.target.style.cursor = 'pointer';
      },
      onLeave: (e: any) => {
        e.target.style.cursor = 'default';
      },
      labels: {
        boxWidth: 15,
      },
      align: 'start',
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          if (tooltipItem.yLabel < 0) {
            label += 'N/A';
          } else {
            label += Math.round(tooltipItem.yLabel * 100) / 100;
          }
          return label;
        },
      },
    },
  };
  public horizontalBarChartDataSetOption: any = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Compliance %',
            display: true,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          scaleLabel: {
            labelString: 'Criteria',
            display: false,
          },
          ticks: {
            minRotation: 0,
            callback: (label: string, index: number) => {
              return this.generateLabel(label, index);
            },
          },
        },
      ],
    },
    legend: {
      display: true,
      onHover: (e: any) => {
        e.target.style.cursor = 'pointer';
      },
      onLeave: (e: any) => {
        e.target.style.cursor = 'default';
      },
      labels: {
        boxWidth: 15,
      },
      align: 'start',
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          if (tooltipItem.xLabel < 0) {
            label += 'N/A';
          } else {
            label += Math.round(tooltipItem.xLabel * 100) / 100;
          }
          return label;
        },
      },
    },
  };
  public comparisonIndividualBooleanCriteriaConfiguration: any[] = [];
  public comparisonIndividualMixedCriteriaConfiguration: any[] = [];
  public comparisonAggregateCriteriaConfiguration: any[] = [];
  public criteriaNames: string[] = [];
  public criteriaList: Array<AuditCriteria['criteria']> = [];
  public renderOverallStatisticTable: boolean = true;

  public mounted() {
    this.populateData();
  }

  public populateData() {
    const transformedComparisonPeriods: Date[][] = [];
    this.analysisFilter.comparisonPeriods.forEach((comparisonPeriod) => {
      const transformedSelectedPeriod: Date[] = [];
      comparisonPeriod.forEach((period) => {
        transformedSelectedPeriod.push(new Date(period));
      });
      transformedComparisonPeriods.push(transformedSelectedPeriod);
    });
    this.analysisFilter.comparisonPeriods = _cloneDeep(
      transformedComparisonPeriods,
    );
    if (!this.comparisonReportResponse.success) {
      this.dataSet = [];
      this.dataSetOptions = [];
      this.loadingSet = [];
      this.comparisonIndividualBooleanCriteriaConfiguration = [];
      this.comparisonIndividualMixedCriteriaConfiguration = [];
    } else {
      this.dataSet = [];
      this.dataSetOptions = [];
      this.loadingSet = [];
      this.comparisonIndividualBooleanCriteriaConfiguration = [];
      this.comparisonIndividualMixedCriteriaConfiguration = [];
      const chartData: ChartRecords[][] = [];
      const statisticData: StatisticData[][] = [];
      const samplingConfigurationData: any[] = [];
      _map(
        this.comparisonReportResponse.comparisonResult,
        (comparisonResult: AuditReportSinglePeriodResponse) => {
          const dirtyChartData = comparisonResult.chartData;
          const dirtyStatisticData =
            comparisonResult.statisticData.configurationData;
          if (dirtyChartData.length > 0) {
            chartData.push(dirtyChartData);
          }
          statisticData.push(dirtyStatisticData);
          samplingConfigurationData.push(
            comparisonResult.statisticData.samplingConfiguration,
          );
        },
      );
      this.generateComparisonConfigurationData(
        statisticData,
        samplingConfigurationData,
      );
      if (chartData.length > 0) {
        this.multiSiteComparisonRender(chartData);
      }
    }
  }

  public multiSiteComparisonRender(chartData: ChartRecords[][]) {
    if (this.analysisFilter.checkIndividualSite) {
      if (this.analysisFilter.checkIndividualCriteria) {
        this.renderComparisonIndividualSiteIndividualCriteria(chartData);
      } else {
        this.renderComparisonIndividualSiteAggregateCriteria(chartData);
      }
    } else {
      if (this.analysisFilter.checkIndividualCriteria) {
        this.renderComparisonAggregateSiteIndividualCriteria(chartData);
      } else {
        this.renderComparisonAgregateSiteAggregateCriteria(chartData);
      }
    }
  }

  public renderComparisonIndividualSiteIndividualCriteria(
    chartData: ChartRecords[][],
  ) {
    this.criteriaNames = [];
    const booleanFilteredCriteriaMapIds: number[] = this
      .getBooleanCriteriaMapIds;
    _map(booleanFilteredCriteriaMapIds, (criteriaMapId: number) => {
      let criteriaName = '';
      _map(this.auditCriteriaList, (auditCriteria) => {
        if (auditCriteria.id === criteriaMapId) {
          criteriaName = auditCriteria.criteria.title;
          this.criteriaNames.push(auditCriteria.criteria.title);
          this.criteriaList.push(auditCriteria.criteria);
        }
      });
      const data = {
        labels: [] as string[],
        datasets: [] as any[],
      };
      let dataOptions = {};
      let dataOptionsObject: any = {};
      Object.keys(chartData).forEach((key: string) => {
        const chartValues: number[] = [];
        const siteNames: string[] = [];
        const backgroundColors: string[] = [];
        const singleChartData = chartData[Number(key)];
        Object.keys(singleChartData).map((dataKey: string) => {
          if (
            singleChartData[Number(dataKey)].auditCriteriaId === criteriaMapId
          ) {
            _map(
              this.analysisFilter.filteredSites,
              (siteMapId: number, siteMapIndex: string) => {
                chartValues.push(
                  singleChartData[Number(dataKey)].data[Number(siteMapIndex)]
                    .compliance,
                );
              },
            );
            _map(singleChartData[Number(dataKey)].data, (eachRecord) => {
              siteNames.push(eachRecord.site.name);
              backgroundColors.push(chartColorSet[Number(key)]);
            });
          }
        });
        const dataSetObject = {
          label: this.formatDateRange(
            this.analysisFilter.comparisonPeriods[Number(key)],
          ),
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: chartValues,
          categoryPercentage: this
            .getIndividualCriteriaIndividualSiteChartCategoryPercentage,
        };
        data.datasets.push(dataSetObject);
        data.labels = _cloneDeep(siteNames);
      });
      if (
        this.analysisFilter.selectedChartType === ChartType.verticalBarChart
      ) {
        dataOptionsObject = _cloneDeep(this.verticalBarChartDataSetOption);
      } else {
        dataOptionsObject = _cloneDeep(this.horizontalBarChartDataSetOption);
      }
      dataOptionsObject.tooltips.callbacks.title = () => criteriaName;
      dataOptionsObject.tooltips.callbacks.afterTitle = (item: any[]) =>
        item[0].label;
      dataOptions = _cloneDeep(dataOptionsObject);
      this.dataSet.push(data);
      this.dataSetOptions.push(dataOptions);
      this.loadingSet.push(true);
    });
  }

  public renderComparisonAggregateSiteIndividualCriteria(
    chartData: ChartRecords[][],
  ) {
    let siteName: string;
    switch (this.analysisFilter.filteredSites.length) {
      case 1:
        siteName = chartData[0][0].data[0].site.name;
        break;
      case this.auditSitelist.length:
        siteName = 'All Sites';
        break;
      default:
        siteName =
          'Selected sites (' + this.analysisFilter.filteredSites.length + ')';
    }
    this.criteriaNames = [];
    const booleanFilteredCriteriaMapIds: number[] = this
      .getBooleanCriteriaMapIds;
    _map(booleanFilteredCriteriaMapIds, (criteriaMapId: number) => {
      let criteriaName = '';
      _map(this.auditCriteriaList, (auditCriteria) => {
        if (auditCriteria.id === criteriaMapId) {
          criteriaName = auditCriteria.criteria.title;
          this.criteriaNames.push(auditCriteria.criteria.title);
          this.criteriaList.push(auditCriteria.criteria);
        }
      });
      const data = {
        labels: [] as string[],
        datasets: [] as any[],
      };
      let dataOptions = {};
      let dataOptionsObject: any = {};
      Object.keys(chartData).forEach((key: string) => {
        const chartValues: number[] = [];
        const backgroundColors: string[] = [];
        const singleChartData = chartData[Number(key)];
        Object.keys(singleChartData).map((dataKey: string) => {
          if (
            singleChartData[Number(dataKey)].auditCriteriaId === criteriaMapId
          ) {
            chartValues.push(
              singleChartData[Number(dataKey)].data[0].compliance,
            );
          }
        });
        backgroundColors.push(chartColorSet[Number(key)]);
        const dataSetObject = {
          label: this.formatDateRange(
            this.analysisFilter.comparisonPeriods[Number(key)],
          ),
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          data: chartValues,
          categoryPercentage: this
            .getIndividualCriteriaAggregateSiteChartCategoryPercentage,
        };
        data.datasets.push(dataSetObject);
      });
      data.labels = [siteName];
      if (
        this.analysisFilter.selectedChartType === ChartType.verticalBarChart
      ) {
        dataOptionsObject = _cloneDeep(this.verticalBarChartDataSetOption);
      } else {
        dataOptionsObject = _cloneDeep(this.horizontalBarChartDataSetOption);
      }
      dataOptionsObject.tooltips.callbacks.title = () => criteriaName;
      dataOptionsObject.tooltips.callbacks.afterTitle = () => siteName;
      dataOptions = _cloneDeep(dataOptionsObject);
      this.dataSet.push(data);
      this.dataSetOptions.push(dataOptions);
      this.loadingSet.push(true);
    });
  }

  public getSiteNameByAuditSiteMapId(auditSiteMapId: number): string {
    const siteName = this.auditSitelist.filter(
      (auditSite) => auditSite.id === auditSiteMapId,
    );
    return siteName[0].site.name;
  }

  public getChartValues(
    chartData: ChartRecords[],
    auditSiteMapId: number,
  ): number[] {
    const chartValues: number[] = [];
    Object.keys(chartData).map((key: string) => {
      const dirtyChartData = chartData[Number(key)];
      _map(dirtyChartData.data, (dirtyData, dirtyDataIndex) => {
        if (dirtyData.auditSiteId === auditSiteMapId) {
          chartValues.push(dirtyData.compliance);
        }
      });
    });
    return chartValues;
  }

  public getCriteriaNames(chartData: ChartRecords[]): string[] {
    const criteriaNames: string[] = [];
    Object.keys(chartData).map((key: string) => {
      criteriaNames.push(chartData[Number(key)].criteria.title);
    });
    return criteriaNames;
  }

  public getBackgroundColors(
    periodIndex: number,
    auditSiteMapIdIndex: number,
    totalPeriods: number,
  ): string[] {
    const backgroundColors: string[] = [];
    const dirtyColorSet = _cloneDeep(chartColorSet);
    const factor = auditSiteMapIdIndex * totalPeriods;
    if (factor !== 0) {
      dirtyColorSet.splice(0, factor);
    }
    _map(
      this.analysisFilter.filteredCriterias,
      (auditCriteriaMapId: number, auditCriteriaMapIndex: string) => {
        backgroundColors.push(dirtyColorSet[periodIndex]);
      },
    );
    return backgroundColors;
  }

  public getSiteName(auditSiteMapId: number) {
    const auditSite = this.auditSitelist.filter(
      (site) => site.id === auditSiteMapId,
    );
    return auditSite[0].site.name;
  }

  public getCustomLabel(auditSiteMapId: number, periodKey: number) {
    const siteName = this.getSiteName(auditSiteMapId);
    const formatPeriod = this.formatDateRange(
      this.analysisFilter.comparisonPeriods[periodKey],
    );
    return siteName + ' ( ' + formatPeriod + ' ) ';
  }

  public renderComparisonIndividualSiteAggregateCriteria(
    chartData: ChartRecords[][],
  ) {
    const data = {
      labels: [] as string[],
      datasets: [] as any[],
    };
    let dataOptions = {};
    _map(
      this.analysisFilter.filteredSites,
      (auditSiteMapId: number, auditSiteMapIdIndex: string) => {
        Object.keys(chartData).map((key: string) => {
          const singleChartData = chartData[Number(key)];
          const chartValues: number[] = this.getChartValues(
            singleChartData,
            auditSiteMapId,
          );
          const backgroundColors: string[] = this.getBackgroundColors(
            Number(key),
            Number(auditSiteMapIdIndex),
            this.analysisFilter.comparisonPeriods.length,
          );
          const dataSetObject = {
            label: this.getCustomLabel(auditSiteMapId, Number(key)),
            backgroundColor: backgroundColors,
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            data: chartValues,
            categoryPercentage: this
              .getAggregateCriteriaAggregateSiteChartCategoryPercentage,
          };
          data.datasets.push(dataSetObject);
        });
      },
    );
    let dataOptionsObject;
    if (this.analysisFilter.selectedChartType === ChartType.verticalBarChart) {
      dataOptionsObject = _cloneDeep(this.verticalBarChartDataSetOption);
    } else {
      dataOptionsObject = _cloneDeep(this.horizontalBarChartDataSetOption);
    }
    data.labels = this.getCriteriaNames(chartData[0]);
    dataOptions = _cloneDeep(dataOptionsObject);
    this.dataSet.push(data);
    this.dataSetOptions.push(dataOptions);
    this.loadingSet.push(true);
  }

  public renderComparisonAgregateSiteAggregateCriteria(
    chartData: ChartRecords[][],
  ) {
    let siteName: string;
    switch (this.analysisFilter.filteredSites.length) {
      case 1:
        siteName = chartData[0][0].data[0].site.name;
        break;
      case this.auditSitelist.length:
        siteName = 'All Sites';
        break;
      default:
        siteName =
          'Selected sites (' + this.analysisFilter.filteredSites.length + ')';
    }
    const data = {
      labels: [] as string[],
      datasets: [] as any[],
    };
    let dataOptions = {};
    const labels: string[] = [];
    let dataOptionsObject: any = {};
    Object.keys(chartData).map((key: string) => {
      const chartValues: number[] = [];
      const backgroundColors: string[] = [];
      const singleChartData = chartData[Number(key)];
      Object.keys(singleChartData).map((dataKey: string) => {
        if (Number(key) === 0) {
          labels.push(singleChartData[Number(dataKey)].criteria.title);
        }
        chartValues.push(singleChartData[Number(dataKey)].data[0].compliance);
        backgroundColors.push(chartColorSet[Number(key)]);
      });
      const dataSetObject = {
        label: this.formatDateRange(
          this.analysisFilter.comparisonPeriods[Number(key)],
        ),
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
        pointBackgroundColor: 'white',
        borderWidth: 1,
        pointBorderColor: '#249EBF',
        data: chartValues,
        categoryPercentage: this
          .getAggregateCriteriaAggregateSiteChartCategoryPercentage,
      };
      data.datasets.push(dataSetObject);
    });
    if (this.analysisFilter.selectedChartType === ChartType.verticalBarChart) {
      dataOptionsObject = _cloneDeep(this.verticalBarChartDataSetOption);
    } else {
      dataOptionsObject = _cloneDeep(this.horizontalBarChartDataSetOption);
    }
    dataOptionsObject.tooltips.callbacks.afterTitle = () => siteName;
    data.labels = _cloneDeep(labels);
    dataOptions = _cloneDeep(dataOptionsObject);
    this.dataSet.push(data);
    this.dataSetOptions.push(dataOptions);
    this.loadingSet.push(true);
  }

  public generateComparisonConfigurationData(
    configurationData: any[],
    samplingConfigurationData: any[],
  ) {
    if (this.analysisFilter.checkIndividualSite) {
      if (
        Array.isArray(configurationData) &&
        configurationData.length ===
          this.analysisFilter.comparisonPeriods.length
      ) {
        const aggregateConfiguration: any[] = [];
        _map(
          this.analysisFilter.filteredCriterias,
          (criteriaMapId, ooooooo) => {
            const aggregateCriteriaConfiguration: any[] = [];
            _map(configurationData, (configData, index) => {
              let dirtyAggregateConfiguration: any = {};
              configData.forEach((data: any) => {
                if (data.id === criteriaMapId) {
                  const criteriaSamplingData = data.criteriaSamplingData.filter(
                    (item: any) => {
                      const criteriaOptionsDataDistribution =
                        item.criteriaOptionsDataDistribution;
                      const dirtyCriteriaSamplingData = Object.keys(
                        criteriaOptionsDataDistribution,
                      ).map((key: string) => ({
                        [key]:
                          data.criteriaSamplingData[0]
                            .criteriaOptionsDataDistribution[key],
                      }));
                      return dirtyCriteriaSamplingData;
                    },
                  );
                  const dirtyConfig = {
                    id: data.criteria.id,
                    title: _get(data, 'criteria.title', ''),
                    criteriaType: _get(data, 'criteria.criteriaType', ''),
                    sampleSize: Number(
                      _get(
                        data,
                        'criteriaSamplingDataConfigurations.sampleSize',
                        0,
                      ),
                    ),
                    criteriaOptions: JSON.parse(data.criteria.criteriaOptions),
                    siteSamplingConfiguration:
                      data.siteSamplingDataConfigurations,
                    criteriaSamplingDataConfiguration:
                      data.criteriaSamplingDataConfigurations,
                    samplingConfiguration: samplingConfigurationData[index],
                    criteriaSamplingData,
                  };
                  dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
                }
              });
              aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
            });
            aggregateConfiguration.push(aggregateCriteriaConfiguration);
          },
        );
        this.comparisonAggregateCriteriaConfiguration = _cloneDeep(
          aggregateConfiguration,
        );
      } else {
        this.comparisonAggregateCriteriaConfiguration = [];
      }
    } else {
      if (
        Array.isArray(configurationData) &&
        configurationData.length ===
          this.analysisFilter.comparisonPeriods.length
      ) {
        const aggregateConfiguration: any[] = [];
        _map(this.analysisFilter.filteredCriterias, (criteriaMapId) => {
          const aggregateCriteriaConfiguration: any[] = [];
          _map(configurationData, (configData, index) => {
            let dirtyAggregateConfiguration: any = {};
            configData.forEach((data: any) => {
              if (data.id === criteriaMapId) {
                const criteriaOptionsDataDistribution =
                  data.criteriaSamplingData.criteriaOptionsDataDistribution;
                const criteriaSamplingData = Object.keys(
                  criteriaOptionsDataDistribution,
                ).map((key: string) => ({
                  [key]:
                    data.criteriaSamplingData.criteriaOptionsDataDistribution[
                      key
                    ],
                }));
                const dirtyConfig = {
                  id: data.criteria.id,
                  title: _get(data, 'criteria.title', ''),
                  criteriaType: _get(data, 'criteria.criteriaType', ''),
                  sampleSize: Number(
                    _get(
                      data,
                      'criteriaSamplingDataConfigurations.sampleSize',
                      0,
                    ),
                  ),
                  criteriaOptions: JSON.parse(data.criteria.criteriaOptions),
                  siteSamplingConfiguration:
                    data.siteSamplingDataConfigurations,
                  criteriaSamplingDataConfiguration:
                    data.criteriaSamplingDataConfigurations,
                  samplingConfiguration: samplingConfigurationData[index],
                  criteriaSamplingData,
                };
                dirtyAggregateConfiguration = _cloneDeep(dirtyConfig);
              }
            });
            aggregateCriteriaConfiguration.push(dirtyAggregateConfiguration);
          });
          aggregateConfiguration.push(aggregateCriteriaConfiguration);
        });
        this.comparisonAggregateCriteriaConfiguration = _cloneDeep(
          aggregateConfiguration,
        );
      } else {
        this.comparisonAggregateCriteriaConfiguration = [];
      }
    }
    this.generateComparisonIndividualCriteriaSet(
      this.comparisonAggregateCriteriaConfiguration,
    );
  }

  public generateComparisonIndividualCriteriaSet(
    comparisonAggregateCriteriaConfiguration: any[],
  ) {
    this.comparisonIndividualBooleanCriteriaConfiguration = comparisonAggregateCriteriaConfiguration.filter(
      (item) => {
        return item[0].criteriaType === CRITERION_TYPE.BOOLEAN;
      },
    );
    this.comparisonIndividualMixedCriteriaConfiguration = comparisonAggregateCriteriaConfiguration.filter(
      (item) => {
        return item[0].criteriaType !== CRITERION_TYPE.BOOLEAN;
      },
    );
    if (
      this.comparisonIndividualBooleanCriteriaConfiguration.length ===
        this.comparisonAggregateCriteriaConfiguration.length &&
      this.analysisFilter.checkIndividualCriteria
    ) {
      this.renderOverallStatisticTable = false;
    }
  }

  public getDate(newValue: Date): string {
    return (
      newValue.getDate() +
      ' ' +
      dirtyMonths[newValue.getMonth()] +
      ' ' +
      newValue.getFullYear()
    );
  }

  public formatDateRange(date: Date[]): string {
    return this.getDate(date[0]) + ' - ' + this.getDate(date[1]);
  }

  public getMixedBooleanTitleIndex(index: number): number {
    return (
      this.comparisonIndividualBooleanCriteriaConfiguration.length + index + 1
    );
  }

  public generateLabel = (label: string, index: number): string | string[] => {
    if (this.analysisFilter.checkIndividualCriteria) {
      return this.transformLabel(label);
    } else {
      if (this.analysisFilter.filteredCriterias.length > 8) {
        return index + 1 + '.';
      }
      return this.transformLabel(label);
    }
  };

  public transformLabel(label: string): string | string[] {
    const words = label.split(' ');
    let endIndex: number = labelSplitIndex;
    let eachLine: string = '';
    const eachLabel: string[] = [];
    _map(words, (word: string, wordIndex: string) => {
      switch (Number(wordIndex)) {
        case 0:
          eachLine = word;
          break;
        case endIndex:
          eachLabel.push(eachLine);
          eachLine = word;
          endIndex += labelSplitIndex;
          break;
        case words.length - 1:
          eachLine += ' ' + word;
          eachLabel.push(eachLine);
          break;
        default:
          eachLine += ' ' + word;
      }
    });
    if (eachLabel.length >= 3) {
      eachLabel[2] = eachLabel[2] + '...';
      return eachLabel.slice(0, 3);
    }
    return label;
  }

  get getBooleanCriteriaMapIds(): number[] {
    const criteriaMapIds: number[] = [];
    const booleanCriteriaMapIds: number[] = [];
    _map(this.auditCriteriaList, (auditCriteria) => {
      if (auditCriteria.criteria.criteriaType === CRITERION_TYPE.BOOLEAN) {
        booleanCriteriaMapIds.push(auditCriteria.id);
      }
    });
    _map(booleanCriteriaMapIds, (eachId: number) => {
      if (this.analysisFilter.filteredCriterias.includes(eachId)) {
        criteriaMapIds.push(eachId);
      }
    });
    return criteriaMapIds;
  }

  get getIndividualCriteriaAggregateSiteChartCategoryPercentage(): number {
    const noOfPeriods: number = this.analysisFilter.comparisonPeriods.length;
    return noOfPeriods <= 10 ? noOfPeriods * 0.1 : 0.8;
  }

  get getSingleCriteriaIndividualSiteChartCategoryPercentage(): number {
    const noOfPeriods: number = this.analysisFilter.comparisonPeriods.length;
    const noOfSites: number = this.analysisFilter.filteredSites.length;
    return noOfSites * noOfPeriods <= 10 ? noOfPeriods * 0.2 : 0.8;
  }

  get getAggregateCriteriaAggregateSiteChartCategoryPercentage(): number {
    const noOfBooleanCriterias: number = this
      .comparisonIndividualBooleanCriteriaConfiguration.length;
    if (noOfBooleanCriterias === 1) {
      return this.getIndividualCriteriaAggregateSiteChartCategoryPercentage;
    }
    const noOfPeriods: number = this.analysisFilter.comparisonPeriods.length;
    return noOfBooleanCriterias * noOfPeriods <= 10 ? noOfPeriods * 0.2 : 0.8;
  }

  get getIndividualCriteriaIndividualSiteChartCategoryPercentage(): number {
    const noOfBooleanCriterias: number = this
      .comparisonIndividualBooleanCriteriaConfiguration.length;
    if (noOfBooleanCriterias === 1) {
      return this.getSingleCriteriaIndividualSiteChartCategoryPercentage;
    }
    const noOfPeriods: number = this.analysisFilter.comparisonPeriods.length;
    if (this.analysisFilter.selectedChartType === ChartType.verticalBarChart) {
      return noOfBooleanCriterias * noOfPeriods <= 10 ? noOfPeriods * 0.2 : 0.8;
    } else {
      return noOfBooleanCriterias * noOfPeriods <= 10 ? noOfPeriods * 0.3 : 0.8;
    }
  }

  get getSelectedCriteriaList(): AuditCriteria[] {
    const selectedCriteriaList: any[] = [];
    this.analysisFilter.filteredCriterias.forEach(
      (auditCriteriaMapId: number) => {
        const auditCriteria = this.auditCriteriaList.find((criteria) => {
          return (
            criteria.id === auditCriteriaMapId &&
            criteria.criteria.criteriaType === CRITERION_TYPE.BOOLEAN
          );
        });
        if (auditCriteria) {
          selectedCriteriaList.push(auditCriteria);
        }
      },
    );
    return selectedCriteriaList;
  }
}
