































import { Component, Vue, Prop } from 'vue-property-decorator';
import ChartView from '../components/ChartView.vue';
import AuditParticipantList from '../components/AuditParticipantList.vue';
import DataCollectionList from '../components/DataCollectionList.vue';
import AuditInvitationList from '../components/AuditInvitationList.vue';
import AuditActivityLogList from '../components/AuditActivityLogList.vue';
import AuditDataHistoryList from '../components/AuditDataHistoryList.vue';
import {
  AuditDetail,
  AuditParticipantDTO,
  AuditSite,
} from '../../../store/modules/audits/types/audits.types';
import { RootState } from '@/store/store';
import { Action, State } from 'vuex-class';

@Component({
  components: {
    ChartView,
    AuditParticipantList,
    DataCollectionList,
    AuditInvitationList,
    AuditActivityLogList,
    AuditDataHistoryList,
  },
})
export default class AuditDashboardTab extends Vue {
  @Prop() public auditDetail!: AuditDetail;

  @Action('audits/getAuditSites')
  public getAuditSites!: (auditId: number) => void;

  @State((state: RootState) => state.audits.auditSites)
  public auditSites!: AuditSite[];

  @Action('audits/getParticipants')
  public getAuditParticipants!: (auditId: number) => void;

  @State((state: RootState) => state.audits.participants)
  public auditParticipants!: AuditParticipantDTO[];

  private get auditId(): number {
    return +this.$route.params.auditId;
  }

  public mounted() {
    this.getAuditSites(this.auditId);
    this.getAuditParticipants(this.auditId);
  }

  public changeActiveTab(newValue: string) {
    this.$emit('changeActiveTab', newValue);
  }
}
