






































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/utils/date.util';
import moment from 'moment';
import { cloneDeep as _cloneDeep } from 'lodash';
import { AuditDetail } from '../../../store/modules/audits/types/audits.types';
import { UserRoleInAudit } from '@/store/modules/projects/types/projects.types';

@Component({ components: {} })
export default class AuditList extends Vue {
  @Prop()
  public activeAuditList!: AuditDetail[];
  @Prop()
  public archivedAuditList!: AuditDetail[];

  public dirtyActiveAuditList: AuditDetail[] = [];
  public dirtyArchivedAuditList: AuditDetail[] = [];
  public showViewLessBtn: boolean = false;
  public showViewAllBtn: boolean = true;
  public showArchivedList: boolean = false;
  public dateToday: Date = new Date();

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  public mounted() {
    this.dirtyActiveAuditList = _cloneDeep(this.activeAuditList);
    this.dirtyArchivedAuditList = _cloneDeep(this.archivedAuditList);
    if (this.dirtyActiveAuditList.length > 5) {
      this.dirtyActiveAuditList = this.activeAuditList.slice(0, 5);
    }
  }

  public isDataCollector(item: AuditDetail): boolean {
    return item.userRoleInAudit === UserRoleInAudit.isDataCollector
      ? true
      : false;
  }

  public getAuditUrl(item: AuditDetail): string {
    if (
      item.userRoleInAudit &&
      (item.userRoleInAudit === UserRoleInAudit.isOwner ||
        item.userRoleInAudit === UserRoleInAudit.isProjectCoOrdinator)
    ) {
      let auditUrl = '/audits/' + item.id + '#dashboard';
      if (this.dataCollectionStatus(item) !== 'Not Started') {
        auditUrl = '/audits/' + item.id;
      }
      return auditUrl;
    } else {
      return '/audits/' + item.id + '/homepage#dataCollectionSheet';
    }
  }

  public getDurationText(startDate: string, endDate: string) {
    if (startDate === null && endDate === null) {
      return 'N/A';
    } else {
      if (endDate !== null) {
        return `${dayjs(startDate).format(DATE_FORMAT)} - ${dayjs(
          endDate,
        ).format(DATE_FORMAT)}`;
      } else {
        return `from ${dayjs(startDate).format(DATE_FORMAT)}`;
      }
    }
  }

  public dataCollectionStatus(auditDetail: any) {
    if (
      auditDetail.endedAt &&
      moment(new Date()).isSameOrAfter(auditDetail.endedAt)
    ) {
      return `Completed on ${this.formatDate(auditDetail.endedAt)}`;
    } else if (
      auditDetail.startedAt &&
      moment(new Date()).isSameOrAfter(auditDetail.startedAt)
    ) {
      return 'Ongoing';
    } else {
      return 'Not Started';
    }
  }

  public handleViewAll() {
    this.showViewLessBtn = true;
    this.showViewAllBtn = false;
    this.dirtyActiveAuditList = _cloneDeep(this.activeAuditList);
  }

  public handleViewLess() {
    this.showViewLessBtn = false;
    this.showViewAllBtn = true;
    this.dirtyActiveAuditList = this.activeAuditList.slice(0, 5);
  }

  public showArchivedAudits() {
    this.showArchivedList = true;
  }

  public hideArchivedAudits() {
    this.showArchivedList = false;
  }
}
