import {
  PdfContentStyle,
  PdfDividerLine,
} from '@/store/modules/projects/types/projects.types';
import {
  Grip,
  GripAnalysis,
  GripAnalysisBarrier,
  GripAnalysisCriteria,
  GripAnalysisEnabler,
  GripAnalysisStrategy,
  GripExportReportResponse,
} from '@/store/types/grip.types';
import dayjs from 'dayjs';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {
  Content,
  ContentTable,
  TableCell,
  TDocumentDefinitions,
} from 'pdfmake/interfaces';
import JbiLogo from '../../../../assets/images/jbi_navbar_img@2x.png';

export const GripExportColors = {
  primary: '#00205B',
  secondary: '#1D70B8',
  lightGray: '#DBDBDB',
  darkGray: '#444f51',
  gray: '#8C8C8C',
  lightBlue: '#D3E9FA',
};

export const GripExportSize = {
  titleHeader: 12,
  secondary: 9,
};

export const generateGripReportPdf = (
  isSummary: boolean,
  gripExportReport: GripExportReportResponse,
  isDomainCategoryRequired: boolean,
  projectTitle: string,
) => {
  (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

  const exportDateTime: string = dayjs().format('D MMM YYYY, HH:mm A');
  const fileName: string =
    projectTitle + '_' + dayjs().format('DDMMYYYY') + '.pdf';

  let reportContent: TDocumentDefinitions = {
    info: {
      title: fileName,
    },
    pageMargins: [40, 40],
    content: [],
    footer: (currentPage: number) => {
      return {
        columns: [
          {
            text: 'Exported on ' + exportDateTime,
            alignment: 'left',
            color: GripExportColors.darkGray,
            fontSize: GripExportSize.secondary,
            margin: [40, 10, 0, 10],
          },
          {
            text: currentPage.toString(),
            alignment: 'right',
            color: GripExportColors.darkGray,
            fontSize: GripExportSize.secondary,
            margin: [0, 10, 40, 10],
          },
        ],
      };
    },
    pageBreakBefore: (currentNode: any, followingNodesOnPage) => {
      if (currentNode.id && currentNode.id.includes('table_analysis')) {
        const findCurrentNodeIndex: number = followingNodesOnPage.findIndex(
          (value) => value && value.id === currentNode.id,
        );

        /**
         * Adding page break,
         * if current node is starting new analysis in table,
         * and following node would be ending the page,
         * break page on this node itself.
         */
        if (
          (followingNodesOnPage[findCurrentNodeIndex + 1] &&
            followingNodesOnPage[findCurrentNodeIndex + 1].pageNumbers.length >
              1) ||
          followingNodesOnPage.length < 4
        ) {
          return true;
        }
      } else {
        return currentNode.pageNumbers.length > 1 && currentNode.unbreakable;
      }
    },
  };

  /** Generates the Grip Report */
  reportContent = generateReportContent(
    reportContent,
    gripExportReport,
    projectTitle,
  );

  if (gripExportReport.gripAnalysis.length > 0) {
    /** Generates the Grip Report Table */
    reportContent = generateReportTableContent(
      reportContent,
      gripExportReport,
      isDomainCategoryRequired,
    );
  }

  if (isSummary) {
    pdfMake.createPdf(reportContent as TDocumentDefinitions).open();
  } else {
    pdfMake.createPdf(reportContent as TDocumentDefinitions).download(fileName);
  }
};

function generateReportContent(
  reportContent: any,
  gripExportReport: GripExportReportResponse,
  projectTitle: string,
): TDocumentDefinitions {
  /** Set Paces Logo and Header of pdf */
  const header: Content = setReportHeader();
  reportContent.content.push(header);

  /** Set title of report i.e. Project Title */
  const projectName: PdfContentStyle = setReportTitle(projectTitle);
  reportContent.content.push(projectName);

  /** Set grip team details */
  const teamDetails: PdfContentStyle[] = setTeamDetails(
    gripExportReport.gripTeamDetails,
  );
  reportContent.content.push(teamDetails);
  reportContent.content.push(setLineDivider());

  /** loop grip analysis */
  const allGripAnalysis = gripExportReport.gripAnalysis.map(
    (analysis: GripAnalysis) => {
      const analysisReportData: PdfContentStyle[] = [];
      const analysisTitle = setGripAnalysisTitle(analysis.title);
      analysisReportData.push(analysisTitle);

      /** set analysis criteria list */
      if (analysis.gripAnalysisCriteriaMaps.length > 0) {
        const criteriaHeader: PdfContentStyle = setCriteriaTitle('Criteria', [
          0,
          5,
        ]);
        const analysisCriteria: PdfContentStyle[] = setAnalysisCriteria(
          analysis.gripAnalysisCriteriaMaps,
        );
        analysisReportData.push(criteriaHeader);
        analysisReportData.push(...analysisCriteria);

        if (analysis.gripAnalysisBarriers.length > 0) {
          const analysisData: PdfContentStyle[] = setAnalysisData(
            analysis.gripAnalysisBarriers,
          );
          analysisReportData.push(...analysisData);
        }
      } else {
        const noData: PdfContentStyle = setContentTitle(
          'No data added for this analysis.',
          [0, 5, 0, 5],
        );
        analysisReportData.push(noData);
      }

      analysisReportData.push(setLineDivider());
      return analysisReportData;
    },
  );

  /** add all analysis content */
  reportContent.content.push(allGripAnalysis);
  return reportContent;
}

function generateReportTableContent(
  reportContent: any,
  gripExportReport: GripExportReportResponse,
  isDomainCategoryRequired: boolean,
): TDocumentDefinitions {
  /** set table report  title  */
  reportContent.content.push([
    {
      text: 'GRiP REPORT TABLE',
      color: GripExportColors.primary,
      fontSize: 20,
      bold: true,
      margin: [0, 20, 0, 20],
      alignment: 'center',
      pageBreak: 'before',
    },
  ]);

  const tableColWidths: string[] = isDomainCategoryRequired
    ? ['25%', '5%', '20%', '5%', '20%', '5%', '20%']
    : ['25%', '25%', '25%', '25%'];

  /** create table content */
  let tableContent: ContentTable = {
    style: {
      color: GripExportColors.darkGray,
    },
    table: {
      widths: tableColWidths,
      body: [],
    },
  };

  /** add table headers */
  tableContent.table.body.push(setTableHeader(isDomainCategoryRequired));

  /** loop analysis and add the respective criteria/barriers/enablers/strategies */
  gripExportReport.gripAnalysis.forEach((analysis) => {
    tableContent.table.body.push(
      setGripTableAnalysisTitle(
        analysis.title.toLocaleUpperCase(),
        [0, 5],
        isDomainCategoryRequired,
      ),
    );

    if (!analysis.gripAnalysisCriteriaMaps.length) {
      tableContent.table.body.push([
        ...setGripTableNoDataRow(
          'No Data added.',
          [0, 0],
          isDomainCategoryRequired,
        ),
      ]);
    } else {
      if (isDomainCategoryRequired) {
        tableContent = setTableAnalysisDataWithDomain(tableContent, analysis);
      } else {
        tableContent = setTableAnalysisData(tableContent, analysis);
      }
    }
  });

  reportContent.content.push(tableContent);
  return reportContent;
}

function setReportHeader(): ContentTable {
  return {
    layout: 'noBorders',
    table: {
      widths: [96, '*'],
      heights: 4,
      body: [
        [
          {
            fit: [75, 25],
            image: JbiLogo,
            margin: [0, 7, 0, 5],
          },
          {
            text: [
              {
                text: 'JBI PACES ',
                bold: true,
              },
              'GRiP ANALYSIS',
            ],
            fontSize: 20,
            color: '#FFFFFF',
            fillColor: '#062157',
            margin: [0, 7, 0, 5],
          },
        ],
      ],
    },
  };
}

function setReportTitle(projectTitle: string): PdfContentStyle {
  return {
    text: projectTitle.replace(/\s+/g, ' ').toUpperCase(),
    color: GripExportColors.primary,
    fontSize: GripExportSize.titleHeader,
    bold: true,
    margin: [0, 20, 0, 20],
  };
}

function setTeamDetails(gripTeamDetails: Grip) {
  const teamDetails: PdfContentStyle[] = [];
  teamDetails.push(setLabel('Project Leader'));
  teamDetails.push(setValue(gripTeamDetails.projectLead));
  teamDetails.push(setLabel('Stakeholders'));
  teamDetails.push(setValue(gripTeamDetails.stakeholder));
  teamDetails.push(setLabel('Date to Implement Action'));
  teamDetails.push(
    setValue(dayjs(gripTeamDetails.actionDate).format('D MMM YYYY')),
  );
  return teamDetails;
}

function setAnalysisCriteria(
  gripAnalysisCriteriaMaps: GripAnalysisCriteria[],
): PdfContentStyle[] {
  return gripAnalysisCriteriaMaps.map((analysisCriteria, index) => {
    return {
      text: [
        `${index + 1}. ${analysisCriteria.criteria.documentId}  `,
        {
          text: analysisCriteria.criteria.title,
          bold: true,
        },
      ],
      color: GripExportColors.darkGray,
      fontSize: GripExportSize.secondary,
      margin: [0, 5, 0, 5],
    };
  });
}

function setGripAnalysisTitle(title: string): PdfContentStyle {
  return {
    text: title.replace(/\s+/g, ' ').toUpperCase(),
    color: GripExportColors.gray,
    fontSize: GripExportSize.titleHeader,
    bold: true,
    margin: [0, 10, 0, 10],
  };
}

function setAnalysisData(
  gripAnalysisBarriers: GripAnalysisBarrier[],
): PdfContentStyle[] {
  const analysisData: PdfContentStyle[] = [];

  /** loop barriers and add */
  gripAnalysisBarriers.forEach((analysisBarrier, index) => {
    /** Add Earrier */
    const barrier: PdfContentStyle[] = setBarrier(analysisBarrier, index);
    analysisData.push(...barrier);

    if (
      analysisBarrier.action &&
      analysisBarrier.gripAnalysisEnablers.length > 0
    ) {
      /** loop enablers and add */
      analysisBarrier.gripAnalysisEnablers.forEach((analysisEnabler, index) => {
        /** Add Enabler */
        const enabler: PdfContentStyle[] = setEnabler(analysisEnabler, index);
        analysisData.push(...enabler);

        /** loop strategies and add */
        if (analysisEnabler.gripAnalysisStrategies.length > 0) {
          analysisEnabler.gripAnalysisStrategies.forEach(
            (analysisStrategy, index) => {
              /** Add Strategy */
              const strategy: PdfContentStyle[] = setStrategy(
                analysisStrategy,
                index,
              );
              analysisData.push(...strategy);
            },
          );
        }
      });
    }
  });
  return analysisData;
}

function setBarrier(
  barrier: GripAnalysisBarrier,
  index: number,
): PdfContentStyle[] {
  const titleMargin: number[] = [0, 20, 0, 5];
  const contentMargin: number[] = [0, 2, 0, 2];
  const listContentMargin: number[] = [10, 0, 0, 5];
  const barrierContent: PdfContentStyle[] = [];

  /** Barrier Title */
  const barrierTitle = setContentTitle(
    `Barrier ${index + 1}: ${barrier.title}`,
    titleMargin,
  );
  barrierContent.push(barrierTitle);

  /** Barrier Domain Category */
  if (barrier.gripDomainCategory) {
    const barrierDomainCategory = setContentDetails(
      'Domain/Category:',
      barrier.gripDomainCategory.name,
      contentMargin,
    );
    barrierContent.push(barrierDomainCategory);
  }

  /** Barrier Description */
  if (barrier.description) {
    const barrierDescription = setContentDetails(
      'Description:',
      barrier.description,
      contentMargin,
    );
    barrierContent.push(barrierDescription);
  }

  /** Barrier Links */
  if (barrier.links.length > 0) {
    const barrierLinkLabel = setContentDetails('Link(s):', '', contentMargin);
    const barrierLinks = barrier.links.map((link) =>
      setLink(link, listContentMargin),
    );
    barrierContent.push(barrierLinkLabel);
    barrierContent.push(...barrierLinks);
  }

  /** Barrier Attachments */
  if (barrier.attachments.length > 0) {
    const barrierAttachmentsLabel = setContentDetails(
      'Attachment(s):',
      '',
      contentMargin,
    );
    const barrierAttachments = barrier.attachments.map((attachment) =>
      setAttachment(attachment, listContentMargin),
    );
    barrierContent.push(barrierAttachmentsLabel);
    barrierContent.push(...barrierAttachments);
  }

  /** Barrier Justification, if not to be actioned */
  if (!barrier.action && barrier.reason) {
    const isNotToBeActioned = setContentDetails(
      'Not to be actioned',
      '',
      contentMargin,
    );
    barrierContent.push(isNotToBeActioned);

    const barrierJustification = setContentDetails(
      'Justification:',
      barrier.reason,
      contentMargin,
    );
    barrierContent.push(barrierJustification);
  }

  return barrierContent;
}

function setEnabler(
  enabler: GripAnalysisEnabler,
  index: number,
): PdfContentStyle[] {
  const titleMargin: number[] = [30, 20, 0, 5];
  const contentMargin: number[] = [30, 2, 0, 2];
  const listContentMargin: number[] = [40, 0, 0, 5];
  const enablerContent: PdfContentStyle[] = [];

  /** Enabler Title */
  const enablerTitle = setContentTitle(
    `Enabler ${index + 1}: ${enabler.title}`,
    titleMargin,
  );
  enablerContent.push(enablerTitle);

  /** Enabler Domain Category */
  if (enabler.gripDomainCategory) {
    const enablerDomainCategory = setContentDetails(
      'Domain/Category:',
      enabler.gripDomainCategory.name,
      contentMargin,
    );
    enablerContent.push(enablerDomainCategory);
  }

  /** Enabler Description */
  if (enabler.description) {
    const enablerDescription = setContentDetails(
      'Description:',
      enabler.description,
      contentMargin,
    );
    enablerContent.push(enablerDescription);
  }

  /** Enabler Links */
  if (enabler.links.length > 0) {
    const enablerLinkLabel = setContentDetails('Link(s):', '', contentMargin);
    const enablerLinks = enabler.links.map((link) =>
      setLink(link, listContentMargin),
    );
    enablerContent.push(enablerLinkLabel);
    enablerContent.push(...enablerLinks);
  }

  /** Enabler Attachments */
  if (enabler.attachments.length > 0) {
    const enablerAttachmentsLabel = setContentDetails(
      'Attachment(s):',
      '',
      contentMargin,
    );
    const enablerAttachments = enabler.attachments.map((attachment) =>
      setAttachment(attachment, listContentMargin),
    );
    enablerContent.push(enablerAttachmentsLabel);
    enablerContent.push(...enablerAttachments);
  }

  return enablerContent;
}

function setStrategy(
  strategy: GripAnalysisStrategy,
  index: number,
): PdfContentStyle[] {
  const titleMargin: number[] = [60, 20, 0, 5];
  const contentMargin: number[] = [60, 2, 0, 2];
  const listContentMargin: number[] = [70, 0, 0, 5];
  const strategyContent: PdfContentStyle[] = [];

  /** Strategy Title */
  const strategyTitle = setContentTitle(
    `Strategy ${index + 1}: ${strategy.title}`,
    titleMargin,
  );
  strategyContent.push(strategyTitle);

  /** Strategy Domain Category */
  if (strategy.gripDomainCategory) {
    const strategyDomainCategory = setContentDetails(
      'Domain/Category:',
      strategy.gripDomainCategory.name,
      contentMargin,
    );
    strategyContent.push(strategyDomainCategory);
  }

  /** Strategy Description */
  if (strategy.description) {
    const strategyDescription = setContentDetails(
      'Description:',
      strategy.description,
      contentMargin,
    );
    strategyContent.push(strategyDescription);
  }

  /** Strategy Links */
  if (strategy.links.length > 0) {
    const strategyLinkLabel = setContentDetails('Link(s):', '', contentMargin);
    const strategyLinks = strategy.links.map((link) =>
      setLink(link, listContentMargin),
    );
    strategyContent.push(strategyLinkLabel);
    strategyContent.push(...strategyLinks);
  }

  /** Strategy Attachments */
  if (strategy.attachments.length > 0) {
    const strategyAttachmentsLabel = setContentDetails(
      'Attachment(s):',
      '',
      contentMargin,
    );
    const strategyAttachments = strategy.attachments.map((attachment) =>
      setAttachment(attachment, listContentMargin),
    );
    strategyContent.push(strategyAttachmentsLabel);
    strategyContent.push(...strategyAttachments);
  }

  /** Strategy Resource */
  if (strategy.resource.length > 0) {
    const strategyResource = setContentDetails(
      'Resources Required:',
      strategy.resource,
      contentMargin,
    );
    strategyContent.push(strategyResource);
  }

  /** Strategy Method */
  if (strategy.method.length > 0) {
    const strategyMethod = setContentDetails(
      'How the Strategy is measured or evaluated:',
      strategy.method,
      contentMargin,
    );
    strategyContent.push(strategyMethod);
  }

  return strategyContent;
}

function setTableHeader(isDomainCategoryRequired: boolean): TableCell[] {
  const tableHeaderTitles: string[] = isDomainCategoryRequired
    ? ['Criteria', 'Barriers', '', 'Enablers', '', 'Strategies', '']
    : ['Criteria', 'Barriers', 'Enablers', 'Strategies'];

  const tableHeaderRowData: TableCell[] = [];
  tableHeaderTitles.forEach((headerTitle) => {
    const columnSpan: number = isDomainCategoryRequired
      ? headerTitle === 'Criteria'
        ? 1
        : 2
      : 1;

    tableHeaderRowData.push({
      text: headerTitle,
      style: 'tableHeader',
      alignment: 'center',
      fillColor: '#333333',
      color: '#FAFBFC',
      bold: true,
      margin: [0, 5],
      colSpan: columnSpan,
    });
  });
  return tableHeaderRowData;
}

function setGripTableAnalysisTitle(
  analysisTitle: string,
  margin: number[],
  isDomainCategoryRequired: boolean,
) {
  return [
    {
      id: `table_analysis_${analysisTitle}`,
      text: analysisTitle.replace(/\s+/g, ' '),
      colSpan: isDomainCategoryRequired ? 7 : 4,
      alignment: 'center',
      color: '#8C8C8C',
      bold: true,
      fontSize: GripExportSize.titleHeader,
      margin,
    },
  ];
}

function setGripTableNoDataRow(
  text: string,
  margin: number[],
  isDomainCategoryRequired: boolean,
) {
  return [
    {
      text,
      colSpan: isDomainCategoryRequired ? 7 : 4,
      alignment: 'center',
      color: GripExportColors.darkGray,
      bold: true,
      fontSize: GripExportSize.secondary,
      margin,
    },
  ];
}

function setTableAnalysisData(
  tableContent: ContentTable,
  analysis: GripAnalysis,
): ContentTable {
  let totalAnalysisRowCount: number = 0;
  /** get count of rows required for analysis */
  if (analysis.gripAnalysisBarriers.length > 0) {
    analysis.gripAnalysisBarriers.forEach((analysisBarrier) => {
      if (analysisBarrier.gripAnalysisEnablers.length > 0) {
        analysisBarrier.gripAnalysisEnablers.forEach((analysisEnabler) => {
          if (analysisEnabler.gripAnalysisStrategies.length > 0) {
            totalAnalysisRowCount +=
              analysisEnabler.gripAnalysisStrategies.length;
          } else {
            totalAnalysisRowCount += 1;
          }
        });
      } else {
        totalAnalysisRowCount += 1;
      }
    });
  } else {
    totalAnalysisRowCount += 1;
  }

  let currentRowIndex: number = tableContent.table.body.length;
  /** Add empty rows for analysis data */
  for (let i = 1; i <= totalAnalysisRowCount; i++) {
    tableContent.table.body.push([i, analysis.title, '', '']);
  }

  /** Add analysis criteria */
  const criteriaTitles: string[] = analysis.gripAnalysisCriteriaMaps
    .filter((criteriaMap) => !criteriaMap.criteria.isDeleted)
    .map((criteriaMap) => criteriaMap.criteria.title);

  tableContent.table.body[currentRowIndex].splice(0, 1, {
    stack: [
      {
        ol: criteriaTitles.map((title: string) => {
          return {
            text: title,
            margin: [5, 5],
            fontSize: GripExportSize.secondary,
          };
        }),
      },
    ],
    rowSpan: totalAnalysisRowCount,
    fontSize: GripExportSize.secondary,
  });

  let barrierRowIndex: number = currentRowIndex;
  let enablerRowIndex: number = currentRowIndex;
  let strategyRowIndex: number = currentRowIndex;
  if (analysis.gripAnalysisBarriers.length > 0) {
    /** loop analysis barriers */
    analysis.gripAnalysisBarriers.forEach((analysisBarrier) => {
      let barrierRowSpan: number = 0;
      if (
        analysisBarrier.gripAnalysisEnablers.length > 0 &&
        analysisBarrier.action
      ) {
        /** loop analysis enablers */
        analysisBarrier.gripAnalysisEnablers.forEach((analysisEnabler) => {
          const enablerRowSpan: number =
            analysisEnabler.gripAnalysisStrategies.length;

          if (analysisEnabler.gripAnalysisStrategies.length) {
            /** loop analysis strategies */
            analysisEnabler.gripAnalysisStrategies.forEach(
              (analysisStartegy) => {
                /** Add analysis startegy data to table */
                tableContent.table.body[strategyRowIndex].splice(3, 1, {
                  text: analysisStartegy.title,
                  margin: [5, 5],
                  fontSize: GripExportSize.secondary,
                });
                strategyRowIndex += 1;
              },
            );
          } else {
            tableContent.table.body[strategyRowIndex].splice(3, 1, {
              text: 'No Strategy Included.',
              margin: [5, 5],
              fontSize: GripExportSize.secondary,
            });
            strategyRowIndex += 1;
          }

          /** Add analysis enabler data to table */
          tableContent.table.body[enablerRowIndex].splice(2, 1, {
            text: analysisEnabler.title,
            rowSpan: enablerRowSpan ? enablerRowSpan : 1,
            margin: [5, 5],
            fontSize: GripExportSize.secondary,
          });
          enablerRowIndex += enablerRowSpan ? enablerRowSpan : 1;
          barrierRowSpan += enablerRowSpan ? enablerRowSpan : 1;
        });
      } else {
        tableContent.table.body[enablerRowIndex].splice(
          2,
          2,
          {
            text: 'No Enabler Included.',
            margin: [5, 5],
            fontSize: GripExportSize.secondary,
          },
          {
            text: 'No Strategy Included.',
            margin: [5, 5],
            fontSize: GripExportSize.secondary,
          },
        );
        enablerRowIndex += 1;
        strategyRowIndex += 1;
      }

      /** Add analysis barrier data to table */
      let barrierColData = analysisBarrier.action
        ? [analysisBarrier.title]
        : [
            { text: analysisBarrier.title },
            { text: '\nNot to be actioned.', bold: true },
            {
              text: [
                { text: '\nJustification: ', bold: true },
                `${analysisBarrier.reason}`,
              ],
            },
          ];

      tableContent.table.body[barrierRowIndex].splice(1, 1, {
        text: barrierColData,
        margin: [5, 5],
        rowSpan: barrierRowSpan ? barrierRowSpan : 1,
        fontSize: GripExportSize.secondary,
      });
      barrierRowIndex += barrierRowSpan ? barrierRowSpan : 1;
    });
  } else {
    tableContent.table.body[barrierRowIndex].splice(
      1,
      3,
      {
        text: 'No Barrier Included.',
        margin: [5, 5],
        fontSize: GripExportSize.secondary,
      },
      {
        text: 'No Enabler Included.',
        margin: [5, 5],
        fontSize: GripExportSize.secondary,
      },
      {
        text: 'No Strategy Included.',
        margin: [5, 5],
        fontSize: GripExportSize.secondary,
      },
    );
    barrierRowIndex += 1;
  }

  return tableContent;
}

function setTableAnalysisDataWithDomain(
  tableContent: ContentTable,
  analysis: GripAnalysis,
): ContentTable {
  let totalAnalysisRowCount: number = 0;
  if (analysis.gripAnalysisBarriers.length > 0) {
    analysis.gripAnalysisBarriers.forEach((analysisBarrier) => {
      totalAnalysisRowCount += 1;

      if (analysisBarrier.gripAnalysisEnablers.length > 0) {
        analysisBarrier.gripAnalysisEnablers.forEach((analysisEnabler) => {
          totalAnalysisRowCount += 1;

          if (analysisEnabler.gripAnalysisStrategies.length > 0) {
            totalAnalysisRowCount +=
              analysisEnabler.gripAnalysisStrategies.length * 2;
          } else {
            totalAnalysisRowCount += 2;
          }
        });
      } else {
        totalAnalysisRowCount += 2;
      }
    });
  } else {
    totalAnalysisRowCount += 2;
  }

  let currentRowIndex: number = tableContent.table.body.length;
  /** Add empty rows for analysis data */
  for (let i = 1; i <= totalAnalysisRowCount; i++) {
    tableContent.table.body.push(['', '', '', '', '', '', '']);
  }

  if (analysis.gripAnalysisBarriers.length > 0) {
    /** generate analysis data based on DomainCategory */
    const {
      barriers,
      enablers,
      strategies,
    } = generateAnalysisTableDataByDomainCategory(analysis);

    tableContent = mapAnalysisTableDataVertical(
      tableContent,
      barriers,
      enablers,
      strategies,
      currentRowIndex,
    );
  } else {
    tableContent.table.body[currentRowIndex].splice(
      1,
      6,
      {
        text: 'No Barrier Included.',
        margin: [5, 5],
        colSpan: 2,
        fontSize: GripExportSize.secondary,
      },
      '',
      {
        text: 'No Enabler Included.',
        margin: [5, 5],
        colSpan: 2,
        fontSize: GripExportSize.secondary,
      },
      '',
      {
        text: 'No Strategy Included.',
        margin: [5, 5],
        colSpan: 2,
        fontSize: GripExportSize.secondary,
      },
      '',
    );
  }

  /** remove extra empty rows */
  tableContent.table.body = tableContent.table.body.filter((row) => {
    return JSON.stringify(row) !== JSON.stringify(['', '', '', '', '', '', '']);
  });

  const criteriaTitles: string[] = analysis.gripAnalysisCriteriaMaps.map(
    (criteria) => criteria.criteria.title,
  );

  /** Add analysis criteria */
  tableContent.table.body[currentRowIndex].splice(0, 1, {
    stack: [
      {
        ol: criteriaTitles.map((title) => {
          return {
            text: title,
            margin: [0, 5],
            fontSize: GripExportSize.secondary,
          };
        }),
      },
    ],
    rowSpan: tableContent.table.body.length - currentRowIndex,
    fontSize: GripExportSize.secondary,
  });

  return tableContent;
}

function generateAnalysisTableDataByDomainCategory(analysis: GripAnalysis) {
  let barriers: any = {};
  let enablers: any = {};
  let strategies: any = {};

  analysis.gripAnalysisBarriers.forEach((barrier) => {
    enablers[`barrier_${barrier.id}`] = {};

    const barrierDomainCategory: string = barrier.gripDomainCategory
      ? barrier.gripDomainCategory.name
      : 'NoDomainCategory';

    /** separate the barriers based on DomainCategory  */
    if (
      barriers[barrierDomainCategory] &&
      barriers[barrierDomainCategory].length
    ) {
      barriers[barrierDomainCategory].push(barrier);
    } else {
      barriers[barrierDomainCategory] = [barrier];
    }

    barrier.gripAnalysisEnablers.forEach((enabler) => {
      strategies[`enabler_${enabler.id}`] = {};
      const enablerDomainCategory: string = enabler.gripDomainCategory
        ? enabler.gripDomainCategory.name
        : 'NoDomainCategory';

      /** separate the enablers based on DomainCategory  */
      const analysisEnablers =
        enablers[`barrier_${barrier.id}`][enablerDomainCategory];

      if (analysisEnablers) {
        enablers[`barrier_${barrier.id}`][enablerDomainCategory].push(enabler);
      } else {
        enablers[`barrier_${barrier.id}`][enablerDomainCategory] = [enabler];
      }

      enabler.gripAnalysisStrategies.forEach((strategy) => {
        const strategyDomainCategory: string = strategy.gripDomainCategory
          ? strategy.gripDomainCategory.name
          : 'NoDomainCategory';

        const analysisStratergies =
          strategies[`enabler_${enabler.id}`][strategyDomainCategory];

        /** separate the stratergies based on DomainCategory  */
        if (analysisStratergies) {
          strategies[`enabler_${enabler.id}`][strategyDomainCategory].push(
            strategy,
          );
        } else {
          strategies[`enabler_${enabler.id}`][strategyDomainCategory] = [
            strategy,
          ];
        }
      });
    });
  });

  return { barriers, enablers, strategies };
}

function mapAnalysisTableData(
  tableContent: any,
  barriers: any,
  enablers: any,
  strategies: any,
  currentRowIndex: number,
) {
  let barrierRowIndex: number = currentRowIndex;
  let enablerRowIndex: number = currentRowIndex;
  let strategyRowIndex: number = currentRowIndex;

  const domainCategoriesOfBarriers: string[] = Object.keys(barriers);
  /** Find NoDomainCategory index and add them at the end of the object */
  const noDomainCategoryBarrierIndex: number = domainCategoriesOfBarriers.findIndex(
    (value) => value === 'NoDomainCategory',
  );
  if (noDomainCategoryBarrierIndex >= 0) {
    domainCategoriesOfBarriers.splice(noDomainCategoryBarrierIndex, 1);
    domainCategoriesOfBarriers.push('NoDomainCategory');
  }

  domainCategoriesOfBarriers.forEach((barrierDCK, barrierDCKIndex) => {
    const analysisBarriers: GripAnalysisBarrier[] = barriers[barrierDCK];
    let barrierDomainCategory = { isAdded: false, index: barrierRowIndex };
    let noEnablerStrategyDataRowSpan: number = 0;

    /** add domain category */
    tableContent.table.body[barrierRowIndex].splice(1, 1, {
      colSpan: 1,
      text: barrierDCK === 'NoDomainCategory' ? '-' : barrierDCK,
      fillColor: GripExportColors.lightBlue,
      alignment: 'center',
      margin: [0, 5],
      fontSize: GripExportSize.secondary,
    });
    barrierDomainCategory = { isAdded: true, index: barrierRowIndex };
    barrierRowIndex += 1;
    noEnablerStrategyDataRowSpan += 1;

    analysisBarriers.forEach(
      (analysisBarrier: GripAnalysisBarrier, abIndex: number) => {
        let barrierRowSpan = 0;
        /**
         * check whether barrier is to be actioned and have enablers,
         * if true, add enablers, else add no data column
         */
        if (
          analysisBarrier.action &&
          enablers[`barrier_${analysisBarrier.id}`] &&
          Object.keys(enablers[`barrier_${analysisBarrier.id}`]).length > 0
        ) {
          const barrierEnablers = enablers[`barrier_${analysisBarrier.id}`];
          const domainCategoriesOfEnablers: string[] = Object.keys(
            barrierEnablers,
          );

          /** Find NoDomainCategory index and add them at the end of the object */
          const noDomainCategoryEnablerIndex = domainCategoriesOfEnablers.findIndex(
            (value) => value === 'NoDomainCategory',
          );
          if (noDomainCategoryEnablerIndex >= 0) {
            domainCategoriesOfEnablers.splice(noDomainCategoryEnablerIndex, 1);
            domainCategoriesOfEnablers.push('NoDomainCategory');
          }

          domainCategoriesOfEnablers.forEach((enablerDCK, enablerDCKIndex) => {
            const analysisEnablers: GripAnalysisEnabler[] =
              barrierEnablers[enablerDCK];
            let enablerDomainCategory = {
              isAdded: false,
              index: enablerRowIndex,
            };
            let noStrategyDataRowSpan = 0;

            /** Add domain category:
             * Barriers, Enablers and Strategies can each have their own domain categories.
             * The combination of column cells for domain categories within barriers, enablers can be done if,
             * 1. barrier and enabler share the same domain category
             * 2. It should be first barrier of respective domain category (i.e abIndex)
             * 3. It should be first domain category for enabler(i.e. enablerDCKIndex)
             */
            if (
              barrierDCK === enablerDCK &&
              abIndex === 0 &&
              enablerDCKIndex === 0
            ) {
              let index = barrierRowIndex;
              if (barrierDomainCategory.isAdded) {
                index = barrierDomainCategory.index;
                noStrategyDataRowSpan += 1;
              } else {
                barrierRowIndex += 1;
              }

              tableContent.table.body[index].splice(1, 1, {
                colSpan: 2,
                text: barrierDCK === 'NoDomainCategory' ? '-' : barrierDCK,
                fillColor: GripExportColors.lightBlue,
                alignment: 'center',
                margin: [0, 5],
                fontSize: GripExportSize.secondary,
              });
              enablerRowIndex += 1;
            } else {
              enablerDomainCategory = {
                isAdded: true,
                index: enablerRowIndex,
              };
              tableContent.table.body[enablerRowIndex].splice(2, 1, {
                text: enablerDCK === 'NoDomainCategory' ? '-' : enablerDCK,
                fillColor: GripExportColors.lightBlue,
                alignment: 'center',
                margin: [0, 5],
                fontSize: GripExportSize.secondary,
              });
              enablerRowIndex += 1;
              barrierRowSpan += barrierRowIndex === enablerRowIndex ? 0 : 1;
              noStrategyDataRowSpan += 1;
            }

            analysisEnablers.forEach(
              (analysisEnabler: GripAnalysisEnabler, aeIndex: number) => {
                let enablerRowSpan: number = 0;
                const enablerStratergies =
                  strategies[`enabler_${analysisEnabler.id}`];

                const domainCategoriesOfStratergies = Object.keys(
                  enablerStratergies,
                );
                /** Find NoDomainCategory index and add them at the end of the object */
                const noDomainCategoryStrategyIndex = domainCategoriesOfStratergies.findIndex(
                  (value) => value === 'NoDomainCategory',
                );
                if (noDomainCategoryStrategyIndex >= 0) {
                  domainCategoriesOfStratergies.splice(
                    noDomainCategoryStrategyIndex,
                    1,
                  );
                  domainCategoriesOfStratergies.push('NoDomainCategory');
                }

                /**
                 * if enabler have startegy(ies) add enablers,
                 * else add no data column
                 */
                if (
                  strategies[`enabler_${analysisEnabler.id}`] &&
                  Object.keys(strategies[`enabler_${analysisEnabler.id}`])
                    .length > 0
                ) {
                  domainCategoriesOfStratergies.forEach(
                    (strategyDCK, strategyDCKIndex) => {
                      /**  Add domain category:
                       * Barriers, Enablers and Strategies can each have their own domain categories.
                       * The combination of column cells for domain categories within barriers, enablers, and strategies can be done in the following ways.
                       *
                       * A. if all 3 share the same domain category
                       *  1. barrier, enabler and strategy share the same domain category
                       *  2. It should be first barrier and enabler of respective domain category(i.e. abIndex, aeIndex)
                       *  3. It should be first domain category for enablers and strategy(i.e. enablerDCKIndex, strategyDCKIndex)
                       *
                       * B. else if only 2 share the same domain category
                       *  1. if enabler and strategy share the same domain category
                       *  2. It should be first enabler of respective domain category(i.e. aeIndex)
                       *  3. It should be first domain category for strategies(i.e. strategyDCKIndex)
                       */
                      if (
                        barrierDCK === enablerDCK &&
                        enablerDCK === strategyDCK &&
                        abIndex === 0 &&
                        aeIndex === 0 &&
                        enablerDCKIndex === 0 &&
                        strategyDCKIndex === 0
                      ) {
                        let index = barrierRowIndex;
                        if (barrierDomainCategory.isAdded) {
                          index = barrierDomainCategory.index;
                        } else {
                          barrierRowIndex += 1;
                          enablerRowIndex += 1;
                        }

                        tableContent.table.body[index].splice(1, 1, {
                          colSpan: 3,
                          text:
                            barrierDCK === 'NoDomainCategory'
                              ? '-'
                              : barrierDCK,
                          fillColor: GripExportColors.lightBlue,
                          alignment: 'center',
                          margin: [0, 5],
                          fontSize: GripExportSize.secondary,
                        });
                        strategyRowIndex += 1;
                      } else if (
                        enablerDCK === strategyDCK &&
                        aeIndex === 0 &&
                        strategyDCKIndex === 0
                      ) {
                        let index = enablerRowIndex;
                        if (enablerDomainCategory.isAdded) {
                          index = enablerDomainCategory.index;
                        } else {
                          enablerRowIndex += 1;
                          barrierRowSpan +=
                            enablerRowIndex === barrierRowIndex ? 0 : 1;
                        }
                        tableContent.table.body[index].splice(2, 1, {
                          colSpan: 2,
                          text:
                            enablerDCK === 'NoDomainCategory'
                              ? '-'
                              : enablerDCK,
                          fillColor: GripExportColors.lightBlue,
                          alignment: 'center',
                          margin: [0, 5],
                          fontSize: GripExportSize.secondary,
                        });
                        strategyRowIndex += 1;
                      } else {
                        tableContent.table.body[strategyRowIndex].splice(3, 1, {
                          colSpan: 1,
                          text:
                            strategyDCK === 'NoDomainCategory'
                              ? '-'
                              : strategyDCK,
                          fillColor: GripExportColors.lightBlue,
                          alignment: 'center',
                          margin: [0, 5],
                          fontSize: GripExportSize.secondary,
                        });
                        strategyRowIndex += 1;
                        enablerRowSpan +=
                          strategyRowIndex === enablerRowIndex ? 0 : 1;
                      }

                      const analysisStratergies: GripAnalysisStrategy[] =
                        enablerStratergies[strategyDCK];

                      /**  add startegy(ies) data */
                      analysisStratergies.forEach(
                        (analysisStrategy: GripAnalysisStrategy) => {
                          tableContent.table.body[strategyRowIndex].splice(
                            3,
                            1,
                            {
                              text: analysisStrategy.title,
                              margin: [5, 5],
                              fontSize: GripExportSize.secondary,
                            },
                          );
                          strategyRowIndex += 1;
                          enablerRowSpan += 1;
                        },
                      );
                    },
                  );

                  /**  add enabler data */
                  tableContent.table.body[enablerRowIndex].splice(2, 1, {
                    text: analysisEnabler.title,
                    margin: [5, 5],
                    rowSpan: enablerRowSpan ? enablerRowSpan : 1,
                    fontSize: GripExportSize.secondary,
                  });
                  enablerRowIndex += enablerRowSpan ? enablerRowSpan : 1;
                  barrierRowSpan += enablerRowSpan ? enablerRowSpan : 1;
                } else {
                  /** add enabler data  */
                  tableContent.table.body[enablerRowIndex].splice(2, 1, {
                    text: analysisEnabler.title,
                    margin: [5, 5],
                    rowSpan: enablerRowSpan ? enablerRowSpan : 1,
                    fontSize: GripExportSize.secondary,
                  });
                  enablerRowIndex += enablerRowSpan ? enablerRowSpan : 1;
                  barrierRowSpan += enablerRowSpan ? enablerRowSpan : 1;
                  noStrategyDataRowSpan += enablerRowSpan ? enablerRowSpan : 1;

                  /** add empty data */
                  tableContent.table.body[strategyRowIndex].splice(3, 1, {
                    text: 'No Strategy Included.',
                    margin: [5, 5],
                    rowSpan: noStrategyDataRowSpan ? noStrategyDataRowSpan : 1,
                    fontSize: GripExportSize.secondary,
                  });
                  strategyRowIndex += noStrategyDataRowSpan
                    ? noStrategyDataRowSpan
                    : 1;
                }
                noStrategyDataRowSpan = 0;
              },
            );
          });
          /** add barrier data */
          tableContent.table.body[barrierRowIndex].splice(1, 1, {
            text: analysisBarrier.title,
            margin: [5, 5],
            rowSpan: barrierRowSpan ? barrierRowSpan : 1,
            fontSize: GripExportSize.secondary,
          });
          barrierRowIndex += barrierRowSpan ? barrierRowSpan : 1;
        } else {
          /** add barrier data */
          let barrierColData = analysisBarrier.action
            ? [analysisBarrier.title]
            : [
                { text: analysisBarrier.title },
                { text: '\nNot to be actioned.', bold: true },
                {
                  text: [
                    { text: '\nJustification: ', bold: true },
                    `${analysisBarrier.reason}`,
                  ],
                },
              ];

          tableContent.table.body[barrierRowIndex].splice(1, 1, {
            text: barrierColData,
            margin: [5, 5],
            rowSpan: barrierRowSpan ? barrierRowSpan : 1,
            fontSize: GripExportSize.secondary,
          });
          barrierRowIndex += barrierRowSpan ? barrierRowSpan : 1;
          noEnablerStrategyDataRowSpan += 1;

          /** add empty data */
          tableContent.table.body[enablerRowIndex].splice(2, 1, {
            text: 'No Enabler Included.',
            margin: [5, 5],
            rowSpan:
              noEnablerStrategyDataRowSpan > 0
                ? noEnablerStrategyDataRowSpan
                : 1,
            fontSize: GripExportSize.secondary,
          });
          enablerRowIndex += noEnablerStrategyDataRowSpan
            ? noEnablerStrategyDataRowSpan
            : 1;

          tableContent.table.body[strategyRowIndex].splice(3, 1, {
            text: 'No Strategy Included.',
            margin: [5, 5],
            rowSpan:
              noEnablerStrategyDataRowSpan > 0
                ? noEnablerStrategyDataRowSpan
                : 1,
            fontSize: GripExportSize.secondary,
          });
          strategyRowIndex += noEnablerStrategyDataRowSpan
            ? noEnablerStrategyDataRowSpan
            : 1;
        }
        noEnablerStrategyDataRowSpan = 0;
      },
    );
  });

  return tableContent;
}

function mapAnalysisTableDataVertical(
  tableContent: any,
  barriers: any,
  enablers: any,
  strategies: any,
  currentRowIndex: number,
) {
  let barrierRowIndex: number = currentRowIndex;
  let enablerRowIndex: number = currentRowIndex;
  let strategyRowIndex: number = currentRowIndex;

  const domainCategoriesOfBarriers: string[] = Object.keys(barriers);
  /** Find NoDomainCategory index and add them at the end of the object */
  const noDomainCategoryBarrierIndex: number = domainCategoriesOfBarriers.findIndex(
    (value) => value === 'NoDomainCategory',
  );
  if (noDomainCategoryBarrierIndex >= 0) {
    domainCategoriesOfBarriers.splice(noDomainCategoryBarrierIndex, 1);
    domainCategoriesOfBarriers.push('NoDomainCategory');
  }

  domainCategoriesOfBarriers.forEach((barrierDCK) => {
    const analysisBarriers: GripAnalysisBarrier[] = barriers[barrierDCK];
    let barrierDomainCategory = { isAdded: true, index: barrierRowIndex };
    let barrierCategorySpan = 0;
    let barrierColSpan = barrierDCK === 'NoDomainCategory' ? 2 : 1;
    const barrierColIndex = barrierDCK === 'NoDomainCategory' ? 1 : 2;

    analysisBarriers.forEach((analysisBarrier: GripAnalysisBarrier) => {
      let barrierRowSpan = 0;
      /**
       * check whether barrier is to be actioned and have enablers,
       * if true, add enablers, else add no data column
       */
      if (
        analysisBarrier.action &&
        enablers[`barrier_${analysisBarrier.id}`] &&
        Object.keys(enablers[`barrier_${analysisBarrier.id}`]).length > 0
      ) {
        const barrierEnablers = enablers[`barrier_${analysisBarrier.id}`];
        const domainCategoriesOfEnablers: string[] = Object.keys(
          barrierEnablers,
        );

        /** Find NoDomainCategory index and add them at the end of the object */
        const noDomainCategoryEnablerIndex = domainCategoriesOfEnablers.findIndex(
          (value) => value === 'NoDomainCategory',
        );
        if (noDomainCategoryEnablerIndex >= 0) {
          domainCategoriesOfEnablers.splice(noDomainCategoryEnablerIndex, 1);
          domainCategoriesOfEnablers.push('NoDomainCategory');
        }

        domainCategoriesOfEnablers.forEach((enablerDCK) => {
          const analysisEnablers: GripAnalysisEnabler[] =
            barrierEnablers[enablerDCK];
          let enablerDomainCategory = {
            isAdded: true,
            index: enablerRowIndex,
          };
          let enablerCategorySpan = 0;
          let enablerColSpan = enablerDCK === 'NoDomainCategory' ? 2 : 1;
          const enablerColIndex = enablerDCK === 'NoDomainCategory' ? 3 : 4;

          analysisEnablers.forEach((analysisEnabler: GripAnalysisEnabler) => {
            let enablerRowSpan: number = 0;
            const enablerStratergies =
              strategies[`enabler_${analysisEnabler.id}`];

            const domainCategoriesOfStratergies = Object.keys(
              enablerStratergies,
            );
            /** Find NoDomainCategory index and add them at the end of the object */
            const noDomainCategoryStrategyIndex = domainCategoriesOfStratergies.findIndex(
              (value) => value === 'NoDomainCategory',
            );
            if (noDomainCategoryStrategyIndex >= 0) {
              domainCategoriesOfStratergies.splice(
                noDomainCategoryStrategyIndex,
                1,
              );
              domainCategoriesOfStratergies.push('NoDomainCategory');
            }

            /**
             * if enabler have startegy(ies) add enablers,
             * else add no data column
             */
            if (
              strategies[`enabler_${analysisEnabler.id}`] &&
              Object.keys(strategies[`enabler_${analysisEnabler.id}`]).length >
                0
            ) {
              domainCategoriesOfStratergies.forEach((strategyDCK) => {
                const analysisStratergies: GripAnalysisStrategy[] =
                  enablerStratergies[strategyDCK];

                const strategyColSpan =
                  strategyDCK === 'NoDomainCategory' ? 2 : 1;

                const strategyColIndex =
                  strategyDCK === 'NoDomainCategory' ? 5 : 6;

                if (strategyDCK !== 'NoDomainCategory') {
                  /**  Add domain category */
                  tableContent.table.body[strategyRowIndex].splice(
                    5,
                    1,
                    getVerticalText(strategyDCK, {
                      fillColor: GripExportColors.lightBlue,
                      alignment: 'center',
                      rowSpan: analysisStratergies.length,
                      fontSize: GripExportSize.secondary,
                    }),
                  );
                }

                /**  add startegy(ies) data */
                analysisStratergies.forEach(
                  (analysisStrategy: GripAnalysisStrategy) => {
                    tableContent.table.body[strategyRowIndex].splice(
                      strategyColIndex,
                      1,
                      {
                        text: analysisStrategy.title,
                        margin: [5, 5],
                        colSpan: strategyColSpan,
                        rowSpan: 1,
                        fontSize: GripExportSize.secondary,
                      },
                    );
                    strategyRowIndex += 1;
                    enablerRowSpan += 1;
                  },
                );
              });

              /**  add enabler data */
              tableContent.table.body[enablerRowIndex].splice(
                enablerColIndex,
                1,
                {
                  text: analysisEnabler.title,
                  margin: [5, 5],
                  rowSpan: enablerRowSpan ? enablerRowSpan : 1,
                  colSpan: enablerColSpan,
                  fontSize: GripExportSize.secondary,
                },
              );
              enablerRowIndex += enablerRowSpan ? enablerRowSpan : 1;
              enablerCategorySpan += enablerRowSpan ? enablerRowSpan : 1;
              barrierRowSpan += enablerRowSpan ? enablerRowSpan : 1;
            } else {
              /** add enabler data  */
              tableContent.table.body[enablerRowIndex].splice(
                enablerColIndex,
                1,
                {
                  text: analysisEnabler.title,
                  margin: [5, 5],
                  rowSpan: enablerRowSpan ? enablerRowSpan : 1,
                  colSpan: enablerColSpan,
                  fontSize: GripExportSize.secondary,
                },
              );
              enablerRowIndex += enablerRowSpan ? enablerRowSpan : 1;
              enablerCategorySpan += enablerRowSpan ? enablerRowSpan : 1;
              barrierRowSpan += enablerRowSpan ? enablerRowSpan : 1;

              /** add empty data */
              tableContent.table.body[strategyRowIndex].splice(5, 1, {
                text: 'No Strategy Included.',
                margin: [5, 5],
                colSpan: 2,
                fontSize: GripExportSize.secondary,
              });
              strategyRowIndex += 1;
            }
          });

          if (enablerDCK !== 'NoDomainCategory') {
            /** Add domain category */
            tableContent.table.body[enablerDomainCategory.index].splice(
              3,
              1,
              getVerticalText(enablerDCK, {
                fillColor: GripExportColors.lightBlue,
                alignment: 'center',
                rowSpan: enablerCategorySpan ? enablerCategorySpan : 1,
                fontSize: GripExportSize.secondary,
              }),
            );
          }
        });
        /** add barrier data */
        tableContent.table.body[barrierRowIndex].splice(barrierColIndex, 1, {
          text: analysisBarrier.title,
          margin: [5, 5],
          rowSpan: barrierRowSpan ? barrierRowSpan : 1,
          colSpan: barrierColSpan,
          fontSize: GripExportSize.secondary,
        });
        barrierRowIndex += barrierRowSpan ? barrierRowSpan : 1;
        barrierCategorySpan += barrierRowSpan ? barrierRowSpan : 1;
      } else {
        /** add barrier data */
        let barrierColData = analysisBarrier.action
          ? [analysisBarrier.title]
          : [
              { text: analysisBarrier.title },
              { text: '\nNot to be actioned.', bold: true },
              {
                text: [
                  { text: '\nJustification: ', bold: true },
                  `${analysisBarrier.reason}`,
                ],
              },
            ];

        tableContent.table.body[barrierRowIndex].splice(barrierColIndex, 1, {
          text: barrierColData,
          margin: [5, 5],
          rowSpan: barrierRowSpan ? barrierRowSpan : 1,
          colSpan: barrierColSpan,
          fontSize: GripExportSize.secondary,
        });
        barrierRowIndex += barrierRowSpan ? barrierRowSpan : 1;
        barrierCategorySpan += barrierRowSpan ? barrierRowSpan : 1;

        /** add empty data */
        tableContent.table.body[enablerRowIndex].splice(3, 1, {
          text: 'No Enabler Included.',
          margin: [5, 5],
          colSpan: 2,
          fontSize: GripExportSize.secondary,
        });
        enablerRowIndex += 1;

        tableContent.table.body[strategyRowIndex].splice(5, 1, {
          text: 'No Strategy Included.',
          margin: [5, 5],
          colSpan: 2,
          fontSize: GripExportSize.secondary,
        });
        strategyRowIndex += 1;
      }
    });

    if (barrierDCK !== 'NoDomainCategory') {
      /** add domain category */
      tableContent.table.body[barrierDomainCategory.index].splice(
        1,
        1,
        getVerticalText(barrierDCK, {
          fillColor: GripExportColors.lightBlue,
          alignment: 'center',
          rowSpan: barrierCategorySpan ? barrierCategorySpan : 1,
          fontSize: GripExportSize.secondary,
        }),
      );
    }
  });

  return tableContent;
}

function setLineDivider(): PdfDividerLine {
  return {
    canvas: [
      {
        type: 'line',
        x1: 0,
        y1: 0,
        x2: 515,
        y2: 0,
        lineWidth: 1,
        lineColor: GripExportColors.lightGray,
      },
    ],
    margin: [0, 10],
  };
}

function setLabel(label: string): PdfContentStyle {
  return {
    text: label,
    color: GripExportColors.secondary,
    fontSize: GripExportSize.secondary,
    bold: true,
    margin: [0, 0, 0, 2],
  };
}

function setValue(value: string): PdfContentStyle {
  return {
    text: value ? value : 'No data entered',
    color: value ? GripExportColors.darkGray : GripExportColors.gray,
    fontSize: GripExportSize.secondary,
    margin: [0, 0, 0, 10],
  };
}

function setCriteriaTitle(title: string, margin: number[]): PdfContentStyle {
  return {
    text: title,
    color: GripExportColors.secondary,
    fontSize: GripExportSize.titleHeader,
    bold: true,
    margin,
  };
}

function setContentTitle(title: string, margin: number[]): PdfContentStyle {
  return {
    text: title,
    color: GripExportColors.darkGray,
    fontSize: GripExportSize.titleHeader,
    bold: true,
    margin,
  };
}

function setLink(link: string, margin: number[]): PdfContentStyle {
  return {
    text: link,
    link: link,
    fontSize: GripExportSize.secondary,
    color: GripExportColors.secondary,
    margin,
  };
}

function setAttachment(attachment: string, margin: number[]): PdfContentStyle {
  const fileName: string = attachment.substring(105);
  return {
    text: fileName,
    link: attachment,
    fontSize: GripExportSize.secondary,
    color: GripExportColors.secondary,
    margin,
  };
}

function setContentDetails(
  label: string,
  value: string,
  margin: number[],
): PdfContentStyle {
  return {
    text: [
      {
        text: `${label} `,
        bold: true,
      },
      value,
    ],
    color: GripExportColors.darkGray,
    fontSize: GripExportSize.secondary,
    margin,
  };
}

function getVerticalText(text: string, style: any) {
  // I am using predefined dimensions so either make this part of the arguments or change at will
  const rowSpan = style.rowSpan;
  const splitBy = rowSpan > 2 ? 50 : 25;
  const myArray = [];
  for (let i = 0; i < Math.ceil(text.length / splitBy); i++) {
    myArray.push(text.substr(i * splitBy, splitBy));
  }

  let canvas = document.createElement('canvas');
  canvas.width = myArray.length * 100;
  canvas.height = 300;
  const ctx = canvas.getContext('2d');

  const txtCount = text.length;
  const heightBottom = (300 + txtCount) / 2 + txtCount * 3.5;

  if (ctx) {
    ctx.font = '18pt Open Sans';
    ctx.save();
    ctx.rotate(-0.5 * Math.PI);

    myArray.forEach((txt, index) => {
      const left =
        myArray.length === 1
          ? 60 + myArray.length * index * 10
          : 100 + myArray.length * index * 10;
      ctx.fillText(txt, -heightBottom, left);
    });
    ctx.restore();
  }

  return {
    image: canvas.toDataURL(),
    fit: [100, 100],
    ...style,
  };
}
