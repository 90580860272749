

































































































































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import BaseDropDown from '@/components/base/BaseDropDown.vue';
import { ProjectParticipantDTO } from '@/store/modules/projects/types/projects.types';
import { Site } from '@/store/modules/site/types/site.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { RootState } from '@/store/store';
import { ToastProgrammatic as Toast } from 'buefy';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  CreateAuditParticipantPayload,
  UpdateAuditParticipantPayload,
  AuditParticipantSiteStatus,
  AuditParticipantDataTrfObj,
} from '../../../store/modules/audits/types/audits.types';
import BaseDialog from '@/components/base/BaseDialog.vue';
import { ApiState } from '@/store/types/general.types';
import { cloneData } from '@/utils/general.util';

@Component({
  components: {
    BaseDropDown,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class AuditParticipantForm extends Vue {
  @Prop() public participant!: AuditParticipantDataTrfObj;
  @Prop({ required: true }) public isEdit!: boolean;
  @Prop() public sites!: Site[];
  @Prop() public projectOwnerEmail!: string;
  @Prop() public participantsList!: AuditParticipantDataTrfObj[];

  @Action('audits/getParticipants')
  public getParticipants!: (auditId: number) => void;

  @Action('audits/createParticipant')
  public createParticipant!: (payload: CreateAuditParticipantPayload) => void;

  @Action('audits/updateParticipant')
  public updateParticipant!: (payload: UpdateAuditParticipantPayload) => void;

  public isSameProjectOwnerEmail: boolean = false;
  public isSameParticipantEmail: boolean = false;
  public form = {
    userEmail: '',
    participantRole: null as any,
    participantSites: [] as Site[],
  };

  get auditId() {
    return +this.$route.params.auditId;
  }

  get roleOptions() {
    return [{ label: 'Data Collector', value: 'Data Collector' }];
  }

  get siteOptions() {
    if (Array.isArray(this.sites)) {
      return this.sites;
    }
    return [];
  }

  get displaySelectedSiteName() {
    if (this.form.participantSites.length > 0) {
      const siteNames: Site[] = [];
      this.form.participantSites.map((site: Site) => {
        const selectedAuditSite = this.siteOptions.find(
          (option: Site) => site.id === option.id,
        );
        if (selectedAuditSite) {
          siteNames.push(selectedAuditSite);
        }
      });
      if (siteNames) {
        this.selectedSiteNames = this.convertArrayItemIntoString(siteNames);
      }
      const selectedSiteName =
        this.selectedSiteNames.length > 65
          ? this.selectedSiteNames.substring(0, 65) + '...'
          : this.selectedSiteNames;
      return this.checkedAll ? 'All Sites' : selectedSiteName;
    } else {
      return 'Select Sites';
    }
  }

  public checkedAll = false;
  public selectedSiteNames: string = '';

  public mounted() {
    if (this.participant) {
      const participant: AuditParticipantDataTrfObj = cloneData(
        this.participant,
      );
      const { userEmail, participantRole, participantSites } = participant;

      this.form.userEmail = userEmail;
      this.form.participantRole =
        this.roleOptions.find(({ value }) => value === participantRole) || null;

      const data: Site[] = [];
      participantSites.forEach((participantSite) => {
        if (!participantSite.isDeleted) {
          const site = this.siteOptions.find(
            (siteOption) => siteOption.id === participantSite.site.id,
          );
          if (site) {
            data.push(site);
          }
        }
      });
      this.form.participantSites = data;
    } else {
      // this.form.participantSites = cloneData(this.siteOptions);
      this.form.participantRole = this.roleOptions[0];
    }
  }

  public handleCheckAll(value: boolean) {
    this.checkedAll = value;
    this.form.participantSites = [];
    if (value) {
      this.form.participantSites = cloneData(this.siteOptions);
    }
  }

  public async handleSubmit() {
    if (this.isSameProjectOwnerEmail || this.isSameParticipantEmail) {
      const errorMessage = this.isSameProjectOwnerEmail
        ? 'project owner'
        : 'participant';
      this.$buefy.modal.open({
        parent: this,
        props: {
          title: 'Validation Error!',
          content: `User email cannot be same as ${errorMessage} email.`,
          confirmButton: false,
          cancelButtonText: 'Close',
          cancelButton: true,
        },
        events: {},
        component: BaseDialog,
        hasModalCard: true,
      });
    } else if (this.isEdit) {
      this.$emit('close');
      this.updateParticipant({
        auditId: this.auditId,
        id: this.participant.id,
        userEmail: this.form.userEmail,
        // TODO: Assign the correct value for participant role
        participantRole: this.form.participantRole.value,
        participantSites: this.form.participantSites.map(
          (participantSite: Site) => participantSite.id,
        ),
        projectOwnerEmail: this.projectOwnerEmail,
        participantRoleId: this.participant.participantRoleId,
      });
    } else {
      this.$emit('close');
      this.createParticipant({
        auditId: this.auditId,
        userEmail: this.form.userEmail,
        participantRole: this.form.participantRole.value,
        participantSites: this.form.participantSites.map(
          (participantSite: Site) => participantSite.id,
        ),
        projectOwnerEmail: this.projectOwnerEmail,
      });
    }
  }

  public convertArrayItemIntoString(data: any[]) {
    const values = data.map((item: any) => `${item.name}`).join(', ');
    return `${values}`;
  }

  @Watch('form.participantSites', { deep: true })
  public onCheck(value: any[]) {
    this.checkedAll = false;
    if (
      value
        .map(({ id }) => id)
        .sort()
        .toString() ===
      this.siteOptions
        .map(({ id }) => id)
        .sort()
        .toString()
    ) {
      this.checkedAll = true;
    }
  }

  @Watch('form.userEmail', { deep: true })
  public onCheckUserEmail(value: string) {
    let sameParticipantEmail: AuditParticipantDataTrfObj | undefined;
    if (this.participantsList) {
      sameParticipantEmail = this.participantsList.find(
        (data: AuditParticipantDataTrfObj) => data.userEmail === value,
      );
    }
    switch (value) {
      case this.projectOwnerEmail:
        this.isSameProjectOwnerEmail = true;
        break;
      case sameParticipantEmail && sameParticipantEmail.userEmail:
        this.isSameParticipantEmail = true;
        break;
      default:
        this.isSameParticipantEmail = false;
        this.isSameProjectOwnerEmail = false;
    }
  }
}
