

































import { Component, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import HeroBar from '@/components/base/HeroBar.vue';
import AuditListDataCollector from '@/views/Project/components/dataCollector/AuditListDataCollector.vue';
import { Action, State } from 'vuex-class';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { sortBy as _sortBy, filter as _filter } from 'lodash';
import moment from 'moment';
import { AuditStatus } from '@/store/modules/audits/types/audits.types';
import { useUserEmail } from '@/utils/user.util';
import { PacesRoles } from '@/store/types/general.types';
import Card from '@/components/Card.vue';
import {
  ParticipantRoles,
  ProjectDetailPayload,
  ProjectParticipantDTO,
  ProjectParticipantRole,
  ProjectParticipantSiteMap,
} from '../../../store/modules/projects/types/projects.types';
import {
  AuditParticipantDTO,
  AuditParticipantRole,
  AuditParticipantSiteMap,
} from '../../../store/modules/audits/types/audits.types';

@Component({
  components: {
    DefaultLayout,
    HeroBar,
    AuditListDataCollector,
    Card,
  },
})
export default class ProjectDetailPageDataCollector extends Vue {
  public isAuditDeleted: boolean = false;
  public isDataCollectionArchived: boolean = false;

  @Action('projects/getProjectDetail')
  public getProjectDetail!: (projectId: number) => void;

  @State((state) => state.projects.projectDetail)
  public projectDetail!: ProjectDetailPayload;

  public mounted() {
    this.getProjectDetail(this.projectId);
  }

  @Watch('projectDetail')
  @isTruthy
  public watchProjectDetails(value: any) {
    if (value.status === 'Deleted' || !this.checkUserAccess(value)) {
      this.$router.push({
        name: 'project-me',
      });
    } else if (value.status === AuditStatus.ARCHIVED) {
      this.isDataCollectionArchived = true;
    } else {
      this.isDataCollectionArchived = false;
    }
  }

  public checkUserAccess(project: ProjectDetailPayload) {
    const userEmail = this.userEmail;
    if (project.user.oicPayload.email === userEmail) {
      return false;
    } else {
      const currentUser:
        | ProjectParticipantDTO
        | undefined = project.participants.find(
        (participant: ProjectParticipantDTO) => {
          return (
            participant.userEmail === userEmail &&
            !participant.isDeleted &&
            participant.participantRoles.find(
              (role: ProjectParticipantRole) =>
                !role.isDeleted &&
                role.role === ParticipantRoles.dataCollector &&
                role.participantSites.some(
                  (participantSite: ProjectParticipantSiteMap) =>
                    !participantSite.isDeleted &&
                    participantSite.invite.acceptedAt,
                ),
            )
          );
        },
      );
      if (currentUser) {
        return true;
      } else {
        let count: number = 0;
        if (project.audits) {
          project.audits.map((audit) => {
            const auditParticipant: AuditParticipantDTO = audit.auditParticipants.find(
              (participant: AuditParticipantDTO) => {
                return (
                  participant.userEmail === userEmail &&
                  !participant.isDeleted &&
                  participant.auditParticipantRoles.find(
                    (role: AuditParticipantRole) =>
                      !role.isDeleted &&
                      role.auditParticipantSites.some(
                        (participantSite: AuditParticipantSiteMap) =>
                          !participantSite.isDeleted &&
                          participantSite.invite.acceptedAt,
                      ),
                  )
                );
              },
            );
            if (auditParticipant) {
              count += 1;
            }
          });
        }
        if (count !== 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  get message() {
    if (this.isDataCollectionArchived) {
      return 'This project has been archived';
    } else {
      return 'Click on the Data Collection Period title to submit a new record';
    }
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  get activeAuditList() {
    const filteredAudit = _filter(this.projectDetail.audits, (auditDetail) => {
      if (auditDetail.status === AuditStatus.DELETED) {
        this.isAuditDeleted = true;
      }
      const participantDetails =
        auditDetail && auditDetail.auditParticipants
          ? auditDetail.auditParticipants.find((participant: any) => {
              return (
                participant.userEmail === this.userEmail &&
                participant.auditParticipantRoles.find(
                  (participantRole: any) =>
                    participantRole.role === PacesRoles.DataCollector,
                )
              );
            })
          : undefined;
      return (
        auditDetail.status !== AuditStatus.DELETED &&
        participantDetails &&
        auditDetail.status === AuditStatus.ACTIVE
      );
    });
    return _sortBy(filteredAudit, (auditDetail) => {
      if (moment(auditDetail.startDate).isAfter(new Date())) {
        auditDetail.isFutureAudit = true;
      } else {
        auditDetail.isFutureAudit = false;
      }
      return auditDetail.startDate ? new Date(auditDetail.startDate) : null;
    });
  }

  get archivedAuditList() {
    const filteredAudit = _filter(this.projectDetail.audits, (auditDetail) => {
      if (auditDetail.status === AuditStatus.DELETED) {
        this.isAuditDeleted = true;
      }
      const isParticipant = auditDetail.auditParticipants.find(
        (participant: any) => {
          return (
            participant.userEmail === this.userEmail &&
            participant.participantRole === PacesRoles.DataCollector
          );
        },
      );
      return (
        auditDetail.status !== AuditStatus.DELETED &&
        isParticipant &&
        auditDetail.status === AuditStatus.ARCHIVED
      );
    });
    return _sortBy(filteredAudit, (auditDetail) => {
      if (moment(auditDetail.startDate).isAfter(new Date())) {
        auditDetail.isFutureAudit = true;
      } else {
        auditDetail.isFutureAudit = false;
      }
      return auditDetail.startDate ? new Date(auditDetail.startDate) : null;
    });
  }
}
