import { ApiState, Pagination } from '@/store/types/general.types';
import { CRITERION_TYPE } from '@/store/types/criterions.types';
import {
  AuditDetail,
  AuditGripBarrierModalPayload,
  AuditGripEnablerModalPayload,
  AuditGripStrategyModalPayload,
} from '../../audits/types/audits.types';
import { HyperlinkType } from 'docx';
import {
  IExternalHyperlinkDefinition,
  IInternalHyperlinkDefinition,
} from 'docx/build/file/core-properties';
import { CanvasLine, Content } from 'pdfmake/interfaces';
import {
  Grip,
  GripAnalysis,
  GripAnalysisBarrier,
  GripAnalysisEnabler,
  GripAnalysisStrategy,
  GripBarrier,
  GripDomainCategory,
  GripDomainCategoryDetails,
  GripEnabler,
  GripExportReportResponse,
  GripNotificationResponse,
  GripStrategy,
} from '../../../types/grip.types';
import { CriteriaContent } from '@/jbi-shared/types/paces-project.types';
import { OicPayload } from '../../auth/types/auth.types';
import { UserSubscriptionStatus } from '@/jbi-shared/types/jaas-oauth2.types';
import { EventPayload } from '@/jbi-shared/types/paces-event-log.types';

export interface ProjectsApiState {
  getCplusCriterias: ApiState;
  createProject: ApiState;
  getMyProjects: ApiState;
  getProjectDetail: ApiState;
  updateProjectStatus: ApiState;
  getProjectParticipants: ApiState;
  getProjectParticipant: ApiState;
  createProjectParticipant: ApiState;
  updateProjectParticipant: ApiState;
  deleteProjectParticipant: ApiState;
  getSites: ApiState;
  createSite: ApiState;
  acceptInvitation: ApiState;
  updateCriteria: ApiState;
  updateProjectCriterias: ApiState;
  updateProjectSites: ApiState;
  updateProjectParticipants: ApiState;
  updateProjectSetting: ApiState;
  updateProjectGeneralSettings: ApiState;
  getDashboardChartData: ApiState;
  getMyProjectsSummary: ApiState;
  getDataCollectionActivityLogs: ApiState;
  getProjectCriteria: ApiState;
  getProjectSites: ApiState;
  getProjectAuditList: ApiState;
  getRecentEventLogs: ApiState;
  getPaginatedEventLogs: ApiState;
  getProjectAuditDataCollectionRecords: ApiState;
  getDocumentByPublication: ApiState;
  getUpdatedJBICriteriaListWithAcknowledgment: ApiState;
  getUpdatedProjectReportJBICriteriaList: ApiState;
  getUpdateJBICriteriaAcknowledgment: ApiState;
  updateJBICriteria: ApiState;
  getProjectListForAdmin: ApiState;
  getProjectParticipantsForAdmin: ApiState;
  getProjectSitesForAdmin: ApiState;
  getExistingProjectOwnerRequest: ApiState;
  changeProjectOwnerRequest: ApiState;
  updateProjectOwnerRequest: ApiState;
  updateCancelProjectRequest: ApiState;
  getProjectOwnerHistory: ApiState;
  getActiveProjects: ApiState;
  getCreatedProjects: ApiState;
  getDraftProjects: ApiState;
  getArchiveProjects: ApiState;
  addNewAudit: ApiState;
  getAuditGripType: ApiState;
  createAuditGripType: ApiState;
  updateAuditGripType: ApiState;
  getGripTypeDetail: ApiState;
  deleteAuditGripType: ApiState;
  getProjectDataCollectionRecordExport: ApiState;
  getProjectReportExport: ApiState;
  getProjectReportAnalysis: ApiState;
  getTotalProjectReportAnalysis: ApiState;
  updateProjectReportAnalysis: ApiState;
  deleteProjectReportAnalysis: ApiState;
  getProjectGrip: ApiState;
  updateProjectGrip: ApiState;
  createProjectGrip: ApiState;
  getProjectGripBarriers: ApiState;
  createProjectGripBarrier: ApiState;
  updateProjectGripBarrier: ApiState;
  deletedProjectGripBarrier: ApiState;
  getProjectGripEnablers: ApiState;
  createProjectGripEnabler: ApiState;
  updateProjectGripEnabler: ApiState;
  deletedProjectGripEnabler: ApiState;
  getProjectGripStrategies: ApiState;
  createProjectGripStrategy: ApiState;
  updateProjectGripStrategy: ApiState;
  deletedProjectGripStrategy: ApiState;
  updateProjectCriteriaById: ApiState;
  getGripDomainCategories: ApiState;
  getGripDomainCategory: ApiState;
  verifyGripDomainCategory: ApiState;
  createGripDomainCategory: ApiState;
  updateGripDomainCategory: ApiState;
  deleteGripDomainCategory: ApiState;
  getGripBarriers: ApiState;
  createGripBarrier: ApiState;
  updateGripBarrier: ApiState;
  deleteGripBarrier: ApiState;
  getGripEnablers: ApiState;
  createGripEnabler: ApiState;
  updateGripEnabler: ApiState;
  deleteGripEnabler: ApiState;
  getGripStrategies: ApiState;
  createGripStrategy: ApiState;
  updateGripStrategy: ApiState;
  deleteGripStrategy: ApiState;
  getGripDetails: ApiState;
  createGripDetails: ApiState;
  updateGripDetails: ApiState;
  getGripAnalysisList: ApiState;
  getFilteredGripAnalysisList: ApiState;
  getGripAnalysis: ApiState;
  createGripAnalysis: ApiState;
  renameGripAnalysis: ApiState;
  updateGripAnalysis: ApiState;
  deleteGripAnalysis: ApiState;
  createGripAnalysisBarrier: ApiState;
  updateGripAnalysisBarrier: ApiState;
  deleteGripAnalysisBarrier: ApiState;
  createGripAnalysisEnabler: ApiState;
  updateGripAnalysisEnabler: ApiState;
  deleteGripAnalysisEnabler: ApiState;
  createGripAnalysisStrategy: ApiState;
  updateGripAnalysisStrategy: ApiState;
  deleteGripAnalysisStrategy: ApiState;
  getGripNotifications: ApiState;
  updateGripNotifications: ApiState;
  getGripExportReport: ApiState;
}

export interface ProjectsState {
  apiState: ProjectsApiState;
  cplusCriterias: any;
  createdProject: CreateProjectResponsePayload | undefined;
  projects: ProjectDetailPayload[] | undefined;
  projectDetail: ProjectDetailPayload | undefined;
  updatedProjectStatus: any | undefined;
  participants: ProjectParticipantDTO[] | undefined;
  sites: Site[] | undefined;
  createdSite: Site | undefined;
  acceptedInvitedProject: AcceptInvitationResponsePayload | undefined;
  updatedCriteria: CriteriaResponsePayload | undefined;
  updatedProjectCriterias: CriteriaResponsePayload[] | undefined;
  updatedProjectSites: Site[] | undefined;
  updatedProjectParticipants: Participant[] | undefined;
  updatedProjectSetting: ProjectSettingResponsePayload | undefined;
  updatedProjectGeneralSettings: any | undefined;
  dashboardChartData: DashboardChartData[] | undefined;
  myProjectsSummary: ProjectDetailPayload[] | undefined;
  dataCollectionActivityLogs: any[] | undefined;
  projectCriteria: ProjectCriteria[] | undefined;
  projectSites: ProjectSite[] | undefined;
  projectParticipants: ProjectParticipantDTO[] | undefined;
  projectAuditList: AuditDetail[] | undefined;
  recentEventLogs: any[] | undefined;
  paginatedEventLogs: PaginatedProjectEventLogsResponse | undefined;
  projectAuditDataCollectionRecords:
    | ProjectAuditDataCollectionRecordsPayload
    | undefined;
  documentByPublication: DocumentByPublication | undefined;
  projectOwnerRequestPayload: ProjectOwnerRequestPayload | undefined;
  updatedJBICriteriaListWithAcknowledgment:
    | UpdatedJBICriteriaAcknowledgement
    | undefined;
  updatedProjectReportJBICriteriaList: JBICriteriaDTO[] | undefined;
  updatedJBICriteriaAcknowledgment: any | undefined;
  updatedJbiCriteria: any | undefined;
  projectName: any | undefined;
  activeListProject: ProjectListPagePayload[] | undefined;
  createdListProject: ProjectListPagePayload[] | undefined;
  draftListProject: ProjectListPagePayload[] | undefined;
  archiveListProject: ProjectListPagePayload[] | undefined;
  addedNewAudit: AuditDetail | AuditDetail[] | undefined;
  auditGripType: AuditGripTypePayload[] | undefined;
  createdAuditGripType: AuditGripTypePayload | undefined;
  updatedAuditGripType: AuditGripTypePayload | undefined;
  auditGripTypeDetail: AuditGripTypePayload | undefined;
  deletedAuditGripType: AuditGripTypePayload | undefined;
  projectDataCollectionRecordExport:
    | ProjectDataCollectionRecordExportPayload
    | undefined;
  projectReportExport: any | undefined;
  projectReportAnalysis: any | undefined;
  totalProjectReportAnalysis: ProjectReportAnalysis[] | undefined;
  updatedProjectReportAnalysis: any | undefined;
  deletedProjectReportAnalysis: any | undefined;
  projectGrip: ProjectGripDetails | undefined;
  updateProjectGrip: ProjectGrip | undefined;
  createProjectGrip: ProjectGrip | undefined;
  projectGripBarriers: ProjectGripBarrier[] | undefined;
  createProjectGripBarrier: ProjectGripBarrier | undefined;
  updateProjectGripBarrier: ProjectGripBarrier | undefined;
  deletedProjectGripBarrier: undefined;
  projectGripEnablers: ProjectGripEnabler[] | undefined;
  createProjectGripEnabler: ProjectGripEnabler | undefined;
  updateProjectGripEnabler: ProjectGripEnabler | undefined;
  deletedProjectGripEnabler: undefined;
  projectGripStrategies: ProjectGripStrategy[] | undefined;
  createProjectGripStrategy: ProjectGripStrategy | undefined;
  updateProjectGripStrategy: ProjectGripStrategy | undefined;
  deletedProjectGripStrategy: undefined;
  updatedProjectCriteriaById: CriteriaResponsePayload | undefined;
  gripDomainCategories: GripDomainCategory[] | undefined;
  gripDomainCategory: GripDomainCategoryDetails | undefined;
  verifiedGripDomainCategory: boolean | undefined;
  createdGripDomainCategory: GripDomainCategory | undefined;
  updatedGripDomainCategory: GripDomainCategory | undefined;
  deletedGripDomainCategory: void | undefined;
  gripBarriers: Pagination<GripBarrier[]> | undefined;
  createdGripBarrier: GripBarrier | undefined;
  updatedGripBarrier: any | undefined;
  deletedGripBarrier: void | undefined;
  gripEnablers: Pagination<GripEnabler[]> | undefined;
  createdGripEnabler: GripEnabler | undefined;
  updatedGripEnabler: any | undefined;
  deletedGripEnabler: void | undefined;
  gripStrategies: Pagination<GripStrategy[]> | undefined;
  createdGripStrategy: GripStrategy | undefined;
  updatedGripStrategy: any | undefined;
  deletedGripStrategy: void | undefined;
  gripDetails: Grip | undefined;
  createdGripDetails: Grip | undefined;
  updatedGripDetails: Grip | undefined;
  gripAnalysisList: GripAnalysis[] | undefined;
  filteredGripAnalysisList: Pagination<GripAnalysis> | undefined;
  gripAnalysis: GripAnalysis | undefined;
  createdGripAnalysis: GripAnalysis | undefined;
  renamedGripAnalysis: GripAnalysis | undefined;
  updatedGripAnalysis: GripAnalysis | undefined;
  deletedGripAnalysis: void | undefined;
  createdGripAnalysisBarrier: GripAnalysisBarrier | undefined;
  updatedGripAnalysisBarrier: any | undefined;
  deletedGripAnalysisBarrier: void | undefined;
  createdGripAnalysisEnabler: GripAnalysisEnabler | undefined;
  updatedGripAnalysisEnabler: any | undefined;
  deletedGripAnalysisEnabler: void | undefined;
  createdGripAnalysisStrategy: GripAnalysisStrategy | undefined;
  updatedGripAnalysisStrategy: any | undefined;
  deletedGripAnalysisStrategy: void | undefined;
  gripNotifications: GripNotificationResponse[] | undefined;
  updatedGripNotifications: any | undefined;
  gripExportReport: GripExportReportResponse | undefined;
  administratorProjectList: AdministratorProjectListDTO | undefined;
  projectParticipantsForAdmin: ProjectParticipantDTO[] | undefined;
  projectSitesForAdmin: ProjectSite[] | undefined;
  existingProjectOwnerRequest: ExistingProjectOwnerRequestPayload | undefined;
  projectOwnerHistory: ProjectOwnerHistoryPayload | undefined;
}

export interface GetCplusCriteriaRequestPayload {
  search?: string;
  page?: number;
  perPage?: number;
}

export interface CplusCriteriaResults {
  total_count: number;
  incomplete_results: boolean;
  items: CplusCriteriaItem[];
}

export interface CplusCriteriaItem {
  documentId: string;
  document: CplusCriteriaDocument;
  criteria: JBICriterion[];
}

export interface CplusCriteriaDocument {
  id: string;
  title: string;
  description?: string;
  link?: string;
}

export type JBICriterion =
  | JBICriterionTypeBoolean
  | JBICriterionTypeRange
  | JBICriterionTypeCheckboxes;

export interface JBICriterionContent {
  bestPracticeRecommendations: any[];
  count: number;
  document: CplusCriteriaDocument;
  id: string;
  tags: {
    mesh: string[];
    plain: string[];
  };
  type: CRITERION_TYPE;
  title: string;
}

export interface JBICriterionTypeBoolean extends JBICriterionContent {
  booleanOptions: string[];
}

export interface JBICriterionTypeRange extends JBICriterionContent {
  range: string[];
}

export interface JBICriterionTypeCheckboxes extends JBICriterionContent {
  checkboxesOptions: string[];
}

export interface CplusCriteria {
  id: string;
  criteriaDetails?: any;
  order: number;
  title: string;
  type: CRITERION_TYPE;
  criteriaOptions: string[] | number[];
  document: CplusCriteriaDocument;
  documentId?: string;
  tags: any;
  bestPracticeRecommendations: string[];
  [s: string]: any;
  sourceLink?: string;
  updatedJbiCriteria?: JBICriteriaDTO;
  isChanged?: boolean;
}

export interface UpdateCriteriaDTO {
  emitCriteriaArray: any[];
  deletedCriteriaIds: number[];
}

export interface CplusCriteriaBody {
  id: string;
  title: string;
  type: string;
  document: any;
  tags: any;
  bestPracticeRecommendations: string[];
  [s: string]: any;
}

/**
 * interface used for:
 * 1. project creation
 * 2. draft creation
 * 3. draft update
 */
export interface CreateProjectRequestPayload {
  projectId?: number;
  projectTitle: string;
  auditCriterias: any;
  projectExpiry?: string;
  sites: Site[];
  participants?: ProjectParticipantDataTrfObj[];
  recurrenceSetting?: RecurrenceSettingPayload;
}

export interface CreateProjectResponsePayload {
  project: {
    id: number;
    projectTitle: string;
    status: string;
  };
}

export enum UserRoleInProject {
  isOwner = 'Project Owner',
  isDataCollector = 'Data Collector',
  isProjectCoOrdinator = 'Project Coordinator',
  noParticipation = 'No Participation',
}

export enum UserRoleInAudit {
  isOwner = 'Project Owner',
  isDataCollector = 'Data Collector',
  isProjectCoOrdinator = 'Project Coordinator',
  noParticipation = 'No Participation',
}

export enum ParticipantRoles {
  dataCollector = 'Data Collector',
  projectCoOrdinator = 'Project Coordinator',
}

export enum ProjectStatus {
  ARCHIVED = 'Archived',
  DELETED = 'Deleted',
  ACTIVE = 'Active',
  DRAFT = 'Draft',
}

export interface UserEntity {
  id: number;
  jaasSubjectIdentifier: string;
  updatedAt: Date;
  createdAt: Date;
  oicPayload: OicPayload;
  email: string;
}

export interface AdminProjectDTO extends AdminProjectData {
  administratorName: string;
  given_name: string;
  family_name: string;
  disableChangeAdministration: boolean;
  disableProjectHistory: boolean;
}

export interface AdminProjectData {
  email: string;
  expire_at: string;
  firstName: string;
  id: number;
  lastName: string;
  participant_count: string | number;
  status: ProjectStatus;
  subscriptionstatus: boolean;
  title: string;
  userId: number;
}

export interface AdministratorProjectListDTO {
  items: AdminProjectData[];
  itemCount: number;
  totalItems: number;
}
export interface ProjectDetailPayload {
  id: number;
  title: string;
  description: string;
  isDeleted: boolean;
  isArchived: boolean;
  deleteDate: string;
  archivedDate: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  criterias: any;
  projectSites: ProjectSite[];
  enableProjectGrip: boolean;
  isUserAuthorizedToAccessAllSites: boolean;
  participants: ProjectParticipantDTO[];
  audits?: any[]; // TODO: Add typebinding
  settings?: ProjectSettingResponsePayload[];
  status: string;
  user: UserEntity;
  originator: UserEntity;
  userRoleInProject?: UserRoleInProject;
}

export interface ProjectListPagePayload {
  administratorName: string;
  archivedAt: string;
  audits: any[];
  createdAt: string;
  deletedAt: string;
  description: string;
  family_name: string;
  given_name: string;
  id: number;
  originator: any;
  participants: any;
  status: string;
  title: string;
  updatedAt: string;
  user: any;
}

export interface ProjectSettingResponsePayload {
  id: number;
  expiryDate: string;
  recurrenceType: string;
  startDate: string;
  multipleRecurrenceType: string;
  createdAt: string;
  updatedAt: string;
}

export interface AcceptInvitationRequestPayload {
  token: string;
}
export type AcceptInvitationResponsePayload = ProjectDetailPayload;

export enum RecurrenceType {
  oneOff = 'One Off',
  scheduled = 'Ongoing',
  multiple = 'Audit Cycles',
}

export enum OwnerActionEnum {
  appointNewRole = 'appoint_new_role',
  removeFromProject = 'remove_from_project',
  none = 'none',
}

export enum ProjectOwnerOption {
  projectCoordinator = 'Appoint me as project coordinator',
  dataCollector = 'Appoint me as data collector',
  leaveProject = 'Remove me from the project',
}

export enum ReclaimProjectOwnership {
  projectCoordinator = 'Appoint current project administrator as project coordinator',
  dataCollector = 'Appoint current project administrator as data collector',
  leaveProject = 'Remove the current project administrator from the project',
}

export enum ProjectAdministratorOption {
  projectCoordinator = 'Appoint the previous administrator as project coordinator',
  dataCollector = 'Appoint the previous administrator as data collector',
  leaveProject = 'Remove the previous administrator from the project',
}

export interface RecurrenceSettingPayload {
  recurrenceType: string;
  scheduledRecurrenceStartDate?: any;
  scheduledRecurrenceEndDate?: any;
  multipleRecurrenceType?: string;
  auditSamplingType?: string;
  interval?: number;
  auditNumber?: number;
  enableGrip: boolean;
  multipleDataCollectionPeriod?: DataCollectionPeriodPayload[] | any;
}

export interface DataCollectionPeriodPayload {
  auditNumber?: number;
  auditName: string;
  startDate?: Date;
  endDate?: Date;
}

export enum MultipleRecurrenceFrequencyOptions {
  Daily = 'Day',
  Weekly = 'Week',
  Monthly = 'Month',
  Yearly = 'Year',
}

export interface CriteriaPayload {
  id: number;
  title: string;
  type: string;
}

export interface CriteriaResponsePayload {
  id: number;
  title: string;
  criteriaType: string;
  criteriaOptions: any;
  connectPlusId: string;
  isDefault: boolean;
  documentId: string;
  documentName: string;
  description: string;
  criteriaTags?: any;
  isDeleted: boolean;
}

// deprecate
export interface GroupedCriterias {
  documentId: string;
  criterias: CplusCriteria[];
}

export interface Participant {
  email: string;
  role: string;
  sites: Site[];
}

export interface UpdateProjectGeneralSettingRequestPayload {
  projectId: number;
  projectName: string;
  projectDescription: string;
  enableProjectGrip: boolean;
}

export interface UpdateProjectCriteriasRequestPayload {
  projectId: number;
  criterias: CplusCriteria[];
  deletedCriteriaIds?: number[];
}

export interface UpdateProjectSitesRequestPayload {
  projectId: number;
  sites: Site[];
}

export interface UpdateProjectParticipantsRequestPayload {
  projectId: number;
  participants: Participant[];
}

export interface UpdateProjectSettingRequestPayload {
  projectId: number;
  projectExpiry: any;
  recurrenceSetting: RecurrenceSettingPayload;
  settingDetails: any;
}

/**
 * Sites are always being inserted to db first
 * before being associated with other entities.
 */
export interface Site {
  id: number;
  name: string;
  address: string;
  description: string;
  isIdentifierRequired: boolean;
  createdAt?: string;
  updatedAt?: string;
}
export interface ParticipantSite {
  isDeleted: boolean;
  site: Site;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface DashboardChartDataRequestPayload {
  projectId: number;
  auditIds: number[];
  fromDate?: any;
  toDate?: any;
}

export interface DashboardChartData {
  sum: number;
  createdDate: string;
}

export interface ProjectCriteria {
  id: number;
  connectPlusId: number;
  title: string;
  isDefault: boolean;
  criteriaType: any;
  criteriaOptions: any;
  documentId: number;
  documentName: string;
  description: string;
  connectPlusLink: string;
  bestPracticeRecommendations: any;
  order: number;
  isDeleted: boolean;
}

export interface ProjectSite {
  id: number;
  order: number;
  isDeleted: boolean;
  isIdentifierRequired: boolean;
  site: Site;
  createdAt: string;
  updatedAt: string;
}

export interface ParticipantDTOForInviteList {
  userEmail: string;
  participantRole: ParticipantRoles;
  participantSites: string[];
  invite: {
    id: number;
    token: string;
    expiredAt: Date;
    acceptedAt: Date;
    updatedAt: Date;
    createdAt: Date;
  };
  isDeleted: boolean;
}

/** TODO:rename this, name has been given temporarily */
export interface ProjectParticipantDataTrfObj {
  id: number;
  userEmail: string;
  participantRole: ParticipantRoles;
  participantSites: ProjectParticipantSiteMap[];
  isDeleted: boolean;
  participantRoleId: number;
  user?: UserEntity;
}

export interface ProjectParticipantDTO {
  id: number;
  userEmail: string;
  isDeleted: boolean;
  participantRoles: ProjectParticipantRole[];
  user: UserEntity;
}

export interface ProjectParticipantRole {
  id: number;
  role: ParticipantRoles;
  isDeleted: boolean;
  participantSites: ProjectParticipantSiteMap[];
}

export interface ProjectParticipantSiteMap {
  id: number;
  isDeleted: boolean;
  site: Site;
  invite: ProjectParticipantInvite;
}

export interface ProjectParticipantInvite {
  id: number;
  token: string;
  expiredAt: Date;
  acceptedAt: Date;
  updatedAt: Date;
  createdAt: Date;
}

// this is being used in ParticipantForms
export interface ParticipantFormData {
  userEmail: string;
  participantRole: ParticipantRoles | null;
  participantSites: Site[];
  isDeleted: boolean;
  id?: number;
  participantRoleId?: number;
}

export interface CreateProjectParticipantPayload {
  participantSites: number[];
  participantRole: any;
  userEmail: string;
  projectId: number;
}

export interface UpdateProjectParticipantPayload
  extends CreateProjectParticipantPayload {
  id?: number;
  participantRoleId?: number;
}

export interface ParticipantWithSubscriptionDTO extends ProjectParticipantDTO {
  participantName: string;
  subscriptionStatus: UserSubscriptionStatus;
  isValidProjectParticipant: boolean;
}

export enum ChartType {
  verticalBarChart = 'Vertical Bar Chart',
  horizontalBarChart = 'Horizontal Bar Chart',
}

export interface PaginatedProjectEventLogsPayload {
  projectId: number;
  page: number;
  limit: number;
  fromDate?: Date;
  toDate?: Date;
}

export interface PaginatedProjectEventLogsResponse {
  itemCount: number;
  items: EventLog[];
  totalItems: number;
}

export interface EventLog {
  createdAt: string;
  eventOccurredAt: string;
  eventPayload: EventPayload;
  eventType: string;
  id: number;
  originatorId: number;
  auditId: number | null;
}
export interface UpdateAnalysisTitlePayload {
  projectId: number;
  analysisId: number;
  analysisTitle: string;
}

export interface DeleteAnalysisPayload {
  projectId: number;
  analysisId: number;
}

export interface ProjectReportPeriod {
  title: string;
  startedAt: string | null;
  endedAt: string | null;
  isAudit: boolean;
  auditIds: number[];
}

export interface ProjectReportFiltersConfig {
  filteredSites: number[];
  filteredCriterias: number[];
  selectedChartType: string;
  checkIndividualSite: boolean;
  checkIndividualCriteria: boolean;
  selectedPeriod: ProjectReportPeriod;
  checkComparison: boolean;
  comparisonPeriods: ProjectReportPeriod[];
}

export interface ProjectReportAnalysis {
  title: string;
  filtersConfiguration: ProjectReportFiltersConfig;
  analysisId: number;
}

export interface ProjectReportAnalysisResponseDto {
  result: any;
  payload: any;
  analysisTitle: string;
  jbiLogo: string;
  projectTitle: string;
  projectSites: ProjectSite[];
  projectCriteria: ProjectCriteria[];
}

export interface ChartRecordsSiteData {
  projectSiteId: number | null;
  compliance: number;
  site: any | null;
}

export interface ChartRecords {
  projectCriteriaId: number;
  criteria: any;
  data: ChartRecordsSiteData[];
}

export interface StatisticData {
  configurationData: any;
  samplingConfiguration: any;
}

export interface ProjectReportAnalysisPayload {
  filteredSites: number[];
  filteredCriterias: number[];
  selectedChartType: string;
  checkIndividualSite: boolean;
  checkIndividualCriteria: boolean;
  selectedPeriod: ProjectReportPeriod;
  checkComparison: boolean;
  comparisonPeriods: ProjectReportPeriod[];
  projectId: number;
  analysisId: number;
  analysisTitle: string;
  type: 'singleSite' | 'multiSite';
}

export interface ProjectReportSinglePeriodResponse {
  chartData: ChartRecords[];
  statisticData: StatisticData;
  success: boolean;
  analysisId: number;
}

export interface ProjectReportMultiplePeriodResponse {
  comparisonResult: ProjectReportSinglePeriodResponse[];
  success: boolean;
  analysisId: number;
}

export interface ProjectAuditDataCollectionRecordsPayload {
  projectId: number;
  auditIds: number[];
}

export interface GetDocumentByPublicationPayload {
  documentId: number;
  publicationId: number;
}

export interface DocumentByPublication {
  fileName: string;
  url: string;
}

export interface ChangeProjectOwnerPayload {
  newRequestedOwnerEmail: string;
  projectId: number;
  currentOwnerEmail: string;
  currentOwnerAction: OwnerActionEnum;
  assignedRoleForCurrentOwner: ParticipantRoles | null;
  assignedSitesForCurrentOwner: number[];
  systemAdminEmail: string | null;
  isSystemAdmin: boolean;
  isReclaimProject: boolean;
}

export interface UpdateCancelProjectRequestPayload {
  projectOwnerRequestId: number;
  projectId: number;
  isReclaimProject: boolean;
  token: string;
  isSystemAdmin: boolean;
}

export interface ChangeProjectOwnerDTO {
  isAdminNewProjectOwner: boolean;
  currentProjectOwnerEmail: string;
  newRequestedProjectOwnerEmail: string;
}

export interface ValidUserPayload {
  isValid: boolean;
}

export interface UpdateProjectOwnerRequestPayload {
  token: string;
  email: string;
  projectId: number;
  isRequestAccepted: boolean;
  reason: string | null;
}

export interface ProjectOwnerRequestPayload {
  isAccepted: boolean;
}

export interface UpdateJBICriteriaAcknowledgmentPayload {
  projectId: number;
  projectCriteriaAcknowledgement: ProjectCriteriaAcknowledgement;
  acknowledged_by: ProjectParticipantDTO;
}

export interface UpdatedJBICriteriaAcknowledgement
  extends UpdatedJBICriteriaResponse {
  projectCriteriaAcknowledgement: ProjectCriteriaAcknowledgement | null;
}

export interface UpdatedJBICriteriaResponse {
  newJBICriteria: JBICriteriaDTO[];
  updatedJBICriteria: JBICriteriaDTO[];
  deletedJBICriteria: JBICriteriaDTO[];
}

export interface JBICriteriaDTO {
  documentId: number;
  version: number;
  connectPlusDocumentId: string;
  status: JBICriteriaStatus;
  content: CriteriaContent | null;
  connectPlusCriteriaId: number;
  currentCriteria?: ProjectCriteria;
}

export interface PacesCriteriaDTO extends ProjectCriteria {
  criteriaTags: CriteriaTag[];
}

export interface CriteriaTag {
  id: number;
  criteriaId: number;
  tag: string;
  tagType: CriteriaTagType;
  createdAt: Date;
  updatedAt: Date;
}

export enum CriteriaTagType {
  plain = 'plain',
  meshTag = 'meshTag',
}

export enum JBICriteriaStatus {
  NEW = 'NEW',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}

export interface ProjectCriteriaAcknowledgement {
  id: number;
  connectPlusDocuments: number[];
  isAcknowledged: boolean;
  project: ProjectDetailPayload;
  createdDate: Date;
  updatedDate: Date;
  acknowledged_by?: Participant;
}

// TODO: remove this, not in use
export interface UpdatedJbiCriteria {
  id: number;
  connectPlusId: number;
  documentId: number;
  publicationId: number;
  projectId: number;
  isDeleted: boolean;
  isChanged: boolean;
  currentCriteria: {
    id: number;
    title: string;
    criteriaType: string;
    criteriaOptions: any;
    connectPlusId: string;
    isDefault: boolean;
    documentId: string;
    documentName: string;
    description: string;
    criteriaTags?: any;
    isDeleted: boolean;
  };
  updatedCriterion: {
    id: number;
    content: {
      type: string;
      title: string;
      criteriaOptions: string[] | number[];
    };
    createdAt: string;
    revisionId: number;
    criterionSubSectionId: number;
  };
  latestPublication: {
    revisionId: number;
    projectDocumentId: number;
    versionId: number;
    publicationId: number;
    uniquePublicationId: string;
  };
}

// TODO: remove this, not in use
export interface UpdateJbiCriteriaRequestPayload {
  criteria: UpdatedJbiCriteria;
  selection: string;
}

export interface GetExistingProjectOwnerRequestPayload {
  projectId: number;
  isSystemAdmin: boolean;
}

export interface ExistingProjectOwnerRequestPayload {
  isExistingProjectOwner: boolean;
}

export interface ProjectAdministratorDetailPayload {
  id: number;
  title: string;
  description: string;
  isDeleted: boolean;
  isArchived: boolean;
  deleteDate: string;
  archivedDate: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  status?: string;
  user?: any;
  userSubscription?: any;
}

export interface FilteredProjectPayload {
  projectName?: string;
  projectStatus?: string;
  administrator?: string;
  administratorStatus?: string;
  perPage?: number;
  page?: number;
  orderBy?: OrderBy;
}

export interface OrderBy {
  field?: string;
  sort?: string;
}

export interface GetProjectPayload {
  projectName?: string;
  projectStatus?: string;
  administrator?: string;
  administratorStatus?: string;
  orderBy?: OrderBy;
  page: number;
  limit: number;
}

export interface ProjectOwnerHistoryPayload {
  project: any;
  eventLog: any;
}

export interface GetProjectListPayload {
  search: string;
  limit: number;
  page: number;
  participantProjectOnly: boolean;
}

export interface ActiveListProjectPayload {
  id: number;
  title: string;
  description: string;
  isDeleted: boolean;
  isArchived: boolean;
  deleteDate: string;
  archivedDate: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  criterias: any;
  projectSites: any;
  settings?: ProjectSettingResponsePayload[];
  audits?: any[];
  participants: any;
  status?: string;
  user?: any;
  originator?: any;
}

export interface AuditGripTypePayload {
  id: number;
  name: string;
  isDeleted: boolean;
  barrier?: AuditGripBarrierModalPayload[];
  enabler?: AuditGripEnablerModalPayload[];
  strategy?: AuditGripStrategyModalPayload[];
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateAuditGripTypePayload {
  projectId: number;
  name: string;
}

export interface UpdateAuditGripTypePayload {
  id: number;
  projectId: number;
  name: string;
}

export interface DeleteAuditGripTypePayload {
  projectId: number;
  currentId: number;
  newId: number;
}

export interface AuditCriteriaPayload {
  title: string;
  value: string;
  comment: string;
}

// projectHeaderExport, projectSubHeaderExport and projectSubHeaderExport is an array
// with dynamic key attribute with represents the header, subheader and data record in the exported file
export interface ProjectDataCollectionRecordExportPayload {
  aggregate: {
    projectHeaderExport: any;
    projectSubHeaderExport: any;
    projectDataCollectionRecordAggregate: any;
  };
  individual: {
    projectHeaderExportIndividual: any;
    projectSubHeaderExportIndividual: any;
    projectDataCollectionRecordIndividual: any;
  };
  combined: {
    projectHeaderExport: any;
    projectSubHeaderExport: any;
    projectDataCollectionRecordInfo: any;
  };
}

export enum ProjectExportDataCollectionType {
  aggregate = 'Aggregate',
  individual = 'Individual',
  combined = 'Combined',
}

export interface ProjectGrip {
  projectLead: string;
  stakeholder: string;
  projectId: number;
  actionDate: Date;
  id?: number;
}

export interface UpdateProjectGrip {
  projectGripDetail: ProjectGrip;
  projectGripId: number;
}

export interface ProjectGripDetails {
  enableProjectGrip: boolean;
  projectTitle: string;
  projectGrip?: ProjectGrip;
}

export interface ExportProjectGrip {
  projectLead: string;
  stakeholder: string;
  actionDate: string;
  projectTitle: string;
}

export interface GripTypePayload {
  id: number;
  name: string;
  isDeleted: boolean;
  createdDate: Date;
  updatedDate: Date;
}
export interface ProjectGripBarrierPayload {
  id?: number;
  title: string;
  description: string;
  action: boolean;
  reason: string;
  projectId: number;
  links: string[];
  attachments: string[];
  criterias: number[];
  auditGripTypeId: number | null;
  gripId?: number;
}

export interface ProjectGripEnablerPayload {
  id?: number;
  title: string;
  description: string;
  action: boolean;
  reason: string;
  projectId: number;
  links: string[];
  attachments: string[];
  criterias: number[];
  auditGripTypeId: number | null;
  gripId?: number;
}

export interface ProjectGripStrategyPayload {
  id?: number;
  title: string;
  description: string;
  method: string;
  resource: string;
  projectId: number;
  links: string[];
  attachments: string[];
  auditGripTypeId: number | null;
  strategyBarrier: StrategyBarrierPayload[];
  strategyEnabler: StrategyEnablerPayload[];
  gripId?: number;
}

export interface StrategyBarrierPayload {
  barrierId: number;
  isDeleted: boolean;
}

export interface StrategyEnablerPayload {
  enablerId: number;
  isDeleted: boolean;
}

export type ProjectGripCardItemList =
  | ProjectGripBarrier
  | ProjectGripEnabler
  | ProjectGripStrategy;

export interface ProjectGripBarrier {
  id?: number | undefined;
  title: string;
  description: string;
  action: boolean;
  reason: string;
  filteredCriterias: number[];
  links: string[];
  attachments: string[];
  status: ProjectGripBarrierStatus;
  projectGrip: ProjectGrip | null;
  auditGripType: AuditGripTypePayload | null;
}
export interface ProjectGripEnabler {
  id?: number | undefined;
  title: string;
  description: string;
  action: boolean;
  reason: string;
  filteredCriterias: number[];
  links: string[];
  attachments: string[];
  status: ProjectGripEnablerStatus;
  projectGrip: ProjectGrip | null;
  auditGripType: AuditGripTypePayload | null;
}
export interface ProjectGripStrategy {
  id?: number | undefined;
  title: string;
  description: string;
  resource: string;
  method: string;
  links: string[];
  attachments: string[];
  status: ProjectGripStrategyStatus;
  projectGrip: ProjectGrip;
  auditGripType: AuditGripTypePayload | null;
  strategyBarrierMap?: ProjectGripStrategyBarrierMap[];
  strategyEnablerMap?: ProjectGripStrategyEnablerMap[];
}

export interface ProjectGripStrategyBarrierMap {
  id: number;
  status: ProjectGripStrategyBarrierMapStatus;
  projectGripBarrier: ProjectGripBarrier;
  projectGripStrategy: ProjectGripStrategy;
  createdDate: Date;
  updatedDate: Date;
}

export interface ProjectGripStrategyEnablerMap {
  id: number;
  status: ProjectGripStrategyEnablerMapStatus;
  projectGripEnabler: ProjectGripEnabler;
  projectGripStrategy: ProjectGripStrategy;
  createdDate: Date;
  updatedDate: Date;
}

export enum ProjectGripBarrierStatus {
  DELETED = 'DELETED',
  ACTIVE = 'ACTIVE',
}

export enum ProjectGripEnablerStatus {
  DELETED = 'DELETED',
  ACTIVE = 'ACTIVE',
}

export enum ProjectGripStrategyStatus {
  DELETED = 'DELETED',
  ACTIVE = 'ACTIVE',
}

export enum ProjectGripStrategyBarrierMapStatus {
  DELETED = 'DELETED',
  ACTIVE = 'ACTIVE',
}

export enum ProjectGripStrategyEnablerMapStatus {
  DELETED = 'DELETED',
  ACTIVE = 'ACTIVE',
}

export interface LinkPayload {
  url: string;
  count?: number;
}

export interface ExportDocumentRequestPayload {
  gripId: number;
  exportType: DocumentExportType;
}

export enum DocumentExportType {
  docx = 'docx',
  pdf = 'pdf',
}
export interface HyperLinks {
  [s: string]: { link: string; text: string; type: HyperlinkType };
}

export interface GenerateHyperLinks {
  [key: string]: IInternalHyperlinkDefinition | IExternalHyperlinkDefinition;
}

export interface PdfContentStyle {
  id?: string;
  text?: Content;
  fontSize?: number;
  margin?: number[];
  color?: string;
  bold?: boolean;
  semibold?: boolean;
  ul?: string[];
  link?: string;
}

export interface PdfDividerLine {
  canvas: CanvasLine[];
  margin: number[];
}

export interface UpdateProjectCriteriasbyIdRequestPayload {
  projectId: number;
  criterias: CplusCriteria;
  deletedCriteriaIds?: number[];
}

export interface ReportSamplingDto {
  siteName: string;
  values: any[];
}

export interface DeleteProjectParticipantPayload {
  projectId: number;
  participantRoleId: number;
  participantId: number;
  siteIds: number[];
}
