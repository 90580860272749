import { ApiState } from '@/store/types/general.types';
import {
  CplusCriteria,
  JBICriteriaDTO,
  ParticipantRoles,
  ParticipantSite,
  ProjectDetailPayload,
  Site,
  UserEntity,
  UserRoleInAudit,
} from '@/store/modules/projects/types/projects.types';
import { UserDetail } from '../../users/types/users.types';
import { EventPayload } from '@/jbi-shared/types/paces-event-log.types';

export interface AuditsApiState {
  getAuditDetail: ApiState;
  updateSingleSiteSampling: ApiState;
  updateMultiSiteSampling: ApiState;
  getDataCollectionSummary: ApiState;
  postDataCollection: ApiState;
  getDataCollectionActivityLogs: ApiState;
  startAudit: ApiState;
  endAudit: ApiState;
  resumeAudit: ApiState;
  updateAuditCriterias: ApiState;
  updateAuditCriteriaById: ApiState;
  updateAuditSites: ApiState;
  deleteDataRecord: ApiState;
  updateAuditGeneralSettings: ApiState;
  updateAuditDataCollectionSettings: ApiState;
  getAuditDetailAndSettings: ApiState;
  getAuditCriteria: ApiState;
  getAuditSites: ApiState;
  getAuditConfig: ApiState;
  getDataCollectionRecord: ApiState;
  getDashboardChartData: ApiState;
  getParticipants: ApiState;
  deleteParticipant: ApiState;
  createParticipant: ApiState;
  updateParticipant: ApiState;
  resendInvitation: ApiState;
  getPaginatedEventLogs: ApiState;
  updateStatus: ApiState;
  updateAuditGrip: ApiState;
  createAuditGripBarrierModal: ApiState;
  updateAuditGripBarrierModal: ApiState;
  deletedGripBarrierRecord: ApiState;
  createAuditGripEnablerModal: ApiState;
  updateAuditGripEnablerModal: ApiState;
  deletedGripEnablerRecord: ApiState;
  createAuditGripStrategyModal: ApiState;
  updateAuditGripStrategyModal: ApiState;
  deletedGripStrategyRecord: ApiState;
  getAuditGrip: ApiState;
  getAuditGripBarrierModal: ApiState;
  getAuditGripEnablerModal: ApiState;
  getAuditGripStrategyModal: ApiState;
  getUpdatedAuditJBICriteriaList: ApiState;
  getAuditReportAnalysis: ApiState;
  getTotalAuditReportAnalysis: ApiState;
  updateAuditReportAnalysis: ApiState;
  deleteAuditReportAnalysis: ApiState;
}

export interface AuditsState {
  apiState: AuditsApiState;
  participants: AuditParticipantDTO[] | undefined;
  auditDetail: AuditDetail | undefined;
  updatedSingleSiteSampling: any | undefined;
  updatedMultiSiteSampling: any | undefined;
  dataCollectionSummary: any | undefined;
  dataCollectionResponse: any | undefined;
  dataCollectionActivityLogs: any | undefined;
  updatedAuditCriterias: undefined;
  updatedAuditCriteriaById: any | undefined;
  updatedAuditSites: undefined;
  deletedDataRecord: any | undefined;
  updatedAuditGeneralSettings: any | undefined;
  updatedAuditDataCollectionSettings: any | undefined;
  auditDetailAndSettings: any | undefined;
  auditCriteria: AuditCriteria[] | undefined;
  auditSites: AuditSite[] | undefined;
  auditConfig: AuditSamplingResponse | undefined;
  dataCollectionRecord: DataCollectionRecord | undefined;
  dashboardChartData: DashboardChartData[] | undefined;
  paginatedEventLogs: PaginatedAuditEventLogsResponse | undefined;
  updatedStatus: boolean | undefined;
  updateAuditGrip: undefined;
  createAuditGripBarrier: undefined;
  updateAuditGripBarrier: undefined;
  deletedGripBarrierRecord: any | undefined;
  createAuditGripEnabler: undefined;
  updateAuditGripEnabler: undefined;
  deletedGripEnablerRecord: undefined;
  createAuditGripStrategy: undefined;
  updateAuditGripStrategy: undefined;
  deletedGripStrategyRecord: undefined;
  auditGrip: AuditGrip | undefined;
  auditGripBarrier: UpdateAuditGripBarrierModalPayload[] | undefined;
  auditGripEnabler: UpdateAuditGripEnablerModalPayload[] | undefined;
  auditGripStrategy: UpdateAuditGripStrategyModalPayload[] | undefined;
  dataCollectionPeriodTitle: undefined;
  updatedAuditJBICriteriaList: JBICriteriaDTO[] | undefined;
  auditReportAnalysis: any | undefined;
  totalAuditReportAnalysis: AuditReportAnalysis[] | undefined;
  updatedAuditReportAnalysis: any | undefined;
  deletedAuditReportAnalysis: any | undefined;
}

export interface AuditDetail {
  id: number;
  name: string;
  description: string;
  auditType: AuditType;
  auditSiteType: AuditSiteType;
  isActive: boolean;
  startDate: any;
  endDate: any;
  startedAt: any;
  endedAt: any;
  createdAt: any;
  updatedAt: any;
  project: ProjectDetailPayload;
  settings: any;
  auditCriterias: any;
  auditSites: any;
  auditCategory: string;
  auditCycleNo: string;
  auditSamplingType: string;
  samplingMode: string;
  calculations?: any;
  auditDataTimestamp: string;
  consecutiveSamplingMode?: string;
  validateDataEntry?: boolean;
  status: AuditStatus;
  enableGrip: boolean;
  auditParticipants: AuditParticipantDTO[];
  isFutureAudit?: boolean; // Adding for dashboard only, need to check this
  isUserAuthorizedToAccessAllSites: boolean;
  userRoleInAudit?: UserRoleInAudit;
}

export enum AuditStatus {
  ARCHIVED = 'Archived',
  DELETED = 'Deleted',
  ACTIVE = 'Active',
}

export enum AuditType {
  baseline = 'baseline',
  followUp = 'followUp',
}

export enum AuditSiteType {
  singleSite = 'singleSite',
  multiSite = 'multiSite',
}

export interface SingleSitePayload {
  auditId: number;
  criteriaId: number;
  sampleSize?: number;
  dataConfig?: any;
  auditCriteriaId: number;
  minSampleSize?: number | null;
  maxSampleSize?: number | null;
  isSamplingEnabled?: boolean;
  calculateData?: any;
  criteriaTitle?: string;
  order?: number;
}

export interface SingleSiteSamplingRequestPayload {
  auditId: number;
  singleSitePayload: SingleSitePayload[];
  auditSamplingType: string;
  calculateData?: any;
  auditSamplingMode?: ConsecutiveSamplingMode;
  validateDataEntry?: boolean;
  samplingConfigId?: number;
}

export interface MultiSitePayload {
  auditId: number;
  criteriaId: number;
  sampleSize?: number;
  dataConfig?: any;
  sites: AuditSamplingSite[];
  auditCriteriaId: number;
  calculateData?: any;
  minSampleSize?: number | null;
  maxSampleSize?: number | null;
  isSamplingEnabled?: boolean;
  criteriaTitle?: string;
  order?: number;
}

export interface MultiSiteSamplingRequestPayload {
  auditId: number;
  multiSitePayload: MultiSitePayload[];
  auditSamplingType: string;
  auditSamplingMode?: string;
  siteArray?: AuditSamplingSite[];
  calculateData?: any;
  consecutiveSamplingMode?: ConsecutiveSamplingMode;
  validateDataEntry?: boolean;
  samplingConfigId?: number;
}

export interface AuditSamplingSite {
  siteId: number;
  name: string;
  sampleSize?: number;
  dataConfig?: any;
  auditSiteId: number;
  calculateData?: any;
  minSampleSize?: number | null;
  maxSampleSize?: number | null;
  isSamplingEnabled?: boolean;
}

export interface CalculateData {
  confidenceLevel: number;
  power: number;
  groupOne: number;
  groupTwo: number;
  recommended: number;
  output: number;
  sites?: AuditSamplingSite[];
}

export interface OneOffCalculateData {
  confidenceLevel: number;
  population: number;
  margin: number;
  groupOne: number;
  recommended: number;
  output: number;
  sites?: AuditSamplingSite[];
}

export interface DataCollectionSummaryRequestPayload {
  auditId: number;
  siteIds?: string;
}

export interface DataCollectionActivityLogRequestPayload {
  auditId: number;
  siteIds?: number[];
  criteriaIds?: number[];
  fromDate?: any;
  toDate?: any;
}

export interface DataCollectionSubmitRequestPayload {
  auditId: number;
  auditSiteId: number | null;
  recordedStartDateTime: any;
  recordedEndDateTime: any;
  dataCollectionType: string;
  aid: any;
  criteriaData: DataCollectionCriteriaDataPayload[];
  isSingleCriteria: boolean;
  editDataCollectionRecord: any;
  comments?: string;
}

export interface DataCollectionCriteriaDataPayload {
  auditCriteriId: number;
  criteria: {
    id: number;
    connectPlusId: number;
    title: string;
    isDefault: boolean;
    criteriaType: any;
    criteriaOptions: any;
    documentId: number;
    documentName: string;
    description: string;
    connectPlusLink: string;
    bestPracticeRecommendations: any;
    order: number;
    isDeleted: boolean;
  };
  optionValues: RangeOptionValueType[];
  order: number;
  total: number;
  optionTotal: number;
  isCommentSectionExpanded: boolean;
  comments: string;
}

export interface UpdateAuditCriteriasRequestPayload {
  auditId: number;
  criterias: CplusCriteria[];
  deletedCriteriaIds?: number[];
}

export interface UpdateAuditSitesRequestPayload {
  auditId: number;
  sites: Site[];
  deletedSiteIds?: number[];
}

export interface UpdateAuditDataCollectionSettingRequestPayload {
  auditId: number;
  auditDataTimestamp: string;
}

export interface UpdateAuditGeneralSettingRequestPayload {
  auditId: number;
  auditName: string;
  auditDescription: string;
  enableGrip: boolean;
  startDate: Date;
  endDate: Date;
}

export enum AuditSamplingType {
  targetSampleSize = 'Target Sample Size',
  adHoc = 'Ad-Hoc',
  consecutive = 'Consecutive',
}

export enum ChartType {
  verticalBarChart = 'Vertical Bar Chart',
  horizontalBarChart = 'Horizontal Bar Chart',
}

export enum SamplingMode {
  criteriaBased = 'Criteria Based Sampling',
  siteBased = 'Site Based Sampling',
}

export enum ConsecutiveSamplingMode {
  targetSample = 'Target Sample',
  minAndMax = 'Min and Max',
}

export interface AuditCriteria {
  id: number;
  order: number;
  isDeleted: boolean;
  isActive: boolean;
  criteria: {
    id: number;
    connectPlusId: number;
    title: string;
    isDefault: boolean;
    criteriaType: any;
    criteriaOptions: any;
    documentId: number;
    documentName: string;
    description: string;
    connectPlusLink: string;
    bestPracticeRecommendations: any;
    order: number;
    isDeleted: boolean;
  };
  criteriaTags?: any;
}

export interface AuditSite {
  id: number;
  isDeleted: boolean;
  site: {
    id: number;
    name: string;
    address: string;
    description: string;
  };
}

export interface AuditParticipantSite {
  id: number;
  isDeleted: boolean;
  site: {
    id: number;
    name: string;
    address: string;
    description: string;
  };
}

export interface DataCollectionSummaryResponsePayload {
  samplingConfiguration: {
    auditSamplingType: string;
    samplingMode: string;
  };
  configurationData: DataCollectionSummaryCriteria[];
}

export interface DataCollectionSubmitResponse {
  aid: number;
  audit: Partial<AuditDetail>;
  auditSite: Partial<AuditSite>;
  comments: string;
  createdAt: Date | string;
  dataCollectionType: string;
  id: number;
  isDeleted: boolean;
  isSingularSubmission: boolean;
  recordedEndDateTime: Date | string;
  recordedStartDateTime: Date | string;
  updatedAt: Date | string;
  user: Partial<UserDetail>;
}

export interface DataCollectionSummaryCriteria {
  auditCriteriaId: number;
  order: number;
  isDeleted: boolean;
  isActive: boolean;
  criteria: {
    id: number;
    connectPlusId: number;
    title: string;
    isDefault: boolean;
    criteriaType: any;
    criteriaOptions: any;
    documentId: number;
    documentName: string;
    description: string;
    connectPlusLink: string;
    bestPracticeRecommendations: any;
    order: number;
    isDeleted: boolean;
  };
  criteriaSamplingDataConfigurations?: SamplingDataConfiguration[];
  siteSamplingDataConfigurations?: SamplingDataConfiguration[];
  criteriaSamplingData: CriteriaSamplingData;
}

export interface DataCollectionCriteriaSamplingData {
  criteria: string;
  targetSize: number;
  minSampleSize: number;
  maxSampleSize: number;
  isSamplingEnabled: boolean;
  total: number;
}

export interface CriteriaSamplingData {
  total: number;
  criteriaOptionsDataDistribution: any;
}

export interface SamplingDataConfiguration {
  sampleSize: number;
  minSampleSize: number | null;
  maxSampleSize: number | null;
  isSamplingEnabled: boolean;
  auditSiteMap?: any;
}

export interface AuditSamplingRequestPayload {
  auditId: number;
  samplingType?: string;
  mode?: string;
}

export interface AuditSamplingResponse {
  auditSamplingConfigs: {
    id: number;
    auditSamplingType: string;
    isActive: boolean;
    samplingMode: string;
    validateDataEntry: boolean;
  };
  calculationConfigs: any;
  dataConfigs: AuditSamplingDataConfig[];
}

export interface AuditSamplingDataConfig {
  auditCriteriaMap: AuditCriteria;
  auditSiteMap: any;
  id: number;
  isDeleted: boolean;
  isSamplingEnabled: boolean;
  maxSampleSize: number | null;
  minSampleSize: number | null;
  sampleSize: number;
}

export interface AuditReportFiltersConfig {
  filteredSites: number[];
  filteredCriterias: number[];
  selectedChartType: string;
  checkIndividualSite: boolean;
  checkIndividualCriteria: boolean;
  selectedPeriod: Date[];
  checkComparison: boolean;
  comparisonPeriods: Date[][];
}

export interface AuditReportAnalysisPayload {
  filteredSites: number[];
  filteredCriterias: number[];
  selectedChartType: string;
  checkIndividualSite: boolean;
  checkIndividualCriteria: boolean;
  selectedPeriod: Date[];
  checkComparison: boolean;
  comparisonPeriods: Date[][];
  auditId: number;
  projectId: number;
  analysisId: number;
  analysisTitle: string;
  type: 'singleSite' | 'multiSite';
}

export interface ChartRecordsSiteData {
  auditSiteId: number | null;
  compliance: number;
  site: any | null;
}

export interface ChartRecords {
  auditCriteriaId: number;
  criteria: any;
  data: ChartRecordsSiteData[];
}

export interface StatisticData {
  configurationData: any;
  samplingConfiguration: any;
}

export interface AuditReportSinglePeriodResponse {
  chartData: ChartRecords[];
  statisticData: StatisticData;
  success: boolean;
  analysisId: number;
}

export interface AuditReportMultiplePeriodResponse {
  comparisonResult: AuditReportSinglePeriodResponse[];
  success: boolean;
  analysisId: number;
}

export interface DataCollectionRecord {
  id: number;
  comments: string;
  dataCollectionType: string;
  isSingularSubmission: boolean;
  recordedEndDateTime: any;
  recordedStartDateTime: any;
  user?: any;
  dataCollectionRecords: any[];
  auditSite: AuditSite;
  audit: AuditDetail;
  previousRecord?: {
    id: number;
  };
  nextRecord?: {
    id: number;
  };
}

export interface DashboardChartDataRequestPayload {
  auditId: number;
  siteIds: number[];
  criteriaIds: number[];
  fromDate?: any;
  toDate?: any;
}

export interface DashboardChartData {
  sum: number;
  createdDate: string;
}

export interface AuditReportAnalysis {
  title: string;
  filtersConfiguration: AuditReportFiltersConfig;
  analysisId: number;
}

export interface AuditReportAnalysisResponseDto {
  result: any;
  payload: any;
  analysisTitle: string;
  jbiLogo: string;
  projectTitle: string;
  auditTitle: string;
  auditSites: AuditSite[];
  auditCriteria: AuditCriteria[];
}

export interface UpdateAnalysisTitlePayload {
  auditId: number;
  analysisId: number;
  analysisTitle: string;
}

export interface DeleteAnalysisPayload {
  auditId: number;
  analysisId: number;
}

export interface CreateAuditParticipantPayload {
  auditId: number;
  userEmail: string;
  participantRole: string;
  participantSites: number[];
  projectOwnerEmail?: string;
}

export interface UpdateAuditParticipantPayload
  extends CreateAuditParticipantPayload {
  id?: number;
  participantRoleId: number;
}

export interface PaginatedAuditEventLogsPayload {
  auditId: number;
  page: number;
  limit: number;
  fromDate?: Date;
  toDate?: Date;
}

export interface PaginatedAuditEventLogsResponse {
  itemCount: number;
  items: EventLog[];
  totalItems: number;
}

export interface EventLog {
  createdAt: string;
  eventOccurredAt: string;
  eventPayload: EventPayload;
  eventType: string;
  id: number;
  originatorId: number;
  projectId: number | null;
  auditId: number | null;
}

export interface UpdateAuditStatusPayload {
  auditId: number;
  status: string;
}

export enum AuditGripTypeOption {
  behavioral = 'Behavioural',
  physical = 'Physical',
  finance = 'Financial',
  administrtative = 'Administrative',
  educational = 'Educational',
  geographical = 'Geographical',
  climate = 'Climate',
  cultural = 'Cultural',
  religious = 'Religious',
}

export interface AuditGrip {
  id: number;
  projectLead: string;
  stakeholder: string;
  auditId: number;
  actionDate: Date;
}

export interface UpdateAuditGripPayload {
  id: number;
  projectLead: string;
  stakeholder: string;
  auditId: number;
  actionDate: Date;
}

export interface PdfAuditGrip {
  projectLead: string;
  stakeholder: string;
  actionDateGrip: string;
  auditName: string;
  projectTitle: string;
}

export interface GripTypePayload {
  id: number;
  name: string;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface AuditGripBarrierModalPayload {
  id: number;
  name: string;
  type: string;
  description: number;
  action: boolean;
  reason: string;
  gripId: number;
  auditId: number;
  links: any[];
  attachments: any[];
  criterias: any[];
  actionDate: Date;
  title?: string;
  filteredCriterias?: any[];
  auditGripTypeId?: number | null;
  gripType?: GripTypePayload[];
}

export interface UpdateAuditGripBarrierModalPayload {
  id: number;
  name: string;
  type: string;
  description: number;
  action: boolean;
  reason: string;
  gripId: number;
  auditId: number;
  links: any[];
  attachments: any[];
  criterias: any[];
  auditGripTypeId?: number | null;
  gripType?: GripTypePayload[];
}

export interface AuditGripEnablerModalPayload {
  id: number;
  name: string;
  type: string;
  description: number;
  action: boolean;
  reason: string;
  gripId: number;
  auditId: number;
  links: any[];
  attachments: any[];
  criterias: any[];
  actionDate: Date;
  title?: string;
  filteredCriterias?: any[];
  auditGripTypeId?: number | null;
  gripType?: GripTypePayload[];
}

export interface UpdateAuditGripEnablerModalPayload {
  id: number;
  name: string;
  type: string;
  description: number;
  action: boolean;
  reason: string;
  gripId: number;
  auditId: number;
  links: any[];
  attachments: any[];
  criterias: any[];
  auditGripTypeId?: number | null;
  gripType?: GripTypePayload[];
}

export interface AuditGripStrategyModalPayload {
  id: number;
  name: string;
  type: string;
  description: number;
  method: string;
  resource: string;
  gripId: number;
  auditId: number;
  links: any[];
  attachments: any[];
  strategyBarrier: any[];
  strategyEnabler: any[];
  actionDate: Date;
  title?: string;
  strategyBarrierMap?: any[];
  strategyEnablerMap?: any[];
  auditGripTypeId?: number | null;
  gripType?: GripTypePayload[];
}

export interface UpdateAuditGripStrategyModalPayload {
  id: number;
  name: string;
  type: string;
  description: number;
  method: string;
  resource: string;
  gripId: number;
  auditId: number;
  links: any[];
  attachments: any[];
  strategyBarrier: any[];
  strategyEnabler: any[];
  auditGripTypeId?: number | null;
  gripType?: GripTypePayload[];
}

export interface ExportDocumentRequestPayload {
  gripId: number;
  exportType: DocumentExportType;
}

export enum DocumentExportType {
  docx = 'docx',
  pdf = 'pdf',
}

export interface ResumeEndDatePayload {
  auditId: number;
  endDate: Date;
}

export interface RangeOptionValueType {
  option: number | string;
  totalToDate: number;
  value: number;
}

export interface RangeErrorValidation {
  option: number | string;
  hasError: boolean;
}

export interface RangeSamplingValidation {
  siteId: number | string;
  hasInvalidRange: boolean;
  minSampleSize?: number | null;
  maxSampleSize?: number | null;
}

export interface RangeCriteriaTypeSamplingValidation {
  criteriaId: number | string;
  sites: RangeSamplingValidation[];
  autoDistributeMinSampling: boolean;
  autoDistributeMaxSampling: boolean;
  disableMinSiteInput: boolean;
  disableMaxSiteInput: boolean;
  hasInvalidMin: boolean;
  hasInvalidMax: boolean;
  minSampleSize?: number | null;
  maxSampleSize?: number | null;
}

export enum AuditParticipantSiteStatus {
  DELETED = 'Deleted',
  ACTIVE = 'Active',
}

export interface AuditSiteListDto {
  siteId: number;
  name: string;
  auditSiteId: number;
  id: number;
}

export interface AuditParticipantDataTrfObj {
  id: number;
  userEmail: string;
  participantRole: ParticipantRoles | null;
  participantSites: ParticipantSite[];
  isDeleted: boolean;
  participantRoleId: number;
  user?: UserEntity;
  level?: string;
}

export interface AuditParticipantDTO {
  id: number;
  isDeleted: boolean;
  auditParticipantRoles: AuditParticipantRole[];
  userEmail: string;
  user?: UserEntity;
}

export interface AuditParticipantRole {
  id: number;
  role: ParticipantRoles;
  isDeleted: boolean;
  auditParticipantSites: AuditParticipantSiteMap[];
}

export interface AuditParticipantSiteMap {
  id: number;
  isDeleted: boolean;
  site: Site;
  invite: AuditParticipantInvite;
}

export interface AuditParticipantInvite {
  id: number;
  token: string;
  expiredAt: Date;
  acceptedAt: Date;
  updatedAt: Date;
  createdAt: Date;
}

export interface AuditParticipantSite {
  id: number;
  status: AuditParticipantSiteStatus;
  site: {
    id: number;
    name: string;
    address: string;
    description: string;
  };
}

export interface AuditParticipantInvitation {
  acceptedAt: Date;
  expiredAt: Date;
  id: number;
  token: string;
}

export interface DeleteAuditParticipantPayload {
  auditId: number;
  auditParticipantId: number;
  auditParticipantRoleId: number;
  siteIds: number[];
}
