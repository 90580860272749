


















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  AuditDetail,
  AuditCriteria,
  AuditSite,
  AuditSamplingRequestPayload,
  AuditSamplingResponse,
  DataCollectionSubmitRequestPayload,
  DataCollectionSummaryRequestPayload,
  DataCollectionSummaryResponsePayload,
  AuditParticipantDTO,
  AuditParticipantRole,
  AuditParticipantSiteMap,
} from '@/store/modules/audits/types/audits.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import DataCollectionAggregate from '@/views/Audit/components/DataCollectionAggregate.vue';
import DataCollectionIndividual from '@/views/Audit/components/DataCollectionIndividual.vue';
import { useUserEmail } from '@/utils/user.util';
import { ParticipantRoles } from '@/store/modules/projects/types/projects.types';
import { cloneData } from '@/utils/general.util';
import { orderByUtil } from '@/jbi-shared/util/sort.util';
import { SortOrder } from '@/jbi-shared/types/search.types';

@Component({
  components: {
    DataCollectionAggregate,
    DataCollectionIndividual,
  },
})
export default class DataCollectorSheet extends Vue {
  @Prop()
  public auditDetail!: AuditDetail;
  @Prop()
  public auditId!: number;

  public dataCollectionType: string = 'aggregate';
  public dirtyDataCollectionSummary: any = {};
  public renderComponent: boolean = false;
  public defaultSiteId: number | null = null;
  public isMultipleSubmission: boolean = false;

  @Action('audits/getDataCollectionSummary')
  public getDataCollectionSummary!: (
    payload: DataCollectionSummaryRequestPayload,
  ) => void;

  @State((state) => state.audits.dataCollectionSummary)
  public dataCollectionSummary!: DataCollectionSummaryResponsePayload;

  @Action('audits/getAuditCriteria')
  public getAuditCriteria!: (auditId: number) => void;

  @State((state) => state.audits.auditCriteria)
  public auditCriteria!: AuditCriteria[];

  @Action('audits/getAuditSitesForDataCollection')
  public getAuditSitesForDataCollection!: (auditId: number) => void;

  @State((state) => state.audits.auditSites)
  public auditSites!: AuditSite[];

  @Action('audits/getSamplingConfig')
  public getSamplingConfig!: (payload: AuditSamplingRequestPayload) => void;

  @State((state) => state.audits.auditConfig)
  public auditConfig!: AuditSamplingResponse;

  @Action('audits/postDataCollection')
  public fetchDataCollection!: (
    payload: DataCollectionSubmitRequestPayload,
  ) => void;

  @State((state) => state.audits.dataCollectionResponse)
  public dataCollectionResponse!: any;

  @Watch('auditSites')
  @isTruthy
  public watchAuditSites(newValue: AuditSite[]) {
    const participantSites = this.getParticipantSites(newValue);
    this.defaultSiteId = participantSites[0].id;
  }

  @Watch('auditConfig')
  @isTruthy
  public watchAuditConfig(newValue: AuditSamplingResponse) {
    if (this.dataCollectionSummary) {
      this.renderComponent = true;
    }
  }

  @Watch('dataCollectionSummary')
  @isTruthy
  public watchDataCollectionSummary(
    newValue: DataCollectionSummaryResponsePayload,
  ) {
    this.dirtyDataCollectionSummary = cloneData(newValue);
    if (this.auditConfig) {
      this.renderComponent = true;
    }
  }

  @Watch('dataCollectionResponse')
  @isTruthy
  public watchDataCollectionResponse(newValue: any) {
    Toast.open({
      message: `Data Successfully Submitted.`,
      position: 'is-top',
      type: 'is-dark',
      duration: 3500,
    });
    if (!this.isMultipleSubmission) {
      this.$emit('refreshDataCollectionSheet');
      this.$router.go(-1);
    }
  }

  public mounted() {
    this.getAuditCriteria(this.auditId);
    this.getAuditSitesForDataCollection(this.auditId);
    this.getDataCollectionSummary({
      auditId: this.auditId,
    });
    this.getSamplingConfig({
      auditId: this.auditId,
    });
  }

  public handleSiteDropdownItem(newValue: number) {
    this.defaultSiteId = newValue;
  }

  public handleEnteredDataRecords(
    newValue: DataCollectionSubmitRequestPayload,
  ) {
    if (newValue.aid === '') {
      newValue.aid = null;
    }
    this.fetchDataCollection(newValue);
  }

  public switchMode(newValue: string) {
    this.renderComponent = false;
    this.dataCollectionType = newValue;
    this.$nextTick(() => {
      this.renderComponent = true;
    });
  }

  public compareSites(participantSites: any[]) {
    return (current: any) => {
      return (
        participantSites.filter((participantSite: any) => {
          return participantSite.site.id === current.site.id;
        }).length === 0
      );
    };
  }

  get siteList(): any[] {
    const siteArray: any[] = [];
    const participantSites = this.getParticipantSites();
    participantSites.forEach((participantSite) => {
      siteArray.push({
        siteId: participantSite.site.id,
        name: participantSite.site.name,
        auditSiteId: participantSite.id,
        id: participantSite.id,
      });
    });
    return siteArray;
  }

  get auditCriteriaList(): AuditCriteria[] {
    return this.auditCriteria.sort(orderByUtil(['order'], [SortOrder.ASC]));
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  private getParticipantSites(auditSites?: AuditSite[]) {
    if (!auditSites) {
      auditSites = this.auditSites;
    }
    const currentUserEmail = this.userEmail;
    const currentParticipant = this.auditDetail.auditParticipants.find(
      (participant: AuditParticipantDTO) => {
        return participant.userEmail === currentUserEmail;
      },
    );
    if (currentParticipant) {
      return auditSites.filter((auditSite) => {
        return currentParticipant.auditParticipantRoles.some(
          (auditParticipantRole: AuditParticipantRole) => {
            return (
              !auditParticipantRole.isDeleted &&
              auditParticipantRole.role === ParticipantRoles.dataCollector &&
              auditParticipantRole.auditParticipantSites.some(
                (participantSite: AuditParticipantSiteMap) => {
                  return (
                    auditSite.site.id === participantSite.site.id &&
                    !participantSite.isDeleted
                  );
                },
              )
            );
          },
        );
      });
    }
    return [];
  }
}
