import {
  AuditParticipantDTO,
  AuditParticipantRole,
  AuditParticipantSiteMap,
} from '@/store/modules/audits/types/audits.types';
import {
  ParticipantRoles,
  ProjectDetailPayload,
  ProjectParticipantDTO,
  ProjectParticipantRole,
  ProjectParticipantSiteMap,
  UserRoleInProject,
} from '@/store/modules/projects/types/projects.types';

export const getParticipantByRole = (
  projectParticipants: ProjectParticipantDTO[],
  userEmail: string,
  participantRole: ParticipantRoles,
) => {
  return projectParticipants.find((participant: ProjectParticipantDTO) => {
    return (
      participant.userEmail === userEmail &&
      !participant.isDeleted &&
      participant.participantRoles.find(
        (role: ProjectParticipantRole) =>
          !role.isDeleted &&
          role.role === participantRole &&
          role.participantSites &&
          role.participantSites.some(
            (participantSite: ProjectParticipantSiteMap) =>
              !participantSite.isDeleted && participantSite.invite.acceptedAt,
          ),
      )
    );
  });
};

export const getProjectUrlByRole = (
  projectId: number,
  userRoleInProject: UserRoleInProject,
) => {
  switch (userRoleInProject) {
    case UserRoleInProject.isOwner:
      return '/projects/' + projectId + '#dashboard';
    case UserRoleInProject.isProjectCoOrdinator:
      return '/projects/' + projectId + '#dashboard';
    case UserRoleInProject.isDataCollector:
      return '/projects/' + projectId + '/homepage';
    default:
      return '/projects/me';
  }
};
